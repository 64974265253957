import { UrlParser as urlParser } from 'url-params-parser';

import BrowserDatabase from 'Util/BrowserDatabase';

const render = (args, callback, instance) => {
    const query = urlParser(window.location.href).queryParams;
    const data = BrowserDatabase.getItem('UTM_DATA');
    console.log('utmdata-1 -> ', data);
    if (query.utm_source) {
        BrowserDatabase.setItem(query, 'UTM_DATA');
    } else if (!data) {
        BrowserDatabase.setItem({
            utm_source: 'Direct/Organic',
            utm_medium: 'Direct/Organic',
            utm_campaign: 'Direct/Organic'
        }, 'UTM_DATA');
    }

    return callback.apply(instance, args);
};

export default {
    'Lafz/Component/App/Component/AppComponent': {
        'member-function': {
            render
        }
    }
};

const requestPage = (args, callback, instance) => {
    const {
        sort,
        search,
        filter,
        requestTempProductList,
        requestProductList,
        requestProductListInfo,
        noAttributes,
        noVariants,
        isWidget
    } = instance.props;

    const [currentPage = 1, isNext = false] = args;

    /**
     * In case the wrong category was passed down to the product list,
     * prevent it from being requested.
     */
    if (filter.categoryIds === -1) {
        return;
    }

    /**
     * Do not request page if there are no filters
     */
    if (!search && !instance.isEmptyFilter()) {
        return;
    }

    const FirstLoadoptions = {
        isNext,
        noAttributes,
        noVariants,
        args: {
            sort,
            filter,
            search,
            pageSize: 18,
            currentPage
        }
    };

    requestTempProductList(FirstLoadoptions);

    const options = {
        isNext,
        noAttributes,
        noVariants,
        args: {
            sort,
            filter,
            search,
            pageSize: 300,
            currentPage
        }
    };

    const infoOptions = {
        args: {
            filter: { categoryIds: filter.categoryIds, priceRange: filter.priceRange, customFilters: {} },
            search
        }
    };

    requestProductList(options);

    if (!isWidget) {
        requestProductListInfo(infoOptions);
    }
};

export default {
    'Component/ProductList/Container': {
        'member-function': {
            requestPage
        }
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_REDIRECT = 'UPDATE_REDIRECT';
export const UPDATE_POSTPAY_DATA = 'UPDATE_POSTPAY_DATA';
export const UPDATE_LOADING = 'UPDATE_LOADING';

export const UPDATE_PAYLATER_REDIRECT = 'UPDATE_PAYLATER_REDIRECT';
export const UPDATE_POSTPAY_PAYLATER_DATA = 'UPDATE_POSTPAY_PAYLATER_DATA';
export const UPDATE_POSTPAY_LOADING = 'UPDATE_POSTPAY_LOADING';

/** @namespace Postpay/Store/Postpay/Action/updateRedirect */
export const updateRedirect = (isRedirect) => ({
    type: UPDATE_REDIRECT,
    isRedirect
});
/** @namespace Postpay/Store/Postpay/Action/updatePostpayData */
export const updatePostpayData = (PostpayData) => ({
    type: UPDATE_POSTPAY_DATA,
    PostpayData
});
/** @namespace Postpay/Store/Postpay/Action/updateIsLoading */
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_LOADING,
    isLoading
});

/** @namespace Postpay/Store/Postpay/Action/updatePaylaterRedirect */
export const updatePaylaterRedirect = (isRedirect) => ({
    type: UPDATE_PAYLATER_REDIRECT,
    isRedirect
});
/** @namespace Postpay/Store/Postpay/Action/updatePostpayPaylaterData */
export const updatePostpayPaylaterData = (PostpayPaylaterData) => ({
    type: UPDATE_POSTPAY_PAYLATER_DATA,
    PostpayPaylaterData
});
/** @namespace Postpay/Store/Postpay/Action/updateIsPaylaterLoading */
export const updateIsPaylaterLoading = (isLoading) => ({
    type: UPDATE_POSTPAY_LOADING,
    isLoading
});

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import Postpay from '../component/Postpay';
import { POSTPAY_ONLINE, POSTPAY_PAY_LATER } from '../component/Postpay/Postpay.config';
import PostpayPaylater from '../component/PostpayPaylater';

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/renderBraintreePayment */
export function renderPostpayPayment() {
    const {
        setDetailsStep,
        setOrderButtonEnableStatus
    } = this.props;

    return (
        <Postpay
          setDetailsStep={ setDetailsStep }
          setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
        />
    );
}

export function renderPostpayPaylater() {
    const {
        setDetailsStep,
        setOrderButtonEnableStatus
    } = this.props;

    return (
        <PostpayPaylater
          setDetailsStep={ setDetailsStep }
          setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
        />
    );
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/paymentRenderMap */
export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [POSTPAY_ONLINE]: renderPostpayPayment.bind(instance),
    [POSTPAY_PAY_LATER]: renderPostpayPaylater.bind(instance)
});

export default {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap
        }
    }
};

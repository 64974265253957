import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.style';

/** @namespace Lafz/Component/Carousel/Component/CarouselComponent */
export class CarouselComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element).isRequired
    };

    render() {
        const {
            children,
            ...rest
        } = this.props;

        return (
            <div block="Carousel">
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */ }
                <Slider { ...rest }>
                    { children }
                </Slider>
            </div>
        );
    }
}

export default CarouselComponent;

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';

/** @namespace Lafz/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Lafz/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Lafz/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    onFormSubmit(fields) {
        const { subscribeToNewsletter } = this.props;
        const { email } = fields;

        this.setState({ isLoading: true });

        subscribeToNewsletter(email)
            .then(
                /** @namespace Lafz/Component/NewsletterSubscription/Container/subscribeToNewsletter/then */
                () => this.onFormSubmitDone(email)
            )
            .catch(
                /** @namespace Lafz/Component/NewsletterSubscription/Container/subscribeToNewsletter/then/catch */
                () => this.onFormSubmitDone(email)
            );
    }

    onFormSubmitDone(_email) {
        this.setState({ isLoading: false });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscriptionContainer);

/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import { DeviceType } from 'Type/Device';

import './ProductRecommendation.style';

/** @namespace SkinAnalyse/Route/ProductRecommendation/Component/ProductRecommendationComponent */
export class ProductRecommendationComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        products: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    render() {
        const {
            products,
            isLoading,
            device: { isMobile = false }
        } = this.props;

        return (
            <div block="ProductRecommendation" elem="Wrapper">
                <img src={ isMobile ? 'https://www.zaynmyza.com/media/wysiwyg/ZM-.jpg' : 'https://www.zaynmyza.com/media/wysiwyg/Skin_analysis_banners_1-02.jpg' } alt="Here's what we recommend based on your information" />
                <ContentWrapper block="ProductRecommendation">
                    <h1 block="ProductRecommendation" elem="Heading">
                        Here&apos;s what we recommend based on your information
                    </h1>
                    <div block="ProductRecommendation" elem="ProductList">
                        { products.map((product) => <ProductCard key={ product.sku } product={ product } isSkinAnalyserProduct />) }
                    </div>
                    <Loader isLoading={ isLoading } />
                </ContentWrapper>
            </div>
        );
    }
}

export default ProductRecommendationComponent;

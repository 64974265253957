export const desktopMinWidth = 1025;
export const mobileMinWidth = 768;
/**
 * @return { boolean }
 * Returns current device is mobile or not
 * @namespace Lafz/Util/CheckDevice/Index/isBeforeDesktop */
export const isBeforeDesktop = () => {
    const { availWidth } = window.screen;
    if (availWidth < desktopMinWidth) {
        return true;
    }

    return false;
};

/**
 * @return { boolean }
 * Returns current device is mobile or not
 * @namespace Lafz/Util/CheckDevice/Index/isMobile
 */
export const isMobile = () => {
    const { availWidth } = window.screen;
    if (availWidth < mobileMinWidth) {
        return true;
    }

    return false;
};

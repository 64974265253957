/* eslint-disable react/boolean-prop-naming */
/* eslint-disable global-require */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';

import './ServiceAvailability.style.scss';

/** @namespace Pincodecheck/Component/ServiceAvailability/Component/ServiceAvailability */
export const ServiceAvailability = ({
    onChangePin,
    pincode,
    checked,
    toggleChecked,
    checking,
    checkAvailability,
    deliveryOptions
}) => (
    <div block="ServiceAvailability">
        <div block="ServiceAvailability" elem="Heading">DELIVERY OPTIONS</div>
        <div block="ServiceAvailability" elem="Form">
            <input
              block="ServiceAvailability"
              elem="Input"
              type="text"
              onChange={ onChangePin }
              placeholder="Enter a PIN Code"
              value={ pincode }
              disabled={ checking || checked }
              minLength={ 6 }
              maxLength={ 6 }
            />
            { checked ? (
                <button
                  block="ServiceAvailability"
                  elem="Button"
                  mods={ { isDisabled: checking } }
                  type="button"
                  disabled={ checking }
                  onClick={ toggleChecked }
                >
                    change
                </button>
            ) : (
                <button
                  block="ServiceAvailability"
                  elem="Button"
                  mods={ { isDisabled: pincode === '' || checking } }
                  type="button"
                  disabled={ pincode === '' || checking }
                  onClick={ checkAvailability }
                >
                    check
                </button>
            ) }
        </div>
        { deliveryOptions !== null ? (
        <div block="ServiceAvailability" elem="Options">
            <div block="ServiceAvailability" elem="Option">Get it by { deliveryOptions?.etd }</div>
            { deliveryOptions?.cod === 1 ? (<div block="ServiceAvailability" elem="Option">Pay on delivery available</div>) : null }
        </div>
        ) : null }
    </div>
);

ServiceAvailability.propTypes = {
    onChangePin: PropTypes.func.isRequired,
    pincode: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    toggleChecked: PropTypes.bool.isRequired,
    checking: PropTypes.bool.isRequired,
    checkAvailability: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    deliveryOptions: PropTypes.any
};

ServiceAvailability.defaultProps = {
    deliveryOptions: null
};

export default ServiceAvailability;

/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MyAccountAfterSignIn from './MyAccountAfterSignIn.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/MyAccountAfterSignIn/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, dispatch)
    )
});

/** @namespace Component/MyAccountAfterSignIn/Container */
export class MyAccountAfterSignInContainer extends PureComponent {
    static propTypes = {
        logout: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleLogout: this.handleLogout.bind(this)
    };

    handleLogout() {
        const { logout } = this.props;

        logout();
    }

    render() {
        return (
            <MyAccountAfterSignIn
              { ...this.containerFunctions }
            />
        );
    }
}

/** @namespace Component/MyAccountAfterSignIn/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAfterSignInContainer);

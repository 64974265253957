/* eslint-disable no-magic-numbers */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { IN_STOCK } from 'Component/ProductCard/ProductCard.config';
import { MixType } from 'Type/Common';
import { ProductType } from 'Type/ProductList';
import {
    CONFIGURABLE, SIMPLE
} from 'Util/Product';

import './AddToCart.style';

/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Component/AddToCart/Component
 */
export class AddToCart extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        product: ProductType,
        mix: MixType,
        buttonClick: PropTypes.func.isRequired,
        configurableVariantIndex: ProductType.configurableVariantIndex,
        // eslint-disable-next-line react/boolean-prop-naming
        bundleOutOfStockStatus: PropTypes.bool.isRequired,
        onlyIcon: PropTypes.bool.isRequired,
        countryCode: PropTypes.string.isRequired,
        getAttribute: PropTypes.func.isRequired
    };

    static defaultProps = {
        product: {},
        mix: {},
        configurableVariantIndex: 0,
        isLoading: false
    };

    renderPlaceholder() {
        const { isLoading, mix } = this.props;

        return (
            <div
              block="AddToCart"
              mods={ { isLoading, isPlaceholder: true } }
              mix={ mix }
            />
        );
    }

    renderOhsogoButton() {
        const {
            mix,
            product,
            getAttribute,
            countryCode
        } = this.props;

        const { attributes } = product;
        if (!attributes) {
            return null;
        }
        const ohsogo_link = getAttribute('ohsogo_link');

        if (!ohsogo_link || countryCode !== 'bd') {
            return null;
        }

        return (
                <a
                  href={ ohsogo_link.attribute_value }
                  block="Button AddToCart"
                  mix={ mix }
                >
                    <span>Buy Now</span>
                    <span>{ __('Adding...') }</span>
                </a>
        );
    }

    render() {
        const {
            mix,
            product: { type_id, variants = [] },
            product,
            isLoading,
            buttonClick,
            bundleOutOfStockStatus,
            // eslint-disable-next-line react/prop-types
            isConfigurableAddToCart,
            configurableVariantIndex,
            getAttribute,
            countryCode,
            onlyIcon
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const ohsogo_link = getAttribute('ohsogo_link');

        if (ohsogo_link && countryCode === 'bd') {
            return this.renderOhsogoButton();
        }

        const productOrVariant = variants[configurableVariantIndex] || product;
        const {
            stock_status,
            salable_qty,
            price_range: {
                maximum_price: {
                    default_final_price: {
                        currency = 'AED'
                    } = {}
                } = {}
            } = {}
        } = productOrVariant;

        const qty = parseInt(salable_qty, 10);
        // eslint-disable-next-line fp/no-let
        let buttonText = __('Add to cart');
        if (type_id === CONFIGURABLE) {
            buttonText = isConfigurableAddToCart() || configurableVariantIndex === -1
                ? __('Add to cart') : __('Sold Out');

            return (
                <button
                  onClick={ buttonClick }
                  block="Button AddToCart"
                  mix={ mix }
                  mods={ { isLoading } }
                  disabled={ isLoading || stock_status !== 'IN_STOCK' || bundleOutOfStockStatus }
                >
                    <span>{ buttonText }</span>
                    <span>{ __('Adding...') }</span>
                </button>
            );
        }
        // eslint-disable-next-line fp/no-let
        let inOutOfStock = false;

        // eslint-disable-next-line no-magic-numbers
        const isInStock = !(currency === 'KWD' && (qty + 5) <= 10 && type_id === 'simple');
        if (type_id === SIMPLE) {
            inOutOfStock = stock_status !== IN_STOCK || qty <= 5 || !isInStock;
            buttonText = !inOutOfStock ? __('Add to cart') : __('Sold Out');
        }

        if (bundleOutOfStockStatus) {
            buttonText = __('Sold Out');
        }

        return (
                <button
                  onClick={ buttonClick }
                  block="Button AddToCart"
                  mix={ mix }
                  mods={ { isLoading } }
                  disabled={ isLoading || stock_status === 'OUT_OF_STOCK' || bundleOutOfStockStatus || inOutOfStock }
                >
                    { onlyIcon ? (
                        <svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h20v20H0z" />
                            <path d="M6 13h9c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1V4H2c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1v2h13l-4 7H6zm-.5 3c.83 0 1.5.67 1.5 1.5S6.33 19 5.5 19 4 18.33 4 17.5 4.67 16 5.5 16m9 0c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5" />
                        </svg>
                    ) : (
                        <>
                        <span>{ buttonText }</span>
                        <span>{ __('Adding...') }</span>
                        </>
                    ) }
                </button>
        );
    }
}

export default AddToCart;

/* eslint-disable max-len */
import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

import {
    CONVERSION_EVENT,
    GTM_DATA,
    INTERNAL_TRACKING_CHECKOUT_SUCCESS,
    PURCHASE_EVENT,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';
import GaTrackingQuery from '../query/GaTracking.query';

export const getCountryCode = (key) => {
    switch (key) {
    case 'in':
        return '+91';
    case 'bd':
        return '+880';
    case 'sa':
        return '+27';
    case 'ae':
        return '+971';
    case 'kw':
        return '+965';
    case 'pk':
        return '+92';
    case 'qa':
        return '+974';
    case 'bh':
        return '+973';
    case 'om':
        return '+968';
    case 'ni':
        return '+505';
    default:
        return '+91';
    }
};

const propTypes = (originalMember) => ({
    ...originalMember,
    telephone: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired
});

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const render = (args, callback, instance) => {
    const { gtmTriggered } = instance.state;

    if (!gtmTriggered) {
        const {
            telephone,
            orderID,
            countryCode
        } = instance.props;
        const gtmData = BrowserDatabase.getItem(GTM_DATA) || {};
        const data = BrowserDatabase.getItem('address') || {};
        const { billing_address = {} } = data;

        const {
            items,
            coupon_code: coupon = '',
            grand_total = 0,
            quote_currency_code: currency = 'INR',
            applied_taxes = [],
            shipping_incl_tax: shipping = 0,
            email
        } = gtmData;

        const tax = applied_taxes.reduce((all, curr) => {
            if (!curr) {
                return all;
            }
            const { amount: { value = 0 } = { } } = curr;

            return all + value;
        }, 0);

        if (grand_total > 0 && currency !== '') {
            const utmData = BrowserDatabase.getItem(UTM_DATA);
            const phoneCode = getCountryCode(countryCode);
            const phonenumber = `${phoneCode}${telephone}`;
            const customername = `${billing_address?.firstname} ${billing_address?.lastname}`;

            const clevertapData = {
                'Order ID': orderID,
                'Customer Name': customername,
                'Customer Email': email,
                'Customer Phone': phonenumber,
                'Coupon Code': coupon,
                'Grand Total': grand_total,
                Currency: currency,
                Date: new Date(),
                'Payment Method': 'postpay_pay_now',
                'Country Code': countryCode,
                'UTM Source': utmData?.utm_source,
                'UTM Medium': utmData?.utm_medium,
                'UTM Campaign': utmData?.utm_campaign
            };

            const clevertapChargedEventData = {
                ...clevertapData,
                Items: [],
                Products: '',
                Categories: ''
            };
            const itemsData = [];
            if (items) {
                items.forEach((d) => {
                    const {
                        qty: quantity,
                        sku,
                        row_total_incl_tax: price = 0,
                        product: { name } = {}
                    } = d;

                    if (price > 0) {
                        itemsData.push(
                            {
                                item_id: sku,
                                item_name: name,
                                currency,
                                price,
                                quantity
                            }
                        );
                    }
                });
            }

            const customerData = {
                Site: {
                    Name: customername,
                    Email: email,
                    Phone: phonenumber,
                    'MSG-email': true,
                    'MSG-push': true,
                    'MSG-sms': true,
                    'MSG-whatsapp': true,
                    'Country Code': countryCode
                }
            };

            triggerEvent({ ecommerce: null });

            triggerEvent({
                event: PURCHASE_EVENT,
                ecommerce: {
                    currency,
                    transaction_id: orderID,
                    value: grand_total,
                    coupon,
                    shipping,
                    tax,
                    items: itemsData
                },
                utm_data: utmData,
                customerData,
                clevertapChargedEventData,
                clevertapData
            });
            triggerEvent({ ecommerce: null });
            triggerEvent({
                event: CONVERSION_EVENT,
                ecommerce: {
                    currency,
                    transaction_id: orderID,
                    value: grand_total
                },
                utm_data: utmData
            });
            // BrowserDatabase.deleteItem(GTM_DATA);
            fetchMutation(GaTrackingQuery.getQuery({
                page_type: INTERNAL_TRACKING_CHECKOUT_SUCCESS,
                event_data: ''
            }));
        }

        instance.setState({
            gtmTriggered: true
        });
    }

    return callback.apply(instance, args);
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render
        },
        'member-property': {
            state
        },
        'static-member': {
            propTypes
        }
    }
};

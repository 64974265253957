/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CategorySort.style';

/**
 * Product Sort
 * @class ProductSort
 * @namespace Component/CategorySort/Component
 */
export class CategorySort extends PureComponent {
    static propTypes = {
        onSortChange: PropTypes.func.isRequired,
        selectOptions: PropTypes.array.isRequired
    };

    onChange = ({target: {value}}) => {
        if (!value) {
            return;
        }

        const { onSortChange } = this.props;

        onSortChange(value);
    };

    renderSortField() {
        const {
            selectOptions
        } = this.props;

        return (
            <select
                block="FieldSelect"
                elem="Select"
                onChange={ this.onChange }
                name='category-sort'
            >
                { selectOptions.map((option) => (
                    <option selected={ option.default }  value={ option.value }>{ option.label }</option>
                )) }
            </select>
        );
    }

    render() {
        return (
            <div block="CategorySort">
                { this.renderSortField() }
            </div>
        );
    }
}

export default CategorySort;

import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Lafz/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'phone_number',
            this._getAddressesField()
        ];
    }

    getMobileSignInMutation(options) {
        const { phone_number } = options;

        return new Field('sendOtp')
            .addArgument('phone_number', 'String!', phone_number)
            .addFieldList([
                'success',
                'message',
                'is_customer',
                'otp'
            ]);
    }

    getVerifyOtpMutation(options) {
        const { phone_number, otp } = options;

        return new Field('verifyOtp')
            .addArgument('phone_number', 'String!', phone_number)
            .addArgument('otp', 'String!', otp)
            .addFieldList([
                'success',
                'message',
                'token'
            ]);
    }
}

export default new MyAccountQuery();

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_LOADING,
    UPDATE_PAYLATER_REDIRECT,
    UPDATE_POSTPAY_DATA,
    UPDATE_POSTPAY_LOADING,
    UPDATE_POSTPAY_PAYLATER_DATA,
    UPDATE_REDIRECT
} from './Postpay.action';

export const initialState = {
    isRedirect: false,
    PostpayData: {},
    PostpayPaylaterData: {},
    isLoading: false
};

/** @namespace Postpay/Store/Postpay/Reducer/PostpayReducer */
export const PostpayReducer = (state = initialState, action) => {
    const { PostpayData, isRedirect, isLoading } = action;

    switch (action.type) {
    case UPDATE_PAYLATER_REDIRECT:
    case UPDATE_REDIRECT:
        return {
            ...state,
            isRedirect
        };
    case UPDATE_POSTPAY_DATA:
        return {
            ...state,
            PostpayData
        };
    case UPDATE_POSTPAY_PAYLATER_DATA:
        return {
            ...state,
            PostpayPaylaterData: action.PostpayPaylaterData
        };
    case UPDATE_POSTPAY_LOADING:
    case UPDATE_LOADING:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default PostpayReducer;

/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
import moment from 'moment-timezone';

import { IN_STOCK } from 'Component/ProductCard/ProductCard.config';

/** @namespace Lafz/Util/Helpers/trimTrailingSlash */
export const trimTrailingSlash = (string = '') => {
    if (string.endsWith('/')) {
        return string.slice(0, -1);
    }

    return string;
};

/** @namespace Lafz/Util/Helpers/stripHtml */
export const stripHtml = (html) => {
    const temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

/** @namespace Lafz/Util/Helpers/makeExcerpt */
// eslint-disable-next-line no-magic-numbers, max-len
export const makeExcerpt = (string = '', length = 40) => (string.length > length ? string.substring(0, length) : string);

/** @namespace Lafz/Util/Helpers/getPathFromUrl */
export const getPathFromUrl = (url = '') => url.split(/[?#]/)[0];

/** @namespace Lafz/Util/Helpers/localDateToIst */
export const localDateToIst = () => {
    const localdate = moment(new Date());
    const formatedDate = localdate.tz('Asia/Kolkata');
    return formatedDate.format('MMMM Do YYYY, h:mm:ss a');
};

/** @namespace Lafz/Util/Helpers/getDefaultCustomizableOptions */
export const getDefaultCustomizableOptions = (product) => {
    const { items = [] } = product;

    if (items.length === 0) {
        return {};
    }

    try {
        const requiredOptions = items.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const values = items.reduce((all, { option_id, required, options }) => {
            if (!required) {
                return all;
            }
            const option = options.find((option) => option.is_default);

            if (!option) {
                return all;
            }

            const { product } = option;
            const {
                type_id,
                salable_qty,
                price_range: {
                    maximum_price: {
                        default_final_price: {
                            currency = 'AED'
                        } = {}
                    } = {}
                } = {}
            } = product;

            // eslint-disable-next-line no-magic-numbers
            const isInStock = !(currency === 'KWD' && (parseInt(salable_qty, 10) + 5) <= 10 && type_id === 'simple');

            if (!product || product.stock_status !== 'IN_STOCK' || !isInStock || parseInt(salable_qty, 10) <= 5) {
                return all;
            }

            return [...all, {
                id: option_id,
                quantity: 1,
                value: [
                    option.id.toString()
                ]
            }];
        }, []);

        return {
            requiredOptions,
            productOptions: values
        };
    } catch (error) {
        console.log('erorr in this product: ', product.name);

        return {
            requiredOptions: [],
            productOptions: []
        };
    }
};

/** @namespace Lafz/Util/Helpers/isBundleProductOutOfStock */
export const isBundleProductOutOfStock = (product, options = null) => {
    const { items } = product;

    if (!items || items.length === 0) {
        return true;
    }

    if (!options) {
        const { requiredOptions, productOptions } = getDefaultCustomizableOptions(product);
        if (requiredOptions.length !== productOptions.length) {
            return true;
        }
        const outOfStockItems = productOptions.filter(({ id, value }) => {
            const item = items.find((i) => i.option_id === id);
            if (!item) {
                return true;
            }

            const options = item.options.filter((o) => value.includes(o.id.toString()));
            if (!options) {
                return true;
            }

            return options.some(({ product }) => {
                const {
                    salable_qty,
                    stock_status,
                    type_id,
                    price_range: {
                        maximum_price: {
                            default_final_price: {
                                currency = 'AED'
                            } = {}
                        } = {}
                    } = {}
                } = product;

                // less then 5 qty is out of stock
                const qty = parseInt(salable_qty, 10);
                // eslint-disable-next-line no-magic-numbers
                const isInStock = !(currency === 'KWD' && (qty + 5) <= 10 && type_id === 'simple');

                return stock_status !== IN_STOCK || !isInStock || qty <= 5;
            });
        });

        return outOfStockItems.length > 0;
    }

    if (!Object.keys(options).length) {
        return true;
    }

    const { productOptions } = options;

    if (!productOptions) {
        return true;
    }

    const outOfStockItems = productOptions.filter(({ id, value }) => {
        const item = items.find((i) => i.option_id === id);
        if (!item) {
            return true;
        }

        const options = item.options.filter((o) => value.includes(o.id.toString()));
        if (!options) {
            return true;
        }

        return options.some(({ product }) => {
            const {
                salable_qty,
                type_id,
                price_range: {
                    maximum_price: {
                        default_final_price: {
                            currency = 'AED'
                        } = {}
                    } = {}
                } = {}
            } = product;
            // less then 5 qty is out of stock
            const qty = parseInt(salable_qty, 10);
            // eslint-disable-next-line no-magic-numbers
            const isInStock = !(currency === 'KWD' && (qty + 5) <= 10 && type_id === 'simple');
            return product.stock_status !== IN_STOCK || !isInStock || qty <= 5;
        });
    });

    return outOfStockItems.length > 0;
};

/** @namespace Lafz/Util/Helpers/formatImageName */
export const formatImageName = (imageName) => {
    // eslint-disable-next-line fp/no-let
    let formattedName = imageName.replace(/\.[^/.]+$/, ''); // remove extension
    formattedName = formattedName.replace(/[^a-zA-Z0-9\s]/g, ' '); // replace all special characters with space
    formattedName = formattedName.replace(/\b\w/g, (l) => l.toUpperCase()); // capitalizing the first letter of each word
    return formattedName;
};

/** @namespace Lafz/Util/Helpers/getBundleProductIds */
export const getBundleProductIds = (product, options) => {
    const { items = [] } = product;

    if (items.length === 0) {
        return '';
    }

    const { productOptions } = options;

    const ids = productOptions.reduce((all, { id, value }) => {
        const item = items.find((i) => i.option_id === id);

        if (!item) {
            return all;
        }

        const options = item.options.filter(({ product, id }) => {
            const {
                salable_qty,
                stock_status = 'IN_STOCK',
                type_id,
                price_range: {
                    maximum_price: {
                        default_final_price: {
                            currency = 'AED'
                        } = {}
                    } = {}
                } = {}
            } = product;
            // less then 5 qty is out of stock
            const qty = parseInt(salable_qty, 10);
            // eslint-disable-next-line no-magic-numbers
            const isInStock = !(currency === 'KWD' && (qty + 5) <= 10 && type_id === 'simple');
            return (
                value.includes(id.toString())
                && (
                    stock_status !== IN_STOCK
                    || !isInStock
                    || qty <= 5
                )
            );
        });

        if (!options.length) {
            return all;
        }

        const oos = options.reduce((prev, { product }) => [...prev, product.id], []);

        if (!oos.length) {
            return all;
        }

        return [...all, ...oos];
    }, []);

    // const sku = ids.join('-');
    return ids;
};

/** @namespace Lafz/Util/Helpers/getBundleProductSkus */
export const getBundleProductSkus = (product, options) => {
    const { items = [] } = product;

    if (items.length === 0) {
        return '';
    }

    const { productOptions } = options;

    const skus = productOptions.reduce((all, { id, value }) => {
        const item = items.find((i) => i.option_id === id);

        if (!item) {
            return all;
        }

        const options = item.options.filter(({ product, id }) => {
            const {
                salable_qty,
                stock_status = 'IN_STOCK',
                type_id,
                price_range: {
                    maximum_price: {
                        default_final_price: {
                            currency = 'AED'
                        } = {}
                    } = {}
                } = {}
            } = product;
            // less then 5 qty is out of stock
            const qty = parseInt(salable_qty, 10);
            // eslint-disable-next-line no-magic-numbers
            const isInStock = !(currency === 'KWD' && (qty + 5) <= 10 && type_id === 'simple');
            return (
                value.includes(id.toString())
                && (
                    stock_status !== IN_STOCK
                    || !isInStock
                    || qty <= 5
                )
            );
        });

        if (!options.length) {
            return all;
        }
        const oos = options.reduce((prev, { product }) => [...prev, product.sku], []);
        if (!oos.length) {
            return all;
        }

        return [...all, ...oos];
    }, []);

    return skus;
};

/** @namespace Lafz/Util/Helpers/formatCurrency */
export const formatCurrency = (amount, currency = 'INR', locale = 'en-US') => new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
}).format(amount);

/** @namespace Lafz/Util/Helpers/isStagingSite */
export const isStagingSite = () => {
    const url = window.location.href;
    const stagingDomains = ['staging', 'new', 'devm244'];
    // eslint-disable-next-line fp/no-let
    for (let i = 0; i < stagingDomains.length; i++) {
        const string = stagingDomains[i];
        if (url.includes(string)) {
            return true;
        }
    }

    return false;
};

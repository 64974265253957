/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const NpsSurvey = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "NpsSurvey" */ '../route/NpsSurvey'));

/** @namespace NpmSurvey/Plugin/RouterComponent/addAddNpsSurveyRoute */
export const addAddNpsSurveyRoute = (originalMember) => ([
    ...[originalMember][0],
    {
        component: <Route path={ withStoreRegex('/nps-survey/:entity_key') } exact render={ (props) => <NpsSurvey { ...props } /> } />,
        position: 100
    }
]);

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addAddNpsSurveyRoute
        }
    }

};

/* eslint-disable max-len */
import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

import {
    ADD_PAYMENT_INFO,
    EVENT_BEGIN_CHECKOUT,
    GTM_DATA,
    INTERNAL_TRACKING_CHECKOUT_INIT,
    INTERNAL_TRACKING_PAYMENT_METHOD,
    SAVE_ADDRESS_INFORMATION,
    SAVE_PAYMENT_AND_PLACE_ORDER,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';
import GaTrackingQuery from '../query/GaTracking.query';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    countryCode: PropTypes.string.isRequired
});

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const render = (args, callback, instance) => {
    const {
        totals: {
            items,
            coupon_code: coupon = '',
            grand_total = 0,
            quote_currency_code: currency = 'INR',
            applied_taxes = [],
            shipping_incl_tax: shipping = 0
        },
        countryCode
    } = instance.props;

    const tax = applied_taxes.reduce((all, curr) => {
        if (!curr) {
            return all;
        }
        const { amount: { value = 0 } = { } } = curr;

        return all + value;
    }, 0);

    const { gtmTriggered } = instance.state;

    if (!gtmTriggered) {
        const itemsData = [];
        const utmData = BrowserDatabase.getItem(UTM_DATA);
        const clevertapData = {
            'Country Code': countryCode,
            'UTM Source': utmData?.utm_source,
            'UTM Medium': utmData?.utm_medium,
            'UTM Campaign': utmData?.utm_campaign
        };

        if (items) {
            items.forEach((d, index) => {
                const count = index + 1;
                const {
                    qty: quantity,
                    sku,
                    row_total_incl_tax: price = 0,
                    product: { name, url } = {}
                } = d;

                if (price > 0) {
                    itemsData.push(
                        {
                            item_id: sku,
                            item_name: name,
                            currency,
                            price,
                            quantity
                        }
                    );
                    clevertapData[`Product Name - ${count}`] = name;
                    clevertapData[`Product SKU - ${count}`] = sku;
                    clevertapData[`Product Price - ${count}`] = price;
                    clevertapData[`Product Quantity - ${count}`] = quantity;
                    clevertapData[`Product URL - ${count}`] = url;
                }
            });
        }

        triggerEvent({ ecommerce: null });

        triggerEvent({
            event: EVENT_BEGIN_CHECKOUT,
            ecommerce: {
                value: grand_total,
                items: itemsData,
                currency,
                coupon,
                shipping,
                tax
            },
            utm_data: utmData,
            clevertapData
        });

        fetchMutation(GaTrackingQuery.getQuery({
            page_type: INTERNAL_TRACKING_CHECKOUT_INIT,
            event_data: ''
        }));

        instance.setState({
            gtmTriggered: true
        });
    }

    return callback.apply(instance, args);
};

const savePaymentMethodAndPlaceOrder = (args, callback, instance) => {
    const { totals } = instance.props;
    const { email } = instance.state;
    // this data will be used to trigger gtm transaction event on checkout success page
    BrowserDatabase.setItem({ ...totals, email }, GTM_DATA);
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    triggerEvent({
        event: SAVE_PAYMENT_AND_PLACE_ORDER,
        utm_data: utmData
    });

    return callback.apply(instance, args);
};

const savePaymentInformation = (args, callback, instance) => {
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    triggerEvent({
        event: ADD_PAYMENT_INFO,
        utm_data: utmData
    });

    fetchMutation(GaTrackingQuery.getQuery({
        page_type: INTERNAL_TRACKING_PAYMENT_METHOD,
        event_data: ''
    }));

    return callback.apply(instance, args);
};

export const saveAddressInformation = async (args, callback, instance) => {
    try {
        callback(...args);
        const [addresses] = args;
        const { shipping_address } = addresses;
        const streetName = Array.isArray(shipping_address.street) ? shipping_address.street.join(', ') : shipping_address.street;
        if (shipping_address) {
            const { totals } = instance.props;

            const utmData = BrowserDatabase.getItem('UTM_DATA');

            const clevertapData = {
                city: shipping_address.city,
                country_id: shipping_address.country_id,
                firstname: shipping_address.firstname,
                lastname: shipping_address.lastname,
                postcode: shipping_address.postcode,
                region: shipping_address.region,
                region_id: shipping_address.region_id,
                street: streetName,
                telephone: shipping_address.telephone,
                'UTM Source': utmData?.utm_source,
                'UTM Medium': utmData?.utm_medium,
                'UTM Campaign': utmData?.utm_campaign
            };

            localStorage.setItem(
                'CLEVERTAP_DATA',
                JSON.stringify(totals)
            );

            // eslint-disable-next-line fp/no-let
            let count = 1;
            totals.items.forEach((item) => {
                // eslint-disable-next-line fp/no-let, prefer-const
                let { product } = item;
                clevertapData[`Product Name - ${count}`] = product.name;
                clevertapData[`Product SKU - ${count}`] = product.sku;
                clevertapData[`Product Price - ${count}`] = item.price;
                clevertapData[`Product Quantity - ${count}`] = item.qty;
                clevertapData[`Product URL - ${count}`] = product.url;

                count++;
            });
            triggerEvent({
                event: SAVE_ADDRESS_INFORMATION,
                utm_data: utmData,
                clevertapData
            });
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('saveAddressInformation - error', error);
    }
};

export default {
    'Route/Checkout/Container': {
        'static-member': {
            propTypes
        },
        'member-property': {
            state
        },
        'member-function': {
            render,
            savePaymentInformation,
            savePaymentMethodAndPlaceOrder,
            saveAddressInformation
        }
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

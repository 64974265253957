import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER as SOURCE_CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS as SOURCE_ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { setAuthorizationToken } from 'Util/Auth';
import { fetchMutation } from 'Util/Request';

import { updateIsLoading } from './MyAccount.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const CUSTOMER = SOURCE_CUSTOMER;

export const ONE_MONTH_IN_SECONDS = SOURCE_ONE_MONTH_IN_SECONDS;

/** @namespace Lafz/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    async verifyOtp(options = {}, dispatch) {
        try {
            const {
                phone_number,
                is_customer,
                otp,
                password,
                // eslint-disable-next-line no-unused-vars
                // confirm_password,
                ...rest
            } = options;
            const mutation = MyAccountQuery.getVerifyOtpMutation({
                phone_number,
                otp
            });

            const result = await fetchMutation(mutation);
            const { verifyOtp: { token, success, message } } = result;

            if (success) {
                if (is_customer) {
                    setAuthorizationToken(token);
                    dispatch(updateCustomerSignInStatus(true));
                    CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                    );
                    WishlistDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
                    );
                    ProductCompareDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
                    );

                    await this.requestCustomerData(dispatch);
                    dispatch(updateIsLoading(false));
                    dispatch(hideActiveOverlay());
                    return true;
                }
                await this.createAccount({
                    customer: {
                        ...rest,
                        phone_number
                    },
                    password
                }, dispatch);

                return true;
            }
            dispatch(showNotification(message));
            return false;
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default new MyAccountDispatcher();

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-equals-spacing */
/* eslint-disable max-lines */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable fp/no-delete */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import ExpandableContent from 'Component/ExpandableContent';
import ExpandableContentShowMore from 'Component/ExpandableContentShowMore';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import { formatPrice } from 'Util/Price';

export const mapStateToProps = (state) => ({
    category: state.CategoryReducer.category
});
export const mapDispatchToProps = () => ({
});

/** @namespace Component/CategoryConfigurableAttributes/Component */
export class CategoryConfigurableAttributes extends ProductConfigurableAttributes {
    getPriceLabel(option) {
        const { currency_code } = this.props;
        const { label } = option;
        const [from, to] = label.split('~');
        const priceFrom = formatPrice(from, currency_code);
        const priceTo = formatPrice(to, currency_code);

        if (from === '*') {
            return __('Up to %s', priceTo);
        }

        if (to === '*') {
            return __('From %s', priceFrom);
        }

        return __('%s - %s', priceFrom, priceTo);
    }

    renderPriceSwatch(option) {
        // return null;

        // eslint-disable-next-line no-unreachable
        const { attribute_options, ...priceOption } = option;

        if (attribute_options) {
            // do not render price filter if it includes "*_*" aggregation
            if (attribute_options['*_*']) {
                return null;
            }

            priceOption.attribute_options = Object.entries(attribute_options).reduce((acc, [key, option]) => {
                acc[key] = {
                    ...option,
                    label: this.getPriceLabel(option)
                };

                return acc;
            }, {});
        }

        // eslint-disable-next-line no-unreachable
        return this.renderDropdownOrSwatch(priceOption);
    }

    renderConfigurableAttributeValue(attribute) {
        const { category } = this.props;

        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected
        } = this.props;

        const { attribute_value, children } = attribute;
        if (attribute.attribute_code !== 'category_id') {
            return (
                <ProductAttributeValue
                  key={ attribute_value }
                  attribute={ attribute }
                  isSelected={ isSelected(attribute) }
                  isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
                  onClick={ handleOptionClick }
                  getLink={ getLink }
                />
            );
        }

        return (
            <>
            { category.id == attribute_value && (
                <ProductAttributeValue
                  key={ attribute_value }
                  attribute={ attribute }
                  isSelected={ isSelected(attribute) }
                  isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
                  onClick={ handleOptionClick }
                  getLink={ getLink }
                />
            ) }
            { (isSelected(attribute) && children) && (
                <div className="filter_childs">
                    { children.map((child) => {
                        const childAttribute = { ...attribute };
                        childAttribute.attribute_value = `${ child.id }`;
                        childAttribute.fromChild = true;
                        childAttribute.childAttributeValues = `${attribute_value}, ${ child.id }`;
                        return (
                            <ProductAttributeValue
                              key={ child.id }
                              attribute={ childAttribute }
                              child={ child }
                              isSelected={ isSelected(childAttribute) }
                              isAvailable={ getIsConfigurableAttributeAvailable(childAttribute) }
                              onClick={ handleOptionClick }
                              getLink={ getLink }
                            />
                        );
                    }) }
                </div>
            ) }
            </>
        );
    }

    renderSwatch(option) {
        const { attribute_values } = option;
        const { category, category: { children } } = this.props;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
            >
                { attribute_values.map((attribute_value) => (
                    category.id == attribute_value ? this.renderConfigurableAttributeValue({
                        ...option, attribute_value, children
                    }) : this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
            </div>
        );
    }

    renderDropdownOrSwatch(option) {
        const {
            getSubHeading
        } = this.props;

        const {
            attribute_label,
            attribute_code,
            attribute_options
        } = option;

        const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
        const isSwatch = !!swatch_data;

        return (
            <ExpandableContent
              key={ attribute_code }
              heading={ attribute_label }
              subHeading={ getSubHeading(option) }
              mix={ {
                  block: 'ProductConfigurableAttributes',
                  elem: 'Expandable'
              } }
              isContentExpanded
            >
                { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
            </ExpandableContent>
        );
    }

    renderConfigurableOption = (option) => {
        if (!option) {
            return null;
        }
        const { attribute_code } = option;

        switch (attribute_code) {
        case 'price':
            return this.renderPriceSwatch(option);
        default:
            return this.renderDropdownOrSwatch(option);
        }
    };

    renderConfigurableAttributes() {
        const { configurable_options } = this.props;

        const { price, category_id } = configurable_options;
        const newObjectToPush = { category_id, price };
        const newSortedConfigurable_options = { ...newObjectToPush, ...configurable_options };

        return Object.values(newSortedConfigurable_options)
            .map(this.renderConfigurableOption);
    }

    renderDropdown(option) {
        const { attribute_values } = option;
        const { category, category: { children } } = this.props;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="DropDownList"
            >
                <ExpandableContentShowMore>
                { attribute_values.map((attribute_value) => (
                    category.id == attribute_value ? this.renderConfigurableAttributeValue({
                        ...option, attribute_value, children
                    }) : this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
                </ExpandableContentShowMore>
            </div>
        );
    }
}

// export default CategoryConfigurableAttributes;
export default connect(mapStateToProps, mapDispatchToProps)(CategoryConfigurableAttributes);

import MobileMenuReducer from '../store/MobileMenu/MobileMenu.reducer';

const getReducers = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    MobileMenuReducer
});

export const config = {
    'Lafz/Store/Index/getStaticReducers': {
        function: [
            {
                position: 11,
                implementation: getReducers
            }
        ]
    }
};

export default config;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

import { RAZORPAY } from '../component/Razorpay/Razorpay.config';

/** @namespace Razorpay/Plugin/CheckoutBillingContainer/getPaymentData */
export const _getPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;

    if (code === RAZORPAY) {
        const [{ razorpay_payment_id, razorpay_order_id, razorpay_signature }] = args[0];
        return {
            code,
            additional_data: {
                razorpay_payment_id,
                razorpay_order_id,
                razorpay_signature
            }
        };
    }

    return callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData
        }
    }
};

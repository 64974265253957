export const MIN_DISCOUNT_AMOUNT = {
    // AED: 100,
    // SAR: 100,
    // QAR: 100,
    // KWD: 10,
    // BHD: 10
};

export const FREEPRODUCT = '8904406199097';

export const FIRST10_MIN_AMOUNT = {
    // AED: 100,
    // SAR: 100,
    // QAR: 100,
    // KWD: 10,
    // BHD: 10
};

export const FIRST10 = 'first10';
export const EXTRA10 = 'extra10'; // BHD
export const XTRA10 = 'xtra10'; // kwd

export const SALES_PAGE_LINK = {
    ae: '/ae/sale-gcc.html',
    bh: '/bh/sale-gcc.html',
    kw: '/kw/sale-gcc.html',
    om: '/om/sale-gcc.html',
    qa: '/qa/sale-gcc.html',
    sa: '/sa/sale-gcc.html',
    in: '/in/sale.html',
    bd: '/bd/sale.html',
    pk: '/pk/sale.html'
};

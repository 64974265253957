import OfferProductReducer from '../store/OfferProduct/OfferProduct.reducer';

export const getStaticReducers = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    OfferProductReducer
});

export default {
    'Lafz/Store/Index/getStaticReducers': {
        function: [
            {
                position: 11,
                implementation: getStaticReducers
            }
        ]
    }
};

/** @namespace Lafz/Component/FoodPacketsCounter/Config/random */
export const random = (min, max) => Math.random() * (max - min) + min;

export const opacityDuration = 1;

export const users = [
    'James',
    'Robert',
    'John',
    'Michael',
    'David',
    'William',
    'Richard',
    'Joseph',
    'Thomas',
    'Charles',
    'Christopher',
    'Daniel',
    'Matthew',
    'Anthony',
    'Mark',
    'Donald',
    'Steven',
    'Paul',
    'Andrew',
    'Joshua',
    'Kenneth',
    'Kevin',
    'Brian',
    'George',
    'Timothy',
    'Ronald',
    'Edward',
    'Jason',
    'Jeffrey',
    'Ryan',
    'Jacob',
    'Gary',
    'Nicholas',
    'Eric',
    'Jonathan',
    'Stephen',
    'Larry',
    'Justin',
    'Scott',
    'Brandon',
    'Benjamin',
    'Samuel',
    'Gregory',
    'Alexander',
    'Frank',
    'Patrick',
    'Raymond',
    'Jack',
    'Dennis',
    'Jerry',
    'Tyler',
    'Aaron',
    'Jose',
    'Adam',
    'Nathan',
    'Henry',
    'Douglas',
    'Zachary',
    'Peter',
    'Kyle',
    'Ethan',
    'Walter',
    'Noah',
    'Jeremy',
    'Christian',
    'Keith',
    'Roger',
    'Terry',
    'Gerald',
    'Harold',
    'Sean',
    'Austin',
    'Carl',
    'Arthur',
    'Lawrence',
    'Dylan',
    'Jesse',
    'Jordan',
    'Bryan',
    'Billy',
    'Joe',
    'Bruce',
    'Gabriel',
    'Logan',
    'Albert',
    'Willie',
    'Alan',
    'Juan',
    'Wayne',
    'Elijah',
    'Randy',
    'Roy',
    'Vincent',
    'Ralph',
    'Eugene',
    'Russell',
    'Bobby',
    'Mason',
    'Philip',
    'Louis'
];

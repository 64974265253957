/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import PincodeQuery from '../../query/Pincode.query';
import ServiceAvailability from './ServiceAvailability.component';
import {
    SUCCESS_STATUS_CODE
} from './ServiceAvailability.config';

export const ShiprocketDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Shiprocket/Shiprocket.dispatcher'
);

/** @namespace Pincodecheck/Component/ServiceAvailability/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    enabled: state.ShiprocketReducer.enabled
});

/** @namespace Pincodecheck/Component/ServiceAvailability/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    checkModuleStatus: () => ShiprocketDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch)
    )
});

/** @namespace Pincodecheck/Component/ServiceAvailability/Container/ServiceAvailabilityContainer */
export class ServiceAvailabilityContainer extends DataContainer {
    static propTypes = {
        showNotification: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
        checkModuleStatus: PropTypes.func.isRequired
    };

    state = {
        pincode: '',
        checking: false,
        checked: false,
        deliveryOptions: null,
        token: null,
        ready: false
    };

    containerFunctions = {
        onChangePin: this.onChangePin.bind(this),
        checkAvailability: this.checkAvailability.bind(this),
        toggleChecked: this.toggleChecked.bind(this)
    };

    componentDidMount() {
        const { checkModuleStatus } = this.props;
        checkModuleStatus();
    }

    onChangePin({ target }) {
        this.setState({ pincode: target.value });
    }

    checkAvailability() {
        const { pincode } = this.state;
        if (pincode === '') {
            return;
        }
        this.setState({ checking: true });
        const { showNotification } = this.props;
        this.fetchData(
            [PincodeQuery.getQuery({ pincode })],
            ({ checkPincode }) => {
                console.log('pincode data', checkPincode);
                if (checkPincode.status === SUCCESS_STATUS_CODE) {
                    const [data] = checkPincode.data.available_courier_companies;
                    this.setState({ deliveryOptions: data, checking: false, checked: true });
                } else {
                    showNotification('error', __('delivery is not available in your area!'));
                    this.setState({ deliveryOptions: null, checking: false, checked: true });
                }
            },
            (error) => {
                console.error('error checking pincode', error);
                this.setState({ deliveryOptions: null, checking: false, checked: true });
                showNotification('error', __('some unknown error occured!'));
            }
        );
    }

    toggleChecked() {
        this.setState({
            pincode: '',
            checking: false,
            checked: false,
            deliveryOptions: null
        });
    }

    render() {
        const { enabled } = this.props;
        console.log('service availability', enabled);
        if (!enabled) {
            return null;
        }

        return (
            <ServiceAvailability
              { ...this.state }
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAvailabilityContainer);

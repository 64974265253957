import CategoryConfigurableAttributes from 'Component/CategoryConfigurableAttributes';

import ExcludeOss from '../component/ExcludeOss';

const renderFilters = (args, callback, instance) => {
    const {
        availableFilters,
        customFiltersValues,
        toggleCustomFilter,
        isMatchingInfoFilter,
        getFilterUrl
    } = instance.props;

    return (
        <div>
            <ExcludeOss />
            <CategoryConfigurableAttributes
            //   mix={ { block: 'CategoryFilterOverlay', elem: 'Attributes' } }
              isReady={ isMatchingInfoFilter }
              configurable_options={ availableFilters }
              getLink={ getFilterUrl }
              parameters={ customFiltersValues }
              updateConfigurableVariant={ toggleCustomFilter }
            />
        </div>
    );
};

export default {
    'Lafz/Component/CategoryFilterOverlay/Component/CategoryFilterOverlayComponent': {
        'member-function': {
            renderFilters
        }
    }
};

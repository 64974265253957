/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/sort-comp */
import { Component } from 'react';

/** @namespace Lafz/Util/Globaleventhandler/CleanAndSustainableBeauty */
export class CleanAndSustainableBeauty extends Component {
    async onClickCleanAndSustainableSectionButton() {
        // eslint-disable-next-line fp/no-let
        let tries = 0;
        while (tries < 10 && (!document.querySelector('#clean_and_sustainable_beauty')
        || typeof document.querySelector('#clean_and_sustainable_beauty') === 'undefined')) {
            tries++;
            // eslint-disable-next-line no-await-in-loop
            await new Promise((r) => setTimeout(r, 500));
        }

        const elem = document.querySelector('#clean_and_sustainable_beauty');
        if (elem) {
            elem.addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector('.clean_and_sustainable_beauty_modal').classList.add('modal_open');
            });

            document.querySelector('.About-Us-close').addEventListener('click', () => {
                document.querySelector('.clean_and_sustainable_beauty_modal').classList.remove('modal_open');
            });
        }
    }

    componentDidMount() {
        this.onClickCleanAndSustainableSectionButton();
        // eslint-disable-next-line
    };

    render() {
        return null;
    }
}

export default CleanAndSustainableBeauty;

/* eslint-disable react/prop-types */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

import LoginStatus from './LoginStatus.component';

/** @namespace Lafz/Component/LoginStatus/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Lafz/Component/LoginStatus/Container/LoginStatusContainer */
export class LoginStatusContainer extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired
    };

    containerFunctions = {};

    containerProps = () => {
        const {
            isSignedIn
        } = this.props;

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};

        return {
            isSignedIn,
            customer
        };
    };

    render() {
        return (
             <LoginStatus
               { ...this.containerProps() }
               { ...this.containerFunctions }
             />
        );
    }
}

/** @namespace Lafz/Component/LoginStatus/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginStatusContainer);

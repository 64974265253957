/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import Razorpay from '../component/Razorpay';
import { RAZORPAY } from '../component/Razorpay/Razorpay.config';

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/renderBraintreePayment */
export function renderRazorpayPayment() {
    const {
        setDetailsStep,
        setOrderButtonEnableStatus
    } = this.props;

    return (
        <Razorpay
          setDetailsStep={ setDetailsStep }
          setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
        />
    );
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/paymentRenderMap */
export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [RAZORPAY]: renderRazorpayPayment.bind(instance)
});

export default {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap
        }
    }
};

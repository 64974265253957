import ServiceAvailability from '../component/ServiceAvailability';

const render = (arg, callback) => (
    <>
        { callback(...arg) }
        <ServiceAvailability />
    </>
);

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderShortDescription: render
        }
    }
};

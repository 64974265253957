import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace Lafz/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    _getCartTotalsFields() {
        return [
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'is_virtual',
            'applied_rule_ids',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'shipping_method',
            'coupon_success_massage',
            this._getCartItemsField(),
            this._getAppliedTaxesField()
        ];
    }

    getCustomerPhoneRule(input = { phonenumber: '' }) {
        return new Field('customerphoneRule')
            .addArgument('input', 'CustomerphoneRule!', input)
            .addFieldList([
                'couponcode',
                'message',
                'success',
                'coupon_type'
            ]);
    }
}

export default new CartQuery();

import {
    getInitialState as sourceGetInitialState
} from 'SourceStore/Overlay/Overlay.reducer';
import { SHOW_POPUP } from 'Store/Popup/Popup.action';

import {
    HIDE_ACTIVE_OVERLAY,
    HIDE_CART_OVERLAY,
    SHOW_CART_OVERLAY,
    TOGGLE_OVERLAY
} from './Overlay.action';

/** @namespace Lafz/Store/Overlay/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    shouldRenderCartOverlay: false
});

/** @namespace Lafz/Store/Overlay/Reducer/OverlayReducer */
export const OverlayReducer = (
    state = getInitialState(),
    action
) => {
    const { overlayKey } = action;
    const {
        activeOverlay: prevActiveOverlay
    } = state;

    switch (action.type) {
    case TOGGLE_OVERLAY:
    case SHOW_POPUP:
        const activeOverlay = prevActiveOverlay === overlayKey ? '' : overlayKey;
        const areOtherOverlaysOpen = prevActiveOverlay !== '';

        return {
            ...state,
            activeOverlay,
            areOtherOverlaysOpen
        };
    case SHOW_CART_OVERLAY:
        return {
            ...state,
            activeOverlay: overlayKey,
            shouldRenderCartOverlay: true,
            areOtherOverlaysOpen: false
        };
    case HIDE_CART_OVERLAY:
    case HIDE_ACTIVE_OVERLAY:
        return {
            ...state,
            activeOverlay: '',
            areOtherOverlaysOpen: false,
            shouldRenderCartOverlay: false
        };

    default:
        return state;
    }
};

export default OverlayReducer;

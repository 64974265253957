import { Field } from 'Util/Query';

/** @namespace SkinAnalyse/Query/SkinAnalyser/Query/SkinAnalyserQuery */
export class SkinAnalyserQuery {
    saveSkinAnalysis(input) {
        return new Field('skinAnalysis')
            .addArgument('input', 'SkinAnalysis!', input)
            .addFieldList([
                'message',
                'success',
                'transaction_id'
            ]);
    }

    getSkinAnalysisResult(transaction_id) {
        return new Field('skinAnalyserResult')
            .addArgument('transaction_id', 'String!', transaction_id)
            .addFieldList([
                'message',
                'success',
                'product_sku'
            ]);
    }

    savePurchasedFromRecommendation(input) {
        return new Field('savePurchasedFromRecommendation')
            .addArgument('input', 'PurchasedFromRecommendation!', input)
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new SkinAnalyserQuery();

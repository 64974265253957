import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

import UtmTrackerQuery from '../query/UtmTracker.query';

const componentDidMount = (args, callback, instance) => {
    const { orderID } = instance.props;
    const utmData = BrowserDatabase.getItem('UTM_DATA') || {};
    const skus = BrowserDatabase.getItem('CART_REWARD_SKU') || [];
    console.log('utmdata-2 -> ', utmData);

    if (utmData) {
        fetchMutation(UtmTrackerQuery.getQuery({
            order_id: orderID,
            utm_source: utmData?.utm_source,
            utm_medium: utmData?.utm_medium,
            utm_campaign: utmData?.utm_campaign || utmData?.utm_term || '',
            rewards_skus: skus.join(', ')
        }))
            .then(() => {
                BrowserDatabase.deleteItem('CART_REWARD_SKU');
            });
    }

    callback.apply(instance, args);
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            componentDidMount
        }
    }
};

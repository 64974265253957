/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

// eslint-disable-next-line max-len
export const PostpayPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ '../route/PostpayPage'));

/** @namespace Postpay/Plugin/RouterComponent/addPostpayRouter */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addPostpayRouter = (originalMember) => ([
    ...[originalMember][0],
    {
        // eslint-disable-next-line max-len
        component: <Route path={ withStoreRegex('/postpay/:returntype') } render={ (props) => <PostpayPage { ...props } /> } />,
        position: 100
    }
]);

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addPostpayRouter
        }
    }
};

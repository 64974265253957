/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Carousel from './Carousel.component';

/** @namespace Lafz/Component/Carousel/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // device: state.ConfigReducer.device
});

/** @namespace Lafz/Component/Carousel/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Lafz/Component/Carousel/Container/CarouselContainer */
export class CarouselContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element).isRequired,
        slidesToShow: PropTypes.number.isRequired,
        slidesToScroll: PropTypes.number.isRequired,
        speed: PropTypes.number,
        infinite: PropTypes.bool,
        dots: PropTypes.bool,
        arrows: PropTypes.bool
    };

    static defaultProps = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        return this.props;
    }

    render() {
        return (
            <Carousel
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarouselContainer);

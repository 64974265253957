/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import CustomSlider1 from 'Component/CustomSlider1';

import './ProductsVideo.style';
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import ProductPrice from 'Component/ProductPrice';
import { BUNDLE, CONFIGURABLE, SIMPLE } from 'Util/Product';
import AddToCart from 'Component/AddToCart';
import { getDefaultCustomizableOptions, isBundleProductOutOfStock } from 'Util/helpers';

/** @namespace Scandi/Component/ProductsVideo/Component/ProductsVideoComponent */
export class ProductsVideoComponent extends PureComponent {
    static propTypes = {
        slides: PropTypes.number.isRequired,
        products: PropTypes.arrayOf( PropTypes ).isRequired,
        paddingInline: PropTypes.string.isRequired,
        sliderProps: PropTypes.object.isRequired,
        productsobject: PropTypes.string.isRequired
    };

    sliderRef = createRef();
    renderPrice(product) {
        const {
                price_range = null,
        } = product;
        if (!price_range) {
            return <TextPlaceholder />;
        }
        return (
            <ProductPrice
              show_percentage={ false }
              price={ price_range }
              mix={ { block: 'productVideos', elem: 'Price' } }
            />
        );
    }

    renderAddToCart(product) {
        const {
            type_id,
            stock_status,
            salable_qty,
            url,
            price_range: {
                maximum_price: {
                    default_final_price: {
                        currency = 'AED'
                    } = {}
                } = {}
            } = {}
        } = product;

        if (!type_id) {
            return null;
        }

        // eslint-disable-next-line no-magic-numbers
        const isInStock = !(currency === 'KWD' && (parseInt(salable_qty, 10) + 5) <= 10 && type_id === 'simple');

        // eslint-disable-next-line fp/no-let
        let isbundleoos = false; let productOptionsData = {};
        if (type_id === BUNDLE) {
            productOptionsData = getDefaultCustomizableOptions(product);
            isbundleoos = isBundleProductOutOfStock(product);
        }

        const groupedProductQuantity = {};

        if (type_id === SIMPLE || type_id === BUNDLE) {
            return (
               <AddToCart
                 product={ product }
                 mix={ { block: 'productVideos', elem: 'AddToCart' } }
                 quantity={ 1 }
                 groupedProductQuantity={ groupedProductQuantity }
                 productOptionsData={ productOptionsData }
                 isRewardProduct={ false }
                 // eslint-disable-next-line no-magic-numbers
                 isOutOfStock={ stock_status === 'OUT_OF_STOCK' || isbundleoos || !isInStock || (type_id === SIMPLE && parseInt(salable_qty, 10) <= 5) }
               />
            );
        }

        const inStock = type_id === CONFIGURABLE || (stock_status && stock_status === 'IN_STOCK');

        return (
           <Link
             block="Button"
             to={ url }
             mix={ { block: 'productVideos', elem: 'AddToCart', mods: { isDisabled: !url } } }
             disabled={ !inStock }
           >
            <svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" d="M0 0h20v20H0z"/>
                <path d="M6 13h9c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1V4H2c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1v2h13l-4 7H6zm-.5 3c.83 0 1.5.67 1.5 1.5S6.33 19 5.5 19 4 18.33 4 17.5 4.67 16 5.5 16m9 0c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5"/>
            </svg>
           </Link>
        );
    }

    cards() {
        const { products = [], productsobject } = this.props;
        return products.map((product)=>(
            <div className="productVideos-Wrap" key={product.sku}>
                <a href={product.url}>
                    <video preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                        <source src={productsobject[product.sku]} type="video/mp4" />
                    </video>
                </a>
                <div className="productVideos-Content">
                <div className="productVideos-Wrapper">
                    <a href={product.url}>
                        <div className="productVideos-Content-img">
                            <img src={product.small_image.url ?? product.thumbnail.url} alt={product.name} />
                        </div>
                    </a>
                    <a href={product.url} className="productVideos-Content-text">
                        <span>{product.name}</span>
                        { this.renderPrice(product) }
                        { this.renderAddToCart(product) }
                    </a>
                </div>
                </div>
            </div>
        ));
    }

    render() {
        const { products, paddingInline, sliderProps, slides } = this.props;
        if (!products && products.length < 1) {
            return;
        }
        if (slides > products.length) {
            return (
                <div className="productVideos-flex">
                    {this.cards()}
                </div>
            )
        }
        return (
            <CustomSlider1 style={ { '--slide-gap': paddingInline } } mix={ { block: 'HtmlSlider' } } { ...sliderProps } sliderRef={ this.sliderRef }>
                {this.cards()}
            </CustomSlider1>
        );
    }
}

export default ProductsVideoComponent;

/* eslint-disable max-len */
import Link from 'Component/Link';

import './Header.style.scss';

export const HIDDEN_STATES = [
    'cart',
    'checkout'
];

const renderDesktopMenu = (args, callback, instance) => {
    const { device } = instance.props;

    if (device.isMobile) {
        return null;
    }

    return callback.apply(instance, args);
    // eslint-disable-next-line no-unreachable
    return (
        <div block="Desktop" elem="Icons">
            <div block="Desktop-Icons" elem="Top">
                <div block="Desktop-Icons" elem="GetFomulaLink">
                    <Link to="/skin-analyser">
                        Skin Analyser
                    </Link>
                </div>
                <div>{ instance.renderWishlistButton() }</div>
                <div>{ instance.renderMinicart() }</div>
                <div>{ instance.renderAccount() }</div>
            </div>
            <div block="Desktop-Icons" elem="Bottom">
                { instance.renderSearchField(true) }
            </div>
        </div>
    );
};

const renderMobileMenu = (args, callback, instance) => {
    const { device, navigationState: { name = '' } = {} } = instance.props;

    if (!device.isMobile || HIDDEN_STATES.includes(name)) {
        return callback.apply(instance, args);
    }

    // return callback.apply(instance, args);
    // eslint-disable-next-line no-unreachable
    return (
        <>
            { (name === 'customer_account' || name === 'customer_sub_account') && device.isMobile && (<style>{ '.Desktop-Icons-GetFomulaLink {right: -6px !important;}' }</style>) }
            { callback.apply(instance, args) }
            <div block="Desktop-Icons" elem="GetFomulaLink">
                <Link to="/skin-analyser">
                    <span block="Desktop-Icons" elem="GetFomulaLinkSpan">
                        Zayn & Myza - Buy Halal Cosmetics & Skin Care Products Online
                    </span>
                    Skin Analyser
                </Link>
            </div>
        </>
    );
};

export default {
    'Lafz/Component/Header/Component/HeaderComponent': {
        'member-function': {
            renderDesktopMenu,
            renderMobileMenu
        }
    }
};

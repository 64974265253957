import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import { toggleExcludeOss } from '../../store/ProductsGrid/ProductsGrid.action';
import ExcludeOss from './ExcludeOss.component';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';

/** @namespace PlpCustomGrid/Component/ExcludeOss/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    excludeOss: state.ProductsGridReducer.excludeOss,
    products: state.ProductsGridReducer.excludeOss
});

/** @namespace PlpCustomGrid/Component/ExcludeOss/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(BOTTOM_NAVIGATION_TYPE)),
    toggleExcludeOss: () => dispatch(toggleExcludeOss())
});

export class ExcludeOssContainer extends PureComponent {
    static propTypes = {
        excludeOss: PropTypes.bool.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        toggleExcludeOss: PropTypes.func.isRequired
    }

    containerFunctions = {
        OnclickExit: this.OnclickExit.bind(this),
        onchange: this.onchange.bind(this)
    }

    containerProps() {
        const { excludeOss } = this.props;

        return {
            checked: excludeOss
        }
    }

    OnclickExit() {
        const {
            hideActiveOverlay,
            goToPreviousHeaderState,
            goToPreviousNavigationState
        } = this.props;

        hideActiveOverlay();
        goToPreviousHeaderState();
        goToPreviousNavigationState();
    }

    onchange() {
        const {
            toggleExcludeOss
        } = this.props;
        toggleExcludeOss();
    }

    render() {
        return (
            <ExcludeOss
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeOssContainer);

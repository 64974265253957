/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import { Splide, SplideSlide } from '@splidejs/react-splide';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
// import ProductDetails from '../ProductDetails';
import ProductCard from 'Component/ProductCard';
import { isMobile } from 'Util/CheckDevice';

import './OfferProduct.style';

/** @namespace OfferProduct/Component/OfferProduct/Component/OfferProductComponent */
export class OfferProductComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        product: PropTypes.object.isRequired
    };

    render() {
        const { product: products } = this.props;

        return (
            <div block="OfferProduct">
                <ContentWrapper>
                    <h1 block="OfferProduct" elem="Heading">GET A FACE SERUM AT JUST 100 TK</h1>
                    <div block="OfferProduct" elem="Card">
                        <Splide
                          options={ {
                              rewind: true,
                              width: '100%',
                              gap: '1rem',
                              type: 'loop',
                              perPage: isMobile() ? 1.3 : 4,
                              pagination: false,
                              arrows: !isMobile()
                          } }
                        >
                            { products.length ? products.map((product) => (
                                <SplideSlide>
                                    <ProductCard
                                      key={ product.sku }
                                      product={ product }
                                    />
                                </SplideSlide>
                            )) : null }
                        </Splide>
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}

export default OfferProductComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { DeviceType } from 'Type/Device';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { fetchQuery } from 'Util/Request';

import SkinAnalyserQuery from '../../query/SkinAnalyser.query';
import ProductRecommendation from './ProductRecommendation.component';

export const ProductRecommendationDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "productrecommendationdispatcher" */
    '../../store/ProductRecommendation/ProductRecommendation.dispatcher'
);

/** @namespace SkinAnalyse/Route/ProductRecommendation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    products: state.ProductRecommendationReducer.products,
    device: state.ConfigReducer.device
});

/** @namespace SkinAnalyse/Route/ProductRecommendation/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state)),
    fetchProduct: (options) => {
        ProductRecommendationDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
        );
    }
});

/** @namespace SkinAnalyse/Route/ProductRecommendation/Container/ProductRecommendationContainer */
export class ProductRecommendationContainer extends PureComponent {
    static propTypes = {
        toggleBreadcrumbs: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        fetchProduct: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        products: PropTypes.object.isRequired,
        device: DeviceType.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        const {
            // eslint-disable-next-line react/prop-types
            match: { params: { transaction_id } = {} } = {},
            showNotification
        } = this.props;

        const txnid = BrowserDatabase.getItem('SKIN_ANALYZER_TXN_ID');
        if (!txnid) {
            BrowserDatabase.setItem(transaction_id, 'SKIN_ANALYZER_TXN_ID');
        }

        fetchQuery(SkinAnalyserQuery.getSkinAnalysisResult(transaction_id))
            .then(
                /** @namespace SkinAnalyse/Route/ProductRecommendation/Container/fetchQuery/then */
                (data) => {
                    const { skinAnalyserResult } = data;
                    if (skinAnalyserResult.success) {
                        const { product_sku } = skinAnalyserResult;
                        if (product_sku !== '') {
                            const skus = product_sku.split(',');
                            this.fetchProducts(skus);
                        } else {
                            showNotification('error', 'Something went wrong');
                            history.replace('/');
                        }
                    } else {
                        showNotification('error', skinAnalyserResult.message);
                        history.replace('/');
                    }
                },
                /** @namespace SkinAnalyse/Route/ProductRecommendation/Container/fetchQuery/then */
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log('skinAnalysis-error', error);
                    showNotification('error', 'Something went wrong');
                    history.replace('/');
                }
            );
        // .finally(
        //     () => {
        //         this.stateState({
        //             isLoading: false
        //         });
        //     }
        // );
    }

    __construct(props) {
        const { toggleBreadcrumbs } = props;
        toggleBreadcrumbs(false);
        this.state = {
            isLoading: true
        };
    }

    containerProps() {
        const { isLoading } = this.state;
        const { products, device } = this.props;

        return {
            isLoading,
            products,
            device
        };
    }

    async fetchProducts(skus) {
        const { fetchProduct } = this.props;
        await fetchProduct({
            args: {
                filter: {
                    productsSkuArray: skus
                }
            }
        });
        this.setState({
            isLoading: false
        });
    }

    render() {
        return (
            <ProductRecommendation
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductRecommendationContainer));

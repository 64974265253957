/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line simple-import-sort/sort
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductPrice from 'Component/ProductPrice';
import ProductReviewRating from 'Component/ProductReviewRating';
// import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';
import { BUNDLE, CONFIGURABLE, SIMPLE } from 'Util/Product';
import AddToCart from 'Component/AddToCart';
import media from 'Util/Media';
import { OPTION_TYPE_COLOR, validOptionTypes, FEATURES_ICONS_PATH } from './ProductCard.config';

import './ProductCard.style';
import { getDefaultCustomizableOptions, isBundleProductOutOfStock } from 'Util/helpers';
/**
  * Product card
  * @class ProductCard
  * @namespace Component/ProductCard/Component
  */
export class ProductCard extends PureComponent {
     static propTypes = {
         linkTo: PropTypes.shape({}),
         product: ProductType.isRequired,
         device: DeviceType.isRequired,
         productOrVariant: ProductType.isRequired,
         thumbnail: PropTypes.string,
         availableVisualOptions: PropTypes.arrayOf(PropTypes.shape({
             label: PropTypes.string,
             value: PropTypes.string,
             type: PropTypes.string
         })).isRequired,
         getAttribute: PropTypes.func.isRequired,
         children: PropTypes.element,
         isLoading: PropTypes.bool,
         mix: PropTypes.shape({}),
         renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
         isConfigurableProductOutOfStock: PropTypes.func.isRequired,
         //  isBundleProductOutOfStock: PropTypes.func.isRequired,
         //  hideWishlistButton: PropTypes.bool,
         siblingsHaveBrands: PropTypes.bool,
         setSiblingsHaveBrands: PropTypes.func,
         siblingsHavePriceBadge: PropTypes.bool,
         setSiblingsHavePriceBadge: PropTypes.func,
         siblingsHaveTierPrice: PropTypes.bool,
         setSiblingsHaveTierPrice: PropTypes.func,
         siblingsHaveConfigurableOptions: PropTypes.bool,
         showTagline: PropTypes.bool,
         setSiblingsHaveConfigurableOptions: PropTypes.func,
         discountPercentage: PropTypes.oneOfType([
             PropTypes.string, PropTypes.number
         ]),
         isRewardProduct: PropTypes.bool.isRequired
     };

     static defaultProps = {
         thumbnail: '',
         linkTo: {},
         children: null,
         isLoading: false,
         mix: {},
         showTagline: false,
         renderContent: false,
         //  hideWishlistButton: false,
         siblingsHaveBrands: false,
         setSiblingsHaveBrands: () => null,
         siblingsHavePriceBadge: false,
         setSiblingsHavePriceBadge: () => null,
         siblingsHaveTierPrice: false,
         setSiblingsHaveTierPrice: () => null,
         siblingsHaveConfigurableOptions: false,
         setSiblingsHaveConfigurableOptions: () => null,
         discountPercentage: 0
     };

     contentObject = {
         renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
         pictureBlock: {
             picture: this.renderPicture.bind(this)
         },
         content: {
             review: this.renderReviews.bind(this),
             productPrice: this.renderProductPrice.bind(this),
             confOptions: this.renderVisualConfigurableOptions.bind(this),
             tierPrice: this.renderTierPrice.bind(this),
             mainDetails: this.renderMainDetails.bind(this),
             additionalProductDetails: this.renderAdditionalProductDetails.bind(this)
         }
     };

     imageRef = createRef();

     registerSharedElement = () => {
         window.scrollTo({
             left: 0,
             top: 0,
             behavior: 'smooth'
         });
     };

     renderConfigurablePriceBadge() {
         const {
             product: { type_id },
             siblingsHavePriceBadge,
             setSiblingsHavePriceBadge
         } = this.props;

         if (type_id !== CONFIGURABLE) {
             return null;
         }

         if (!siblingsHavePriceBadge) {
             setSiblingsHavePriceBadge();
         }

         return (
             <p
               mix={ {
                   block: 'ProductCard',
                   elem: 'PriceBadge'
               } }
             >
                 { __('As Low as') }
             </p>
         );
     }

     renderEmptyProductPrice() {
         return (
             <div
               block="ProductCard"
               elem="PriceWrapper"
               mods={ { isEmpty: true } }
             />
         );
     }

     renderConfigurableProductPrice() {
         const {
             product: { price_range }
         } = this.props;

         return (
            <div block="ProductCard" elem="PriceWrapper">
                <ProductPrice
                  show_percentage={ false }
                  price={ price_range }
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                />
            </div>
         );
     }

     renderProductPrice() {
         const {
             product: { price_range, type_id },
             isConfigurableProductOutOfStock
             //  isBundleProductOutOfStock
         } = this.props;

         if (!price_range) {
             return null;
         }

         switch (type_id) {
         case CONFIGURABLE:
             if (isConfigurableProductOutOfStock()) {
                 return this.renderEmptyProductPrice();
             }
             break;
         case BUNDLE:
             //  if (isBundleProductOutOfStock()) {
             //      return this.renderEmptyProductPrice();
             //  }
             this.renderConfigurableProductPrice();
             break;
         default:
             break;
         }

         return (
             <div block="ProductCard" elem="PriceWrapper">
                 { this.renderTierPrice() }
                 { /* this.renderConfigurablePriceBadge() */ }
                 <ProductPrice
                   show_percentage={ false }
                   price={ price_range }
                   mix={ { block: 'ProductCard', elem: 'Price' } }
                 />
             </div>
         );
     }

     renderTierPrice() {
         const {
             productOrVariant,
             siblingsHaveTierPrice,
             setSiblingsHaveTierPrice
         } = this.props;
         const { price_tiers } = productOrVariant;

         if (!price_tiers || !price_tiers.length) {
             return null;
         }

         if (!siblingsHaveTierPrice) {
             setSiblingsHaveTierPrice();
         }

         return (
             <TierPrices
               product={ productOrVariant }
               isLowestPrice
             />
         );
     }

     renderVisualOption({ value, label, type }, i) {
         const isColor = type === OPTION_TYPE_COLOR;

         return (
             <span
               block="ProductCard"
               elem={ isColor ? 'Color' : 'String' }
               key={ i }
               style={ isColor ? { backgroundColor: value } : {} }
               aria-label={ isColor ? label : '' }
               title={ isColor ? '' : label }
             >
                 { isColor ? '' : value }
             </span>
         );
     }

     renderVisualConfigurableOptions() {
         const {
             siblingsHaveConfigurableOptions,
             setSiblingsHaveConfigurableOptions,
             availableVisualOptions,
             device
         } = this.props;

         if (device.isMobile || !availableVisualOptions.length) {
             return <div block="ProductCard" elem="ConfigurableOptions" />;
         }

         if (!validOptionTypes.includes(availableVisualOptions[0].type)) {
             return <div block="ProductCard" elem="ConfigurableOptions" />;
         }

         if (!siblingsHaveConfigurableOptions) {
             setSiblingsHaveConfigurableOptions();
         }

         return (
             <div block="ProductCard" elem="ConfigurableOptions">
                 { availableVisualOptions.map(this.renderVisualOption) }
             </div>
         );
     }

     renderPicture(mix = {}) {
         const { product: { id, name }, thumbnail } = this.props;

         const alt = `Zayn & Myza ${name}`;

         this.sharedComponent = (
             <Image
               imageRef={ this.imageRef }
               src={ thumbnail }
               alt={ alt }
               title={ name }
               ratio="custom"
               mix={ { block: 'ProductCard', elem: 'Picture', mix } }
               isPlaceholder={ !id }
             />
         );

         return (
             <>
         { /* this.sharedComponent */ }
                 <img
                   ref={ this.imageRef }
                   src={ thumbnail }
                   alt={ alt }
                   title={ name }
                 />
             </>
         );
     }

     renderReviews() {
         const {
             product: {
                 review_summary: {
                     rating_summary
                 } = {}
             }
         } = this.props;

         return (
             <div
               block="ProductCard"
               elem="Reviews"
             >
                 <ProductReviewRating summary={ rating_summary } />
             </div>
         );
     }

     renderProductCardWishlistButton() {
         return null;
         //  const { product, hideWishlistButton } = this.props;

         //  if (hideWishlistButton) {
         //      return null;
         //  }

         //  return (
         //      <ProductWishlistButton
         //        product={ product }
         //        mix={ { block: 'ProductCard', elem: 'WishListButton' } }
         //      />
         //  );
     }

     renderBrandValue() {
         const {
             getAttribute,
             siblingsHaveBrands,
             setSiblingsHaveBrands
         } = this.props;
         const {
             product_list_content: {
                 attribute_to_display
             } = {}
         } = window.contentConfiguration;
         const brand = getAttribute(attribute_to_display || 'brand');

         if (!brand) {
             return null;
         }

         if (!siblingsHaveBrands) {
             setSiblingsHaveBrands();
         }

         return (
             <ProductAttributeValue
               attribute={ brand }
               isFormattedAsText
               mix={ {
                   block: 'ProductCard',
                   elem: 'BrandAttributeValue'
               } }
             />
         );
     }

     renderAdditionalProductDetails() {
         return (
             <div block="ProductCard" elem="Brand">
                 { this.renderBrandValue() }
             </div>
         );
     }

     renderMainDetails() {
         const { product: { name } } = this.props;

         return (
             <p
               block="ProductCard"
               elem="Name"
               mods={ { isLoaded: !!name } }
             >
                 <TextPlaceholder content={ name } length="medium" />
             </p>
         );
     }

     renderCardLinkWrapper(children, mix = {}) {
         const { linkTo, product: { url } } = this.props;

         if (!url) {
             return (
                 <div
                   block="ProductCard"
                   elem="Link"
                 >
                     { children }
                 </div>
             );
         }

         return (
             <Link
               block="ProductCard"
               elem="Link"
               to={ linkTo }
               onClick={ this.registerSharedElement }
               mix={ mix }
             >
               { children }
             </Link>
         );
     }

     renderCategory() {
         return (
            <div block="ProductCard" elem="CategoryName">Makeup</div>
         );
     }

     renderCardContent() {
         const { renderContent, showTagline } = this.props;

         if (renderContent) {
             return renderContent(this.contentObject);
         }

         return (
             this.renderCardLinkWrapper((
                 <>
                     <div block="ProductCard" elem="Figure-Review">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                     </div>
                     <div block="ProductCard" elem="Content">
                            { this.renderAdditionalProductDetails() }
                            { /* <div block="ProductCard" elem="Features"> */ }
                            { /*    { this.renderFeatures() } */ }
                            { /* </div> */ }
                            <div block="ProductCard" elem="Content" mods={ { namewrapper: true } }>
                                <div
                                  block="CardBlock"
                                  mods={ { showTagline } }
                                >
                                     { this.renderReviews() }
                                     { this.renderMainDetails() }
                                     { /* { this.renderCategory() } */ }
                                     { this.renderTagLine() }
                                </div>
                             { this.renderProductPrice() }
                            </div>
                            { /* this.renderVisualConfigurableOptions() */ }
                     </div>
                 </>
             ))
         );
     }

     renderTagLine() {
         const {
             product: { attributes } = {},
             showTagline
         } = this.props;

         if (!showTagline) {
             return null;
         }

         // eslint-disable-next-line fp/no-let
         let tagline = '';
         if (typeof (attributes) !== 'undefined' && typeof (attributes.tagline) !== 'undefined') {
             tagline = attributes.tagline.attribute_value;
         }

         return (
             <div block="Tagline">{ tagline }</div>
         );
     }

     renderFeaturesData(product_features) {
         const { attribute_options = {} } = product_features;
         if (attribute_options) {
             return Object.keys(attribute_options).map((key, i) => {
                 // eslint-disable-next-line no-magic-numbers
                 if (i >= 3) {
                     return null;
                 }
                 const { label = null } = attribute_options[key];
                 if (label) {
                     return (
                           <div
                             block="Features"
                             elem="column"
                             // eslint-disable-next-line react/no-array-index-key
                             key={ `${label}_${i}` }
                           >
                               <img
                                 src={ media(`${label}.png`, FEATURES_ICONS_PATH) }
                                 alt=""
                                 // eslint-disable-next-line max-len
                                 onError={ (e) => e.currentTarget.src = '/media/product-features/icons/default.png' }
                               />
                               <div block="Features-label">{ label }</div>
                           </div>
                     );
                 }

                 return null;
             });
         }

         return null;
     }

     renderFeatures() {
         const { product: { attributes: { product_features = null } = {} } } = this.props;

         if (product_features) {
             return this.renderFeaturesData(product_features);
         }

         return null;
     }

     renderAddToCart() {
         const {
             productOrVariant: product,
             linkTo,
             isRewardProduct
         } = this.props;

         const {
             type_id,
             stock_status,
             salable_qty,
             price_range: {
                 maximum_price: {
                     default_final_price: {
                         currency = 'AED'
                     } = {}
                 } = {}
             } = {}
         } = product;

         if (!type_id) {
             return null;
         }

         // eslint-disable-next-line no-magic-numbers
         const isInStock = !(currency === 'KWD' && (parseInt(salable_qty, 10) + 5) <= 10 && type_id === 'simple');

         // eslint-disable-next-line fp/no-let
         let isbundleoos = false; let productOptionsData = {};
         if (type_id === BUNDLE) {
             productOptionsData = getDefaultCustomizableOptions(product);
             isbundleoos = isBundleProductOutOfStock(product);
         }

         const groupedProductQuantity = {};

         if (type_id === SIMPLE || type_id === BUNDLE) {
             return (
                <AddToCart
                  product={ product }
                  mix={ { block: 'ProductCard', elem: 'AddToCart' } }
                  quantity={ 1 }
                  groupedProductQuantity={ groupedProductQuantity }
                  productOptionsData={ productOptionsData }
                  isRewardProduct={ isRewardProduct }
                  // eslint-disable-next-line no-magic-numbers
                  isOutOfStock={ stock_status === 'OUT_OF_STOCK' || isbundleoos || !isInStock || (type_id === SIMPLE && parseInt(salable_qty, 10) <= 5) }
                />
             );
         }

         const inStock = type_id === CONFIGURABLE || (stock_status && stock_status === 'IN_STOCK');

         return (
            <Link
              block="Button"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ { block: 'ProductCard', elem: 'AddToCart', mods: { isDisabled: !linkTo } } }
              disabled={ !inStock }
            >
                <span>
                    { inStock
                        ? __('Add to cart') : 'Sold Out' }
                </span>
            </Link>
         );
     }

     renderDiscountPercentage() {
         const {
             discountPercentage
         } = this.props;

         if (!discountPercentage || discountPercentage === -Infinity) {
             return null;
         }

         return (
                <span
                  block="ProductCard"
                  elem="DiscountPerc"
                  mods={ { isVisible: discountPercentage > 0 } }
                  aria-label={ __('Save') }
                >
                    { /* eslint-disable-next-line no-bitwise */ }
                    <span>{ `${(parseInt(discountPercentage, 10) | 0) }% off` }</span>
                </span>
         );
     }

     renderProductBadge() {
         const { product } = this.props;
         if (!product) {
             return null;
         }
         const { attributes = {} } = product;
         if (!attributes || !attributes.product_badge) {
             return null;
         }
         const { attribute_value = '' } = attributes.product_badge || {};
         if (attribute_value === '') {
             return null;
         }

         return (
               <span
                 block="ProductCard"
                 elem="Badge"
                 aria-label={ attribute_value }
               >
                   { attribute_value }
               </span>
         );
     }

     renderBuyOneGetOne() {
         const { product } = this.props;
         if (!product) {
             return null;
         }
         const { attributes } = product;
         if (!attributes || !attributes.buyonegetone) {
             return null;
         }
         const { attribute_value } = attributes.buyonegetone;
         if (attribute_value === '0') {
             return null;
         }

         return (
            // eslint-disable-next-line global-require
            <img block="ProductCard" elem="buyonegetone" alt="buy one get one free" src={ require('./images/zmbuyonegetone.png').default } />
         );
     }

     render() {
         const {
             children,
             mix,
             isLoading,
             siblingsHaveBrands,
             siblingsHavePriceBadge,
             siblingsHaveTierPrice,
             siblingsHaveConfigurableOptions
         } = this.props;

         const mods = {
             siblingsHaveBrands,
             siblingsHavePriceBadge,
             siblingsHaveTierPrice,
             siblingsHaveConfigurableOptions
         };

         return (
             <li
               block="ProductCard"
               mods={ mods }
               mix={ mix }
             >
                 <Loader isLoading={ isLoading } />
                 { this.renderCardContent() }
                 { this.renderBuyOneGetOne() }
                 { this.renderProductCardWishlistButton() }
                 <div block="ProductCard" elem="AdditionalContent">
                     { children }
                 </div>
                 <div block="ProductCard" elem="AddToCart-Wrapper">
                     { this.renderAddToCart() }
                 </div>
                 { this.renderDiscountPercentage() }
                 { this.renderProductBadge() }
             </li>
         );
     }
}

export default ProductCard;

import './YouSave.style.scss';

const renderYouSaveText = (args, callback, instance) => {
    const { totals = {} } = instance.props;

    const coupondiscount = Math.abs(totals.discount_amount);

    const productsDiscount = totals?.items?.reduce((all, curr) => {
        if (!curr || !curr.product) {
            return all;
        }
        const {
            price_range: {
                minimum_price: {
                    discount: {
                        amount_off = 0
                    } = {}
                } = {}
            } = {}
        } = curr.product || {};
        const { qty } = curr || {};

        return all + (qty * amount_off);
    }, 0);

    const totalDiscount = coupondiscount + productsDiscount;

    if (totalDiscount <= 0) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            { /* <p block="CheckoutOrderSummary" elem="YouSave" mods={ { isCheckout: true } }>
                You save
                { ' ' }
                    <span>
                    { totalDiscount.toFixed(2) }
                    { ' ' }
                    { totals.quote_currency_code }
                    </span>
                { ' ' }
                on this order
            </p> */ }
        </>
    );
};

export default {
    'Lafz/Component/CheckoutOrderSummary/Component/CheckoutOrderSummaryComponent': {
        'member-function': {
            renderYouSaveText
        }
    }
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        cartTotals: state.CartReducer.cartTotals
    };
};

const containerProps = (args, callback, instance) => {
    const { cartTotals } = instance.props;
    return {
        ...callback(...args),
        cartTotals
    };
};

const removeProductAndUpdateCrossSell = async (_args, _callback, instance) => {
    const {
        removeProduct,
        updateCrossSellProducts,
        updateCrossSellsOnRemove,
        item: { item_id }
    } = instance.props;

    const cartData = await removeProduct(item_id);

    const {
        cross_sell_product_sku: {
            is_enable = false,
            minimum_total = 0,
            product_sku = []
        },
        items = []
    } = cartData;

    // eslint-disable-next-line fp/no-let
    let result = cartData;

    const grand_total = items.reduce((acc, item) => {
        if (product_sku.includes(item.sku)) {
            return acc;
        }

        return acc + (item.price * item.qty);
    }, 0);

    if (is_enable && minimum_total > grand_total) {
        const isInCart = items.findIndex((item) => {
            const check = product_sku.includes(item.sku);

            return check;
        });

        if (isInCart >= 0) {
            const {
                item_id: offeritem_id
            } = items[isInCart];

            result = await removeProduct(offeritem_id);
        }
    }

    if (result && updateCrossSellsOnRemove) {
        await updateCrossSellProducts(result.items);
    }

    return result;
};

export default {
    'Component/CartItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartItem/Container': {
        'member-function': {
            containerProps,
            removeProductAndUpdateCrossSell
        }
    }
};

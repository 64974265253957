import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.container';

import Breadcrumbs from './Breadcrumbs.component';

/** @namespace Lafz/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    base_url: state.ConfigReducer.base_url,
    code: state.ConfigReducer.code,
    showBreadcrumbSchema: state.BreadcrumbsReducer.showBreadcrumbSchema
});

/** @namespace Lafz/Component/Breadcrumbs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);

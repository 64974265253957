/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* eslint-disable import/prefer-default-export */
import currencyMap from './Price.config';
import PriceFractions from './PriceFractionsConfig';

export const getFraction = (currency = null, type = null) => {
    if (currency && type) {
        if (PriceFractions[currency]) {
            const { [type]: typeFR = 0 } = PriceFractions[currency];
            return typeFR;
        }
    }

    return 0;
};

/** @namespace Util/Price/formatCurrency */
export const formatCurrency = (currency = 'USD') => currencyMap[currency];

/** @namespace Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    return new Intl.NumberFormat(language,
        {
            style: 'currency',
            currency,
            minimumFractionDigits: getFraction(currency, 'min'),
            maximumFractionDigits: getFraction(currency, 'max')
        }).format(price);
};

/**
 * Calculate final price
 * @param {Number} discount discount percentage
 * @param {Number} min minimum price
 * @param {Number} reg regular price
 * @return {Number} final price
 * @namespace Util/Price/calculateFinalPrice
 */
export const calculateFinalPrice = (discount, min, reg) => (discount ? min : reg);

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Util/Price/roundPrice
 */
export const roundPrice = (price) => parseFloat(price).toFixed(2);

export const getProductPrice = ({
    discount = {},
    regular_price = {},
    final_price = {}
}) => {
    const { amount_off = 0 } = discount;
    const { value, currency } = amount_off > 0 ? final_price : regular_price;
    const formatted = formatPrice(value, currency);

    return {
        raw: value,
        currency,
        formatted
    };
};

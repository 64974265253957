export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_MENU = 'OPEN_MENU';

/** @namespace MobileMenu/Store/MobileMenu/Action/closeMenu */
export const closeMenu = () => ({
    type: CLOSE_MENU
});

/** @namespace MobileMenu/Store/MobileMenu/Action/openMenu */
export const openMenu = () => ({
    type: OPEN_MENU
});

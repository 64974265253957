/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import CustomSlider1 from 'Component/CustomSlider1';

import './ProductsCustomCards.style';
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import ProductPrice from 'Component/ProductPrice';
import { BUNDLE, CONFIGURABLE, SIMPLE } from 'Util/Product';
import AddToCart from 'Component/AddToCart';
import { getDefaultCustomizableOptions, isBundleProductOutOfStock } from 'Util/helpers';

/** @namespace Scandi/Component/ProductsCustomCards/Component/ProductsCustomCardsComponent */
export class ProductsCustomCardsComponent extends PureComponent {
    static propTypes = {
        slides: PropTypes.number.isRequired,
        products: PropTypes.arrayOf( PropTypes ).isRequired,
        paddingInline: PropTypes.string.isRequired,
        sliderProps: PropTypes.object.isRequired,
        productsobject: PropTypes.string.isRequired
    };

    sliderRef = createRef();
    renderPrice(product) {
        const {
                price_range = null,
        } = product;
        if (!price_range) {
            return <TextPlaceholder />;
        }
        return (
            <ProductPrice
              show_percentage={ false }
              price={ price_range }
              mix={ { block: 'ProductsCustomCards', elem: 'Price' } }
            />
        );
    }

    renderAddToCart(product) {
        const {
            type_id,
            stock_status,
            salable_qty,
            url,
            price_range: {
                maximum_price: {
                    default_final_price: {
                        currency = 'AED'
                    } = {}
                } = {}
            } = {}
        } = product;

        if (!type_id) {
            return null;
        }

        // eslint-disable-next-line no-magic-numbers
        const isInStock = !(currency === 'KWD' && (parseInt(salable_qty, 10) + 5) <= 10 && type_id === 'simple');

        // eslint-disable-next-line fp/no-let
        let isbundleoos = false; let productOptionsData = {};
        if (type_id === BUNDLE) {
            productOptionsData = getDefaultCustomizableOptions(product);
            isbundleoos = isBundleProductOutOfStock(product);
        }

        const groupedProductQuantity = {};

        if (type_id === SIMPLE || type_id === BUNDLE) {
            return (
               <AddToCart
                 product={ product }
                 mix={ { block: 'add-to-cart-btn ProductsCustomCards', elem: 'AddToCart' } }
                 quantity={ 1 }
                 groupedProductQuantity={ groupedProductQuantity }
                 productOptionsData={ productOptionsData }
                 isRewardProduct={ false }
                 // eslint-disable-next-line no-magic-numbers
                 isOutOfStock={ stock_status === 'OUT_OF_STOCK' || isbundleoos || !isInStock || (type_id === SIMPLE && parseInt(salable_qty, 10) <= 5) }
               />
            );
        }

        const inStock = type_id === CONFIGURABLE || (stock_status && stock_status === 'IN_STOCK');

        return (
           <Link
             block="Button"
             to={ url }
             mix={ { block: 'add-to-cart-btn ProductsCustomCards', elem: 'AddToCart', mods: { isDisabled: !url } } }
             disabled={ !inStock }
           >
           <span>
               { inStock
                   ? __('Add to cart') : 'Sold Out' }
           </span>
           </Link>
        );
    }

    cards() {
        const { products = [], productsobject } = this.props;
        return products.map((product)=>(
            <div className="ProductsCustomCards-Wrap" key={product.sku}>
                <div className="ProductsCustomCards-images">
                    <img src={ productsobject[product.sku]?.img ?? product.small_image.url ?? product.thumbnail.url} alt={product.name} />
                </div>
                <div class="ProductsCustomCards-info">
                    <h3 className="ProductsCustomCards-title">{product.name}</h3>
                    <p className="ProductsCustomCards-text">{productsobject[product.sku]?.useFor}</p>
                        { this.renderPrice(product) }
                        { this.renderAddToCart(product) }
                </div>
            </div>
        ));
    }

    render() {
        const { products, paddingInline, sliderProps, slides } = this.props;
        if (!products && products.length < 1) {
            return;
        }
        if (slides > products.length) {
            return (
                <div className="ProductsCustomCards-flex">
                    {this.cards()}
                </div>
            )
        }
        return (
            <CustomSlider1 style={ { '--slide-gap': paddingInline } } mix={ { block: 'HtmlSlider' } } { ...sliderProps } sliderRef={ this.sliderRef }>
                {this.cards()}
            </CustomSlider1>
        );
    }
}

export default ProductsCustomCardsComponent;

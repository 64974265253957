/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import Link from 'Component/Link';
import { ProductType } from 'Type/ProductList';
import { getDefaultCustomizableOptions, isBundleProductOutOfStock } from 'Util/helpers';
import { BUNDLE } from 'Util/Product';

import './CustomCartButton.style';

/** @namespace Lafz/Component/CustomCartButton/Component/CustomCartButtonComponent */
export class CustomCartButtonComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        linkTo: PropTypes.string.isRequired
    };

    renderAddToCart() {
        const {
            product,
            linkTo
        } = this.props;

        const { type_id, name } = product;

        if (!type_id || !name) {
            return null;
        }
        // eslint-disable-next-line fp/no-let
        let isbundleoos = false; let productOptionsData = {};
        if (type_id === BUNDLE) {
            productOptionsData = getDefaultCustomizableOptions(product);
            isbundleoos = isBundleProductOutOfStock(product);
        }

        const groupedProductQuantity = {};

        if (type_id === 'simple' || type_id === BUNDLE) {
            return (
                <AddToCart
                  product={ product }
                  mix={ { block: 'ProductCard', elem: 'AddToCart' } }
                  quantity={ 1 }
                  groupedProductQuantity={ groupedProductQuantity }
                  productOptionsData={ productOptionsData }
                  isRewardProduct={ false }
                  isOutOfStock={ isbundleoos }
                />
            );
        }

        return (
            <Link
              block="Button"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ { block: 'ProductCard', elem: 'AddToCart' } }
            >
                <span>{ __('Add to cart') }</span>
            </Link>
        );
    }

    render() {
        return (
            <div block="CustomCartButton">
                { this.renderAddToCart() }
            </div>
        );
    }
}

export default CustomCartButtonComponent;

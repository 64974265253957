import { Field } from 'Util/Query';

/** @namespace Productalert/Query/Preorder/Query/PreorderQuery */
export class PreorderQuery {
    getQuery(options) {
        return new Field('preOrder')
            .addArgument('product_sku', 'String!', options.product_sku)
            .addArgument('name', 'String!', options.name)
            .addArgument('email', 'String!', options.email)
            .addArgument('phone_number', 'String!', options.phone_number)
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new PreorderQuery();

import BrowserDatabase from 'Util/BrowserDatabase';

import {
    FETCH_USER_PROFILE,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';

const onFormSubmitDone = (args, callback, instance) => {
    const [email] = args;
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    triggerEvent({
        name: FETCH_USER_PROFILE,
        clevertapData: {
            Site: {
                Email: email,
                Source: 'Newsletter',
                'MSG-email': true,
                'MSG-push': true,
                'MSG-sms': true,
                'MSG-whatsapp': true,
                'UTM Source': utmData?.utm_source,
                'UTM Medium': utmData?.utm_medium,
                'UTM Campaign': utmData?.utm_campaign
            }
        }
    });
    callback.apply(instance, args);
};

export default {
    'Lafz/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer': {
        'member-function': {
            onFormSubmitDone
        }
    }
};

/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import axios from 'axios';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    // PAYMENT_TOTALS,
    SUCCESS_DATA
} from 'Route/Checkout/Checkout.config';
import { showNotification } from 'Store/Notification/Notification.action';
// import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';
// import { getConvertedCurrency } from 'Util/Currency/Currency';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

// import PostpayPaylaterQuery from '../../query/PostpayPaylater.query';
import PostpayPaylaterComponent from './PostpayPaylater.component';

export const PostpayDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Postpay/Postpay.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Postpay/Component/PostpayPaylater/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isRedirect: state.PostpayReducer.isRedirect,
    isLoading: state.PostpayReducer.isLoading,
    PostpayPaylaterData: state.PostpayReducer.PostpayPaylaterData
});

/** @namespace Postpay/Component/PostpayPaylater/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
    )
});

/** @namespace Postpay/Component/PostpayPaylater/Container/PostpayPaylaterContainer */
export class PostpayPaylaterContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        isRedirect: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        PostpayPaylaterData: PropTypes.object.isRequired,
        cart: PropTypes.isRequired,
        shippingFields: PropTypes.isRequired,
        email: PropTypes.string.isRequired,
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired
    };

    // eslint-disable-next-line react/sort-comp
    __construct() {
        this.state = {
            isLoading: false,
            PostpayPaylaterStatus: ''
        };
    }

    static defaultProps = {
        isLoading: false
    };

    static getRef() {
        const store = getStore();
        const { dispatch, getState } = store;
        const reduxState = getState();
        const cart = reduxState.CartReducer.cartTotals;
        const { shippingFields, email } = reduxState.CheckoutReducer;
        const { base_url } = reduxState.ConfigReducer;
        return new Promise((resolve, reject) => {
            (async () => {
                const guest_cart_id = getGuestQuoteId();

                PostpayPaylaterDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateIsPaylaterLoading(dispatch, true)
                );

                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
                const { placeOrder: { order: { order_id } } } = orderData;

                await fetchMutation(CheckoutQuery.changeOrderStatusMutation(order_id));

                deleteGuestQuoteId();

                if (isSignedIn()) {
                    CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                    );
                } else {
                    CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
                    );
                }

                const items = cart.items.reduce((all, current) => {
                    let url = new URL(current.product.url, base_url).href;
                    let unit_price = current.row_total_incl_tax;
                    // let unit_price = getConvertedCurrency(cart.quote_currency_code, 'AED', current.row_total_incl_tax);
                    all.push({
                        reference: `${current.sku}`,
                        name: `${current.product.name}`,
                        url,
                        image_url: `${current.product.thumbnail.url}`,
                        unit_price,
                        qty: current.qty
                    });

                    return all;
                }, []);

                const total_amount = cart.grand_total;
                const shipping_tax = cart.shipping_incl_tax;
                const data = JSON.stringify({
                    quoteId: guest_cart_id,
                    postPayData: {
                        total_amount,
                        tax_amount: 0,
                        currency: cart.quote_currency_code,
                        shipping: {
                            amount: shipping_tax,
                            name: 'Shipping Address',
                            address: {
                                first_name: shippingFields.firstname,
                                last_name: shippingFields.lastname,
                                phone: shippingFields.telephone,
                                line1: shippingFields.street0,
                                line2: shippingFields.street1,
                                city: shippingFields.city,
                                country: shippingFields.country_id,
                                postal_code: shippingFields.postcode
                            }
                        },
                        billing_address: {
                            first_name: shippingFields.firstname,
                            last_name: shippingFields.lastname,
                            phone: shippingFields.telephone,
                            line1: shippingFields.street0,
                            line2: shippingFields.street1,
                            city: shippingFields.city,
                            country: shippingFields.country_id,
                            postal_code: shippingFields.postcode
                        },
                        customer: {
                            email,
                            first_name: shippingFields.firstname,
                            last_name: shippingFields.lastname
                        },
                        items,
                        num_instalments: 3
                    }
                });

                const config = {
                    method: 'post',
                    url: '/rest/V1/postpay/order/initiate',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data
                };

                axios(config)
                    .then(
                        /** @namespace Postpay/Component/PostpayPaylater/Container/axios/then */
                        ({ data }) => {
                            const { redirect_url } = data;
                            BrowserDatabase.setItem({ quoteId: guest_cart_id, ...data }, SUCCESS_DATA);

                            PostpayPaylaterDispatcher.then(
                                ({ default: dispatcher }) => dispatcher.updatePostpayPaylaterData(dispatch, { url: redirect_url })
                            );

                            PostpayPaylaterDispatcher.then(
                                ({ default: dispatcher }) => dispatcher.updatePaylaterRedirect(dispatch, true)
                            );
                        }
                    )
                    .catch(
                        /** @namespace Postpay/Component/PostpayPaylater/Container/axios/then/catch */
                        (error) => {
                            throw error;
                        }
                    );

                reject();
            })();
        });
    }

    componentDidUpdate(prevProps) {
        const {
            isRedirect, isLoading
        } = this.props;

        if (prevProps.isRedirect !== isRedirect) {
            if (isRedirect) {
                this.redirect();
            }
        }

        if (prevProps.isLoading !== isLoading) {
            if (isLoading) {
                this.setProgress(true, __('Pleasewait...'), false);
            }
        }
    }

    redirect() {
        const { PostpayPaylaterData: { url } } = this.props;

        if (url !== '') {
            try {
                this.setProgress(true, __('Redirecting you to payment page...'), false);

                setTimeout(() => {
                    window.location.href = url;
                // eslint-disable-next-line no-magic-numbers
                }, 3000);
            } catch (e) {
                showNotification('error', __('Something went wrong'));
            }
        }
    }

    setProgress(loading, status, btnStatus) {
        this.setState({ isLoading: loading, PostpayPaylaterStatus: status });
        const { setOrderButtonEnableStatus } = this.props;
        setOrderButtonEnableStatus(btnStatus);
    }

    render() {
        return (
            <PostpayPaylaterComponent
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostpayPaylaterContainer);

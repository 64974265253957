import { PureComponent } from 'react';

import CustomGridLoading from './CustomGridLoading.component';

/** @namespace PlpCustomGrid/Component/CustomGridLoading/Container/CustomGridLoadingContainer */
export class CustomGridLoadingContainer extends PureComponent {
    render() {
        return (
            <CustomGridLoading />
        );
    }
}

export default CustomGridLoadingContainer;

/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';

import ProductRecommendation from '../route/ProductRecommendation';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

// eslint-disable-next-line max-len
export const SkinAnalyser = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ '../route/SkinAnalyser'));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addSslcommerzeRouter = (originalMember) => ([
    ...[originalMember][0],
    {
        component: <Route exact path={ withStoreRegex('/skin-analyse') } render={ (props) => <SkinAnalyser { ...props } /> } />,
        position: 100
    },
    {
        // eslint-disable-next-line max-len
        component: <Route exact path={ withStoreRegex('/skin-analyse/products-recommended/:transaction_id') } render={ (props) => <ProductRecommendation { ...props } /> } />,
        position: 100
    }
]);

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addSslcommerzeRouter
        }
    }
};

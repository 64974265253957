/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ChevronIcon.style';

/** @namespace Lafz/Component/ChevronIcon/Component/ChevronIconComponent */
export class ChevronIconComponent extends PureComponent {
    static propTypes = {
        direction: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        mix: PropTypes.object
    };

    static defaultProps = {
        direction: 'left',
        mix: {}
    };

    render() {
        const { direction, mix } = this.props;

        return (
            <svg className="ChevronIcon" block="ChevronIcon" elem={ direction } mix={ mix } width="21" height="40" viewBox="0 0 21 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.405718 38.5005C0.2197 38.6865 0.2197 38.9881 0.405718 39.1741C0.591736 39.3601 0.893332 39.3601 1.07933 39.1741L20.1267 20.1267C20.3127 19.9407 20.3127 19.6391 20.1267 19.4531L1.07933 0.405749C0.893315 0.219731 0.591719 0.219731 0.405718 0.405749C0.219717 0.591767 0.2197 0.89336 0.405718 1.07936L19.1231 19.79L0.405718 38.5005Z" fill="black" />
            </svg>

        );
    }
}

export default ChevronIconComponent;

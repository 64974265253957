import { CLOSE_MENU, OPEN_MENU } from './MobileMenu.action';

/** @namespace MobileMenu/Store/MobileMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    menuOpen: false
});

/** @namespace MobileMenu/Store/MobileMenu/Reducer/MobileMenuReducer */
export const MobileMenuReducer = (state = getInitialState(), { type }) => {
    switch (type) {
    case OPEN_MENU:
        return {
            ...state,
            menuOpen: true
        };
    case CLOSE_MENU:
        return {
            ...state,
            menuOpen: false
        };
    default:
        return state;
    }
};

export default MobileMenuReducer;

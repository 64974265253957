/* eslint-disable no-magic-numbers */
import { PureComponent } from 'react';

import ProductCard from 'Component/ProductCard';

import './CustomGridLoading.style';

/** @namespace PlpCustomGrid/Component/CustomGridLoading/Component/CustomGridLoadingComponent */
export class CustomGridLoadingComponent extends PureComponent {
    render() {
        return (
            <div block="CustomGridLoading">
                { /* TODO: render 9 product cards as placeholders */ }
                { Array.from(
                    { length: 9 },
                    (_, i) => (
                        <ProductCard
                          key={ i }
                          product={ {} }
                        />
                    )
                ) }
            </div>
        );
    }
}

export default CustomGridLoadingComponent;

import { PRICE_HIGH, PRICE_LOW } from "../component/CategorySort/CategorySort.config";
import { isBundleProductOutOfStock } from 'Util/helpers';

/** @namespace PlpCustomGrid/Util/Index/sortByStock */
export const sortByStock = (items, excludeOss = false) => {
    if (!items || items.length < 1) {
        return [];
    }

    const outStockItems = [];
    const inStockItems = items.reduce(
        (acc = [], curr) => {
            if (!curr) {
                return acc;
            }

            let isBundleOos = false;
            if (curr.type_id === 'bundle') {
                isBundleOos = isBundleProductOutOfStock(curr);
                // console.log('sortByStock', isBundleOos, curr.name);
            }

            if (
                curr.type_id === 'configurable'
                || (curr.type_id === 'simple' && curr.stock_status === 'IN_STOCK' && parseInt(curr.salable_qty, 10) > 5)
                || (curr.type_id === 'bundle' && !isBundleOos)
            ) {
                return [...acc, curr];
            }

            outStockItems.push(curr);
            
            return acc;
        }, []
    );

    if(excludeOss) {
        return inStockItems;
    }

    const newMergedItems = [...inStockItems, ...outStockItems];

    return newMergedItems;
};

/** @namespace PlpCustomGrid/Util/Index/sortByPrice */
export const sortByPrice = (items = [], direction = PRICE_LOW) => {
    if (!items.length) {
        return [];
    }
    if (direction === PRICE_LOW) {
        return items.sort((a, b) => {
            const { price_range: { minimum_price: { final_price: { value: aValue = 0 } = {} } = {} } = {} } = a;
            const { price_range: { minimum_price: { final_price: { value: bValue = 0 } = {} } = {} } = {} } = b;
            return aValue - bValue;
        });
    } else if (direction === PRICE_HIGH) {
        return items.sort((a, b) => {
            const { price_range: { minimum_price: { final_price: { value: aValue = 0 } = {} } = {} } = {} } = a;
            const { price_range: { minimum_price: { final_price: { value: bValue = 0 } = {} } = {} } = {} } = b;
            return bValue - aValue;
        });
    } else {
        return items
    }
}

/** @namespace PlpCustomGrid/Util/Index/sortByDiscount */
export const sortByDiscount = (items = []) => items.sort((a, b) => {
    const { price_range: { minimum_price: { discount: { percent_off: aDiscount = 0 } = {} } = {} } = {} } = a;
    const { price_range: { minimum_price: { discount: { percent_off: bDiscount = 0 } = {} } = {} } = {} } = b;
    return bDiscount - aDiscount;
});

import { lazy } from 'react';

import { INDGLOBAL_PRODUCT_SLIDER } from '../ProductCarousel.config';

export const ProductCarousel = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "indglobalproductcarousel" */ '../component/ProductCarousel')
);

const renderMap = (originalMember) => ({
    ...originalMember,
    [INDGLOBAL_PRODUCT_SLIDER]: {
        component: ProductCarousel
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        }
    }
};

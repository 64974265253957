import PropTypes from 'prop-types';

import { MENU } from 'Component/Header/Header.config';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import { closeMenu, openMenu } from '../store/MobileMenu/MobileMenu.action';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        menuOpen: state.MobileMenuReducer.menuOpen
    };
};

const mapDispatchToProps = (args, callback, _instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
        closeMenu: () => dispatch(closeMenu()),
        openMenu: () => dispatch(openMenu())
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    menuOpen: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    openMenu: PropTypes.func.isRequired,
    goToPreviousNavigationState: PropTypes.func.isRequired
});

export class HeaderContainerPlugin {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        openMenu: this.openMenu.bind(instance),
        closeMenu: this.closeMenu.bind(instance)
    });

    openMenu() {
        const { setNavigationState, openMenu } = this.props;

        setNavigationState({
            name: MENU
        });
        openMenu();
    }

    closeMenu(e) {
        const {
            navigationState: { onBackClick },
            closeMenu,
            goToPreviousNavigationState
        } = this.props;

        if (onBackClick) {
            onBackClick(e);
        }

        goToPreviousNavigationState();

        this.setState({ searchCriteria: '' });
        closeMenu();
    }
}

const containerProps = (args, callback, instance) => {
    const { menuOpen } = instance.props;

    return {
        ...callback(...args),
        menuOpen
    };
};

const { containerFunctions } = new HeaderContainerPlugin();

export default {
    'Component/Header/Container': {
        'static-member': {
            propTypes
        },
        'member-property': {
            containerFunctions
        },
        'member-function': {
            containerProps
        }
    },
    'Component/Header/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

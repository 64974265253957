/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import { POSTPAY_ONLINE, POSTPAY_PAY_LATER } from '../component/Postpay/Postpay.config';
import { PostpayContainer } from '../component/Postpay/Postpay.container';
import { PostpayPaylaterContainer } from '../component/PostpayPaylater/PostpayPaylater.container';

/** @namespace Postpay/Plugin/CheckoutPaymentsContainer/getPostpayData */
export function getPostpayData() {
    return {
        asyncData: PostpayContainer.getRef()
    };
}

/** @namespace Postpay/Plugin/CheckoutPaymentsContainer/getPostpayData */
export function getPostpayPayLaterData() {
    return {
        asyncData: PostpayPaylaterContainer.getRef()
    };
}

/** @namespace Postpay/Plugin/CheckoutPaymentsContainer/dataMap */
export const dataMap = (originalMember, instance) => ({
    ...originalMember,
    [POSTPAY_ONLINE]: getPostpayData.bind(instance),
    [POSTPAY_PAY_LATER]: getPostpayPayLaterData.bind(instance)
});

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap
        }
    }
};

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { ChildrenType, MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSlider.style';

/** @namespace Lafz/Component/CustomSlider1/Component/SampleNextArrow */
export const SampleNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { className, style, onClick } = props;
    return (
        <button
          className={ className }
          style={ { ...style } }
          onClick={ onClick }
        >
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11L1 21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
    );
};

/** @namespace Lafz/Component/CustomSlider1/Component/SamplePrevArrow */
export const SamplePrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { className, style, onClick } = props;
    return (
      <button
        className={ className }
        style={ { ...style } }
        onClick={ onClick }
      >
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L11 11L1 21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    );
};

/** @namespace Lafz/Component/CustomSlider1/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Lafz/Component/CustomSlider1/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Lafz/Component/CustomSlider1/Component/CustomSlider1Component */
export class CustomSlider1Component extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        dots: PropTypes.bool,
        arrows: PropTypes.bool,
        slidesToScroll: PropTypes.number,
        slidesToScrollMobile: PropTypes.number,
        slidesToShow: PropTypes.number,
        slidesToShowMobile: PropTypes.number,
        mix: MixType,
        infinite: PropTypes.bool,
        speed: PropTypes.number,
        autoplay: PropTypes.bool,
        centerMode: PropTypes.bool,
        centerPadding: PropTypes.string,
        device: DeviceType.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        sliderRef: PropTypes.any,
        pauseOnFocus: PropTypes.bool,
        dotsMobile: PropTypes.bool,
        arrowsMobile: PropTypes.bool,
        autoplayMobile: PropTypes.bool
    };

    static defaultProps = {
        dots: true,
        dotsMobile: true,
        arrows: true,
        arrowsMobile: true,
        slidesToScroll: 1,
        slidesToScrollMobile: 1,
        slidesToShow: 1,
        slidesToShowMobile: 1,
        mix: {},
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplayMobile: true,
        centerMode: false,
        centerPadding: '50px',
        sliderRef: null,
        pauseOnFocus: true
    };

    sliderRef = createRef();

    getSliderRef() {
        const { sliderRef } = this.props;

        return sliderRef || this.sliderRef;
    }

    render() {
        const {
            children,
            dots,
            dotsMobile,
            arrows,
            arrowsMobile,
            slidesToScroll,
            slidesToScrollMobile,
            slidesToShow,
            slidesToShowMobile,
            infinite,
            speed,
            centerMode,
            autoplay,
            autoplayMobile,
            centerPadding,
            device,
            // eslint-disable-next-line no-unused-vars
            sliderRef,
            pauseOnFocus,
            ...rest
        } = this.props;

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const sliderProps = {
            dots: device.isMobile ? dotsMobile : dots,
            arrows: device.isMobile ? arrowsMobile : arrows,
            infinite,
            speed,
            slidesToShow: device.isMobile ? slidesToShowMobile : slidesToShow,
            slidesToScroll: device.isMobile ? slidesToScrollMobile : slidesToScroll,
            centerMode,
            autoplay: device.isMobile ? autoplayMobile : autoplay,
            centerPadding,
            pauseOnFocus,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        return (
            <div block="CustomSlider" { ...rest }>
                <Slider { ...sliderProps } ref={ this.getSliderRef() }>
                    { children }
                </Slider>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSlider1Component);

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-nested-ternary */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';
import {
    DISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK
} from 'Route/CategoryPage/CategoryPage.config';
import { CategoryTreeType } from 'Type/Category';

import './PlpFooter.style';

/** @namespace Lafz/Component/PlpFooter/Component/PlpFooterComponent */
export class PlpFooterComponent extends Component {
    static propTypes = {
        isPLP: PropTypes.bool.isRequired,
        isHome: PropTypes.bool.isRequired,
        category: CategoryTreeType.isRequired,
        handleReadMore: PropTypes.func.isRequired,
        readMore: PropTypes.bool.isRequired
    };

    displayCmsBlock() {
        const { category: { display_mode } = {} } = this.props;
        return display_mode === DISPLAY_MODE_CMS_BLOCK
             || display_mode === DISPLAY_MODE_BOTH;
    }

    renderCmsBottomBlock() {
        const { category: { cms_block_bottom } } = this.props;

        if (!cms_block_bottom || !this.displayCmsBlock()) {
            return null;
        }

        const { content, disabled } = cms_block_bottom;

        if (disabled) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="CMS"
            >
                <Html content={ content } />
            </div>
        );
    }

    renderHomeBottomBlock() {
        return <CmsBlock identifier="homepage-bottom-block" />;
    }

    render() {
        const {
            isPLP,
            isHome,
            handleReadMore,
            readMore
        } = this.props;
        const { category: { cms_block_bottom } } = this.props;

        if (!cms_block_bottom || !this.displayCmsBlock()) {
            return null;
        }

        const { content, disabled } = cms_block_bottom;

        // eslint-disable-next-line no-magic-numbers
        if (disabled) {
            return null;
        }

        if (!(isPLP || isHome)) {
            return null;
        }

        const readMoreText = readMore ? 'Read Less' : 'Read More';

        return (
            <Suspense fallback={ null }>
                { content.indexOf('CategoryPage-CMSs') > -1 && (
                <div block="PlpFooter">
                    <div className={ `PlpFooterContentWrapper ${readMore ? 'active' : null }` }>
                        { isPLP ? this.renderCmsBottomBlock() : isHome ? this.renderHomeBottomBlock() : null }
                    </div>
                    <div
                      key="ReadMoreButton"
                      block="ReadMore"
                      onClick={ handleReadMore }
                    >
                        { readMoreText }
                    </div>
                </div>
                ) }
            </Suspense>
        );
    }
}

export default PlpFooterComponent;

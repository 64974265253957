// TODO update this import when renamed
import {
    SHIPROCKET_DISABLE_MODULE,
    SHIPROCKET_ENABLE_MODULE
} from './Shiprocket.action';

/** @namespace Pincodecheck/Store/Shiprocket/Reducer/getInitialState */
export const getInitialState = () => ({
    enabled: false
});

/** @namespace Pincodecheck/Store/Shiprocket/Reducer/ShiprocketReducer */
export const ShiprocketReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SHIPROCKET_ENABLE_MODULE:
        return {
            ...state,
            enabled: true
        };
    case SHIPROCKET_DISABLE_MODULE:
        return {
            ...state,
            enabled: false
        };
    default:
        return state;
    }
};

export default ShiprocketReducer;

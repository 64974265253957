/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';

import Bubble from './Bubble.component';

import './FoodPackets.style.scss';

export default function FoodPackets() {
    const [likes, setLikes] = useState([]);
    const [packets, setPackets] = useState(3000);
    const cleanLike = useRef((id) => {
        setLikes((currentLikes) => currentLikes.filter((like) => like !== id));
    });

    useEffect(() => {
        const startCounter = () => {
            setLikes((prevLikes) => [...prevLikes, new Date().getTime()]);
            setPackets((prevPackets) => prevPackets + 1);
        };
        const interval = setInterval(() => {
            startCounter();
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    return (
      <div block="FoodPackets">
        <div
          block="FoodPackets"
          elem="ThankyouSection"
        >
          { likes.map((id) => (
            <Bubble onAnimationEnd={ cleanLike.current } key={ id } id={ id } />
          )) }
        </div>
        <div
          block="FoodPackets"
          elem="CounterSection"
        >
            <div block="FoodPackets" elem="FloatingCountWrapper">
                <div block="FoodPackets" elem="FloatingCount">
                    { packets - 3 }
                </div>
                <div block="FoodPackets" elem="FloatingCount">
                    { packets - 2 }
                </div>
                <div block="FoodPackets" elem="FloatingCount">
                    { packets - 1 }
                </div>
            </div>
            <div
              block="FoodPackets"
              elem="CounterCount"
            >
                { packets }
            </div>
            <div
              block="FoodPackets"
              elem="CounterHeading"
            >
                Food Packets delivered so far
            </div>
        </div>
      </div>
    );
}

/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

// import { createPortal } from 'react-dom';
// import Html from 'Component/Html';

/**
 * Page Meta data
 * @class Meta
 * @namespace Component/Meta/Component
 */
export class BeforeBody extends PureComponent {
    static propTypes = {
        absolute_footer: PropTypes.string,
        head_after_body: PropTypes.string,
        head_includes: PropTypes.string
    };

    static defaultProps = {
        absolute_footer: '',
        head_after_body: '',
        head_includes: ''
    };

    componentDidMount() {
        this.renderHeadBefore();
        this.renderAfterBody();
        this.renderBeforeBody();
    }

    setInnerHTML(parent, position = 'beforeend', html) {
        const elm = document.createElement('div');
        elm.innerHTML = html;

        Array.from(elm.querySelectorAll('script')).forEach((oldScript) => {
            const newScript = document.createElement('script');
            Array.from(oldScript.attributes)
                .forEach((attr) => newScript.setAttribute(attr.name, attr.value));
            newScript.appendChild(document.createTextNode(oldScript.innerHTML));
            oldScript.parentNode.replaceChild(newScript, oldScript);
            oldScript.remove();
            if (position === 'beforeend') {
                parent.appendChild(newScript);
            } else {
                parent.insertBefore(newScript, parent.firstChild);
            }
        });
        Array.from(elm.querySelectorAll('noscript')).forEach((oldScript) => {
            const newNoScript = document.createElement('noscript');
            Array.from(oldScript.attributes)
                .forEach((attr) => newNoScript.setAttribute(attr.name, attr.value));
            newNoScript.appendChild(document.createTextNode(oldScript.innerHTML));
            oldScript.parentNode.replaceChild(newNoScript, oldScript);
            oldScript.remove();
            if (position === 'beforeend') {
                parent.appendChild(newNoScript);
            } else {
                parent.insertBefore(newNoScript, parent.firstChild);
            }
        });
        if (elm.hasChildNodes) {
            if (position === 'beforeend') {
                parent.appendChild(elm);
            } else {
                parent.insertBefore(elm, parent.firstChild);
            }
        }
    }

    renderHeadBefore() {
        const { head_includes } = this.props;
        this.setInnerHTML(document.head, 'beforeend', head_includes);
    }

    renderAfterBody() {
        const { head_after_body } = this.props;
        this.setInnerHTML(document.body, 'afterbegin', head_after_body);
    }

    renderBeforeBody() {
        const { absolute_footer } = this.props;
        this.setInnerHTML(document.body, 'beforeend', absolute_footer);
    }

    render() {
        return (
            <div id="beforeBody" block="beforeBody" />
        );
    }
}

export default BeforeBody;

import {
    ADD_PRODUCT_TO_CART as SOURCE_ADD_PRODUCT_TO_CART,
    addProductToCart as sourceAddProductToCart,
    APPLY_COUPON_TO_CART as SOURCE_APPLY_COUPON_TO_CART,
    applyCouponToCart as sourceApplyCouponToCart,
    REMOVE_COUPON_FROM_CART as SOURCE_REMOVE_COUPON_FROM_CART,
    REMOVE_PRODUCT_FROM_CART as SOURCE_REMOVE_PRODUCT_FROM_CART,
    removeCouponFromCart as sourceRemoveCouponFromCart,
    removeProductFromCart as sourceRemoveProductFromCart,
    UPDATE_SHIPPING_PRICE as SOURCE_UPDATE_SHIPPING_PRICE,
    UPDATE_TOTALS as SOURCE_UPDATE_TOTALS,
    updateShippingPrice as sourceUpdateShippingPrice,
    updateTotals as sourceUpdateTotals
} from 'SourceStore/Cart/Cart.action';

// TODO: implement ADD_PRODUCT_TO_CART
export const ADD_PRODUCT_TO_CART = SOURCE_ADD_PRODUCT_TO_CART;

// TODO: implement REMOVE_PRODUCT_FROM_CART
export const REMOVE_PRODUCT_FROM_CART = SOURCE_REMOVE_PRODUCT_FROM_CART;

// TODO: implement UPDATE_TOTALS
export const UPDATE_TOTALS = SOURCE_UPDATE_TOTALS;

// TODO: implement APPLY_COUPON_TO_CART
export const APPLY_COUPON_TO_CART = SOURCE_APPLY_COUPON_TO_CART;

// TODO: implement REMOVE_COUPON_FROM_CART
export const REMOVE_COUPON_FROM_CART = SOURCE_REMOVE_COUPON_FROM_CART;

// TODO: implement UPDATE_SHIPPING_PRICE
export const UPDATE_SHIPPING_PRICE = SOURCE_UPDATE_SHIPPING_PRICE;

// TODO: implement addProductToCart
export const addProductToCart = sourceAddProductToCart;

// TODO: implement removeProductFromCart
export const removeProductFromCart = sourceRemoveProductFromCart;

// TODO: implement updateTotals
export const updateTotals = sourceUpdateTotals;

// TODO: implement updateShippingPrice
export const updateShippingPrice = sourceUpdateShippingPrice;

// TODO: implement applyCouponToCart
export const applyCouponToCart = sourceApplyCouponToCart;

// TODO: implement removeCouponFromCart
export const removeCouponFromCart = sourceRemoveCouponFromCart;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_LOADING,
    UPDATE_REDIRECT,
    UPDATE_SSLCOMMERZE_DATA
} from './Sslcommerze.action';

export const initialState = {
    isRedirect: false,
    SslcommerzeData: {},
    isLoading: false
};

/** @namespace Sslcommerze/Store/Sslcommerze/Reducer/SslcommerzeReducer */
export const SslcommerzeReducer = (state = initialState, action) => {
    const { SslcommerzeData, isRedirect, isLoading } = action;

    switch (action.type) {
    case UPDATE_REDIRECT:
        return {
            ...state,
            isRedirect
        };
    case UPDATE_SSLCOMMERZE_DATA:
        return {
            ...state,
            SslcommerzeData
        };
    case UPDATE_LOADING:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default SslcommerzeReducer;

import { UPDATE_RECOMMENDED_PRODUCTS } from './ProductRecommendation.action';

/** @namespace SkinAnalyse/Store/ProductRecommendation/Reducer/getInitialState */
export const getInitialState = () => ({
    products: []
});

/** @namespace SkinAnalyse/Store/ProductRecommendation/Reducer/ProductRecommendationReducer */
export const ProductRecommendationReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_RECOMMENDED_PRODUCTS:
        const { payload } = action;

        return {
            ...state,
            products: payload
        };

    default:
        return state;
    }
};

export default ProductRecommendationReducer;

import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';

const propTypes = (originalMember) => ({
    ...originalMember,
    isSkinAnalyserProduct: PropTypes.bool
});

const renderAddToCart = (args, callback, instance) => {
    const content = callback.apply(instance, args);
    const {
        product,
        isRewardProduct,
        isSkinAnalyserProduct
    } = instance.props;

    const { type_id } = product;

    const productOptionsData = {};
    const groupedProductQuantity = {};

    if (!type_id || type_id !== 'simple') {
        return content;
    }

    return (
        <AddToCart
          product={ product }
          mix={ { block: 'ProductCard', elem: 'AddToCart' } }
          quantity={ 1 }
          groupedProductQuantity={ groupedProductQuantity }
          productOptionsData={ productOptionsData }
          isRewardProduct={ isRewardProduct }
          isSkinAnalyserProduct={ isSkinAnalyserProduct }
        />
    );
};

export default {
    'Component/ProductCard/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            renderAddToCart
        }
    }
};

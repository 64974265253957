import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

export const AutoAddProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/AutoAddProducts/AutoAddProducts.dispatcher'
);

/** @namespace Lafz/Component/LoadAutoAddProducts/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Lafz/Component/LoadAutoAddProducts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    loadProducts: () => AutoAddProductsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch)
    )
});

/** @namespace Lafz/Component/LoadAutoAddProducts/Container/LoadAutoAddProductsContainer */
export class LoadAutoAddProductsContainer extends PureComponent {
    static propTypes = {
        loadProducts: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.loadAutoAddProducts();
    }

    loadAutoAddProducts() {
        const { loadProducts } = this.props;
        loadProducts();
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadAutoAddProductsContainer);

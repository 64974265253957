/* eslint-disable no-magic-numbers */
import { isBundleProductOutOfStock } from 'Util/helpers';

import ProductAlert from '../component/ProductAlert';

const renderProductAlert = (args, callback, instance) => {
    const { productOrVariant, productOptionsData } = instance.props;

    if (!productOrVariant) {
        return callback.apply(instance, args);
    }

    const {
        attributes: {
            preorder: {
                attribute_value = '0'
            } = {}
        } = {},
        type_id,
        stock_status,
        salable_qty = 0,
        price_range: {
            maximum_price: {
                default_final_price: {
                    currency = 'AED'
                } = {}
            } = {}
        } = {}
    } = productOrVariant;

    const qty = parseInt(salable_qty, 10);

    if (!type_id) {
        return callback.apply(instance, args);
    }

    // eslint-disable-next-line fp/no-let
    let isInStock = true;

    // eslint-disable-next-line no-magic-numbers
    isInStock = !(currency === 'KWD' && (qty + 5) <= 10 && type_id === 'simple');

    if (qty <= 5 && type_id === 'simple') {
        isInStock = false;
    }

    // eslint-disable-next-line fp/no-let
    let isBundleOos = false;

    if (type_id === 'bundle') {
        isBundleOos = isBundleProductOutOfStock(productOrVariant, productOptionsData);
    }

    if (
        attribute_value === '1'
        || stock_status === 'OUT_OF_STOCK'
        || isBundleOos
        || !isInStock
    ) {
        return <ProductAlert product={ productOrVariant } productOptionsData={ productOptionsData } />;
    }

    return callback.apply(instance, args);
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderAddToCartWrapper: renderProductAlert
        }
    }
};

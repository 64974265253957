/** @namespace Lafz/Util/Script/Index/setAttributes */
export const setAttributes = (element, attributes) => {
    Object.keys(attributes).forEach((attr) => {
        element.setAttribute(attr, attributes[attr]);
    });
};

/** @namespace Lafz/Util/Script/Index/injectScriptInFooter */
export const injectScriptInFooter = ({ content = '', src = null, ...attributes } = {}) => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = content;
    if (src !== null) {
        scriptElement.src = src;
    }
    setAttributes(scriptElement, attributes);
    document.body.appendChild(scriptElement);
};

/** @namespace Lafz/Util/Script/Index/injectScriptInHead */
export const injectScriptInHead = ({ content = '', src = null, ...attributes } = {}) => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = content;
    if (src !== null) {
        scriptElement.src = src;
    }
    setAttributes(scriptElement, attributes);
    document.head.appendChild(scriptElement);
};

import BrowserDatabase from 'Util/BrowserDatabase';

import { GTM_SKIN_ANALYSER, triggerEvent } from '../../Gtm.config';

export const SKIN_ANALYSER_LINKS = {
    Acne: '/shop-by-concern/acne.html',
    Dullness: '/shop-by-concern/dullness.html',
    Dryness: '/shop-by-concern/dryness.html',
    'Ageing skin': 'shop-by-concern/ageing-skin.html'
};

const saveInformation = (args, callback, instance) => {
    const {
        formData
    } = instance.state;

    const { country: store_code } = instance.props;

    BrowserDatabase.setItem({
        ...formData,
        store_code
    }, 'CLEVERTAP_SKIN_ANALYSER');

    return callback.apply(instance, args);
};

const afterSkinAnalyserDataSubmit = (args, callback, instance) => {
    const clevertapData = BrowserDatabase.getItem('CLEVERTAP_SKIN_ANALYSER');

    const [{ skinAnalysis }] = args;

    const { storeConfig: { base_link_url = '' } = {} } = BrowserDatabase.getItem('config') || {};

    const url = new URL(`/skin-analyse/products-recommended/${skinAnalysis.transaction_id}`, base_link_url);

    clevertapData.recommendations = url.href;

    triggerEvent({
        name: GTM_SKIN_ANALYSER,
        clevertapData
    });

    BrowserDatabase.deleteItem('CLEVERTAP_SKIN_ANALYSER');

    return callback.apply(instance, args);
};

export default {
    'SkinAnalyse/Route/SkinAnalyser/Container/SkinAnalyserContainer': {
        'member-function': {
            saveInformation
        }
    },
    'SkinAnalyse/Route/SkinAnalyser/Container/fetchMutation/submitSuccess': {
        function: afterSkinAnalyserDataSubmit
    }
};

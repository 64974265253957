/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Lafz/Component/Meta/Component/MetaComponent */
export class MetaComponent extends SourceMeta {
    static propTypes = {
        base_url: PropTypes.string.isRequired,
        country: PropTypes.string
    };

    static defaultProps = {
        base_url: '',
        country: 'India'
    };

    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        const titleTag = document.querySelector('title');
        const titleData = `${ titlePrefix }${ title || default_title }${ titleSuffix }`;

        if (!titleTag) {
            const t = document.createElement('title');
            t.innerText = titleData;
            document.head.prepend(t);
        } else {
            titleTag.innerText = titleData;
        }

        return null;
    }

    renderCanonical() {
        // eslint-disable-next-line fp/no-let
        let canonical_url = window.location.href.split('?')[0];
        const homeRoutes = ['/', '/in/', '/ae/', '/qa/', '/sa/', '/bh/', '/bd/', '/pk/', '/kw/', '/ng/'];
        if (!canonical_url) {
            return null;
        }

        if (homeRoutes.find((route) => route === window.location.pathname)) {
            canonical_url = 'https://www.zaynmyza.com/';
        }

        const canonicalTag = document.querySelector('link[rel="canonical"]');

        if (canonicalTag) {
            canonicalTag.setAttribute('rel', 'canonical');
            canonicalTag.setAttribute('href', canonical_url);
        } else {
            const c = document.createElement('link');
            c.setAttribute('rel', 'canonical');
            c.setAttribute('href', canonical_url);
            document.head.prepend(c);
        }

        return null;
    }

    renderHrefLangTag() {
        // eslint-disable-next-line no-var
        var hrefLang = '';
        // eslint-disable-next-line no-unreachable
        const { base_url } = this.props;
        const { pathname } = window.location;
        const stores = ['/bh', 'kw', '/om', '/qa', '/sa', '/ae', '/in', '/bd', '/pk'];
        if (new RegExp(stores.join('|')).test(pathname)) {
            // eslint-disable-next-line no-magic-numbers
            hrefLang = pathname.slice(4);
        } else {
            // eslint-disable-next-line no-magic-numbers
            hrefLang = pathname.slice(1);
        }

        const canonical_url = `${base_url}${hrefLang}`;

        if (!canonical_url) {
            return null;
        }

        const hrefTag = document.querySelector('link[rel="alternate"]');

        if (hrefTag) {
            hrefTag.setAttribute('rel', 'alternate');
            hrefTag.setAttribute('href', canonical_url);
            hrefTag.setAttribute('hrefLang', 'x-default');
        } else {
            const t = document.createElement('link');
            t.setAttribute('rel', 'alternate');
            t.setAttribute('href', canonical_url);
            t.setAttribute('hrefLang', 'x-default');
        }

        return null;
    }

    renderMetaData(tag) {
        const metaTag = document.querySelector(`meta[name="${tag.name}"]`);
        if (metaTag) {
            Object.keys(tag).forEach((key) => {
                metaTag.setAttribute(key, tag[key]);
            });
        } else {
            const m = document.createElement('meta');
            Object.keys(tag).forEach((key) => {
                m.setAttribute(key, tag[key]);
            });
            document.head.prepend(m);
        }

        return null;
    }

    renderMeta() {
        const { metadata } = this.props;
        return (
            <>
                { this.renderTitle() }
                { metadata.map(this.renderMetaData) }
                { this.renderHrefLangTag() }
                { this.renderCanonical() }
            </>
        );
    }

    render() {
        return this.renderMeta();
    }
}

export default MetaComponent;

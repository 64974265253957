/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PAYMENT_TOTALS, SUCCESS_DATA } from 'Route/Checkout/Checkout.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

import SslcommerzeQuery from '../../query/Sslcommerze.query';
import SslcommerzeComponent from './Sslcommerze.component';

/** @namespace Sslcommerze/Component/Sslcommerze/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isRedirect: state.SslcommerzeReducer.isRedirect,
    isLoading: state.SslcommerzeReducer.isLoading,
    SslcommerzeData: state.SslcommerzeReducer.SslcommerzeData
});

/** @namespace Sslcommerze/Component/Sslcommerze/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export const SslcommerzeDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Sslcommerze/Sslcommerze.dispatcher'
);

/** @namespace Sslcommerze/Component/Sslcommerze/Container/SslcommerzeContainer */
export class SslcommerzeContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        isRedirect: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        SslcommerzeData: PropTypes.object.isRequired
    };

    static defaultProps = {
        isLoading: false
    };

    static getRef() {
        const store = getStore();
        const { dispatch } = store;
        return new Promise((resolve, reject) => {
            SslcommerzeDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateIsLoading(dispatch, true)
            );
            const guest_cart_id = getGuestQuoteId();

            fetchMutation(
                SslcommerzeQuery.getCreateSslcommerzePaymentOrderMutation(
                    !isSignedIn() ? { guest_cart_id } : {}
                )
            ).then(
                /** @namespace Sslcommerze/Component/Sslcommerze/Container/fetchMutation/then */
                (response) => {
                    const { sslCommerzeOrder: { success, url, order_id } } = response || {};

                    if (!success) {
                        reject(__('Something went wrong'));
                    }

                    const { grand_total, items, quote_currency_code } = BrowserDatabase.getItem(PAYMENT_TOTALS);
                    const paymentMethodTitle = __('Paid with Card');
                    const successData = {
                        grand_total,
                        items,
                        currency: quote_currency_code,
                        paymentMethodTitle
                    };

                    BrowserDatabase.setItem({ orderID: order_id, successData }, SUCCESS_DATA);

                    SslcommerzeDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateSslcommerzeData(dispatch, { url })
                    );
                    SslcommerzeDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateRedirect(dispatch, true)
                    );
                },
                /** @namespace Sslcommerze/Component/Sslcommerze/Container/fetchMutation/then */
                // eslint-disable-next-line no-alert
                () => alert(__('Something went wrong'))
            );

            reject();
        });
    }

    state = {
        isLoading: false,
        SslcommerzeStatus: ''
    };

    componentDidUpdate(prevProps) {
        const { isRedirect, isLoading } = this.props;

        if (prevProps.isRedirect !== isRedirect) {
            if (isRedirect) {
                this.redirect();
            }
        }

        if (prevProps.isLoading !== isLoading) {
            if (isLoading) {
                this.setProgress(true, __('Pleasewait...'), false);
            }
        }
    }

    __construct(props) {
        super.__construct(props);
    }

    redirect() {
        const { SslcommerzeData: { url } } = this.props;

        if (url !== '') {
            try {
                this.setProgress(true, __('Redirecting you to payment page...'), false);

                setTimeout(() => {
                    window.location.href = url;
                // eslint-disable-next-line no-magic-numbers
                }, 3000);
            } catch (e) {
                showNotification('error', __('Something went wrong'));
            }
        }
    }

    setProgress(loading, status, btnStatus) {
        this.setState({ isLoading: loading, SslcommerzeStatus: status });
        const { setOrderButtonEnableStatus } = this.props;
        setOrderButtonEnableStatus(btnStatus);
    }

    render() {
        return (
            <SslcommerzeComponent
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SslcommerzeContainer);

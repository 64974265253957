/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

import { SSLCOMMERZE } from '../component/Sslcommerze/Sslcommerze.config';

/** @namespace Sslcommerze/Plugin/CheckoutBillingContainer/getPaymentData */
export const _getPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;

    if (code === SSLCOMMERZE) {
        return false;
    }

    return callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData
        }
    }
};

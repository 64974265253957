import {
    MAX_NUMBER_OF_RECENT_PRODUCTS as SOURCE_MAX_NUMBER_OF_RECENT_PRODUCTS,
    RECENTLY_VIEWED_PRODUCTS as SOURCE_RECENTLY_VIEWED_PRODUCTS
} from 'SourceComponent/RecentlyViewedWidget/RecentlyViewedWidget.config';

// TODO: implement RECENTLY_VIEWED_PRODUCTS
export const RECENTLY_VIEWED_PRODUCTS = SOURCE_RECENTLY_VIEWED_PRODUCTS;

// TODO: implement MAX_NUMBER_OF_RECENT_PRODUCTS
export const MAX_NUMBER_OF_RECENT_PRODUCTS = SOURCE_MAX_NUMBER_OF_RECENT_PRODUCTS;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_REDIRECT = 'UPDATE_REDIRECT';
export const UPDATE_SSLCOMMERZE_DATA = 'UPDATE_SSLCOMMERZE_DATA';
export const UPDATE_LOADING = 'UPDATE_LOADING';

/** @namespace Sslcommerze/Store/Sslcommerze/Action/updateRedirect */
export const updateRedirect = (isRedirect) => ({
    type: UPDATE_REDIRECT,
    isRedirect
});
/** @namespace Sslcommerze/Store/Sslcommerze/Action/updateSslcommerzeData */
export const updateSslcommerzeData = (SslcommerzeData) => ({
    type: UPDATE_SSLCOMMERZE_DATA,
    SslcommerzeData
});
/** @namespace Sslcommerze/Store/Sslcommerze/Action/updateIsLoading */
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_LOADING,
    isLoading
});

import OfferProduct from '../component/OfferProduct';

const render = (args, callback, instance) => (
    <>
        { callback.apply(instance, args) }
        <OfferProduct />
    </>
);

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render
        }
    }
};

/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
// import { DeviceType } from 'Type/Device';
import media from 'Util/Media';

/** @namespace Component/MenuItem/Component */
export class MenuItem extends PureComponent {
    static propTypes = {
        activeMenuItemsStack: PropTypes.array.isRequired,
        item: PropTypes.object.isRequired,
        itemMods: PropTypes.object,
        handleCategoryHover: PropTypes.func.isRequired,
        isLink: PropTypes.bool,
        onItemClick: PropTypes.func
        // device: DeviceType.isRequired
        // closeMenu: PropTypes.func.isRequired
    };

    static defaultProps = {
        itemMods: {},
        isLink: false,
        onItemClick: () => {}
    };

    renderItemContentImage(icon, itemMods) {
        // const { device } = this.props;
        // const { isBanner, isLogo, type } = itemMods;

        if (!icon) {
            return null;
        }

        return (
            <div mix={ { block: 'Menu', elem: 'ImageWrapper' } }>
                <Image
                  mix={ { block: 'Menu', elem: 'Image', mods: itemMods } }
                  src={ icon && media(icon) }
                  ratio="custom"
                />
            </div>
        );
    }

    renderItemContentTitle(isBanner, title) {
        if (isBanner) {
            return (
                <button
                  block="Menu"
                  elem="Button"
                  mix={ { block: 'Button' } }
                >
                    { title }
                </button>
            );
        }

        return title;
    }

    renderItemContent(item, itemMods = {}) {
        const { title, icon } = item;
        const { isBanner } = itemMods;

        return (
            <figure
              block="Menu"
              elem="ItemFigure"
              mods={ itemMods }
            >
                <figcaption
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { this.renderItemContentTitle(isBanner, title) }
                </figcaption>
                { this.renderItemContentImage(icon, itemMods) }
            </figure>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            onItemClick
            // closeMenu
        } = this.props;

        const {
            url,
            item_id,
            children
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);
        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              id={ item_id }
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered, isChildren: !!Object.keys(children).length } }
              onClick={ onItemClick }
              // onMouseLeave={ closeMenu }
            >
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }

    render() {
        const { item, itemMods, isLink } = this.props;

        if (isLink) {
            return this.renderItemLinkContent();
        }

        return this.renderItemContent(item, itemMods);
    }
}

export default MenuItem;

/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import { Component } from 'react';

import { formatPrice } from 'Util/Price';

import './CompairProductsTable.style.scss';

/** @namespace Lafz/Component/CompairProductsTable/Component/CompairProductsTableComponent */
export class CompairProductsTableComponent extends Component {
    static propTypes = {
        products: PropTypes.arrayOf(PropTypes.object).isRequired,
        name: PropTypes.string.isRequired
    };

    render() {
        const { products, name } = this.props;
        return (
            <>
                <table className="comProductTable">
                    <tbody>
                        <tr>
                        <th className="comProductName">{ name }</th>
                        <th className="comProductPrice">Price</th>
                        <th className="comProductPrice">Last Updated</th>
                        </tr>
                        { products.map((product) => (
                            <tr>
                            <td className="comProductName"><a href={ product.url }>{ product.name }</a></td>
                            <td className="comProductPrice">
                            { formatPrice(product.price_range.minimum_price.final_price.value, product.price_range.minimum_price.final_price.currency) }
                            </td>
                            <td>{ product.updated_at?.split(' ')[0] }</td>
                            </tr>
                        )) }
                    </tbody>
                </table>
                <style>
                    { '.CategoryPage-CMSs-block{ max-width: calc(var(--content-wrapper-width) - 350px); }' }
                </style>
            </>
        );
    }
}

export default CompairProductsTableComponent;

import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';
import { formatImageName } from 'Util/helpers';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from './Image.config';

import './Image.override.style';

/** @namespace Lafz/Component/Image/Component/ImageComponent */
export class ImageComponent extends SourceImage {
    renderImage() {
        const {
            alt,
            isPlaceholder,
            src,
            style,
            title: originalTitle = null
        } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }
        // eslint-disable-next-line fp/no-let
        let title = originalTitle || alt || '';
        const splitedSrc = src.split('/');
        if (!title || title === '') {
            title = formatImageName(splitedSrc[splitedSrc.length - 1]);
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt || title }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ originalTitle || title }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                />
            );
        default:
            return null;
        }
    }
}

export default ImageComponent;

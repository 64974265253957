import { fetchMutation } from 'Util/Request';

import { INTERNAL_TRACKING_HOMEPAGE_VIEW, triggerEvent, VIEW_HOME } from '../../Gtm.config';
import GaTrackingQuery from '../query/GaTracking.query';

// eslint-disable-next-line arrow-body-style
const componentDidMount = (args, callback, _instance) => {
    fetchMutation(GaTrackingQuery.getQuery({
        page_type: INTERNAL_TRACKING_HOMEPAGE_VIEW,
        event_data: ''
    }));

    triggerEvent({
        event: VIEW_HOME,
        ecommerce: null
    });

    return callback(...args);
};

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';

/** @namespace Lafz/Query/Checkout/Query/CheckoutQuery */
export class CheckoutQuery extends SourceCheckoutQuery {
    _getTotalItemFields() {
        return [
            'qty',
            'name',
            'price',
            'item_id',
            'options',
            'tax_amount',
            'tax_percent',
            'price_incl_tax',
            'discount_amount',
            'discount_percent',
            'sku'
        ];
    }
}

export default new CheckoutQuery();

import ProductRecommendationReducer from '../store/ProductRecommendation/ProductRecommendation.reducer';

export const getStaticReducers = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    ProductRecommendationReducer
});

export default {
    'Lafz/Store/Index/getStaticReducers': {
        function: [
            {
                position: 11,
                implementation: getStaticReducers
            }
        ]
    }
};

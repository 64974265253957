export const SHIPROCKET_ENABLE_MODULE = 'SHIPROCKET_ENABLE_MODULE';
export const SHIPROCKET_DISABLE_MODULE = 'SHIPROCKET_DISABLE_MODULE';

/** @namespace Pincodecheck/Store/Shiprocket/Action/enableModule */
export const enableModule = () => ({
    type: SHIPROCKET_ENABLE_MODULE
});

/** @namespace Pincodecheck/Store/Shiprocket/Action/disableModule */
export const disableModule = () => ({
    type: SHIPROCKET_DISABLE_MODULE
});

import CategorySort from '../component/CategorySort';

const renderCategorySort = (args, callback, instance) => {
    return (
        <div
          block="CategorySortMain"
        >
            <h3 block="SortLabel">{ __('Sort By :') }</h3>
            <CategorySort />
        </div>
    );
}

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderCategorySort
        }
    }
}
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { isMobile } from 'Util/CheckDevice';

import { opacityDuration, random, users } from './FoodPacketsCounter.config';

/** @namespace Lafz/Component/FoodPacketsCounter/Bubble/Component/Bubble */
export const Bubble = ({ id, onAnimationEnd }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [opacity, setOpacity] = useState(1);
    const size = useRef(random(0.8, 1.1));
    const mobile = isMobile();

    const element = useRef();
    const user = useRef(Math.floor(random(0, users.length - 1)));

    const initialOptions = useRef({
        animationDuration: random(2, 5),
        element,
        onAnimationEnd,
        id
    });

    useEffect(() => {
        const {
            animationDuration,
            element,
            onAnimationEnd,
            id
        } = initialOptions.current;

        element.current.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'opacity') {
                onAnimationEnd(id);
            }
        });

        setTimeout(() => {
            setPosition((prevState) => ({
                ...prevState,
                x: random(-40, 40),
                y: random(-100, -200)
            }));
        }, 5);

        setTimeout(() => {
            setOpacity(0);
        }, (animationDuration - opacityDuration) * 1000);
    }, []);

    return (
      <div
        block="FoodPackets"
        elem="Bubble"
        style={ {
            opacity,
            transform: `translate(calc(${mobile ? '50%' : '-40%'} + ${position.x}px), calc(${mobile ? '90%' : '-90%' } + ${position.y}px)) scale(${size.current})`,
            transition: `transform ${initialOptions.current.animationDuration}s linear, opacity ${opacityDuration}s ease-in-out`
        } }
        ref={ element }
      >
        <div
          block="FoodPackets"
          elem="BubbleContent"
        >
            <div
              block="FoodPackets"
              elem="BubbleUserAvatar"
            />
            <div
              block="FoodPackets"
              elem="BubbleUserContent"
            >
                <div
                  block="FoodPackets"
                  elem="BubbleUserName"
                >
                    { users[user.current] }
                </div>
                <div
                  block="FoodPackets"
                  elem="BubbleUserMsg"
                >
                    Says thank you
                </div>
            </div>
        </div>
      </div>
    );
};

Bubble.propTypes = {
    id: PropTypes.string.isRequired,
    onAnimationEnd: PropTypes.func.isRequired
};

export default Bubble;

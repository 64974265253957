import {
    NewsletterSubscription as SourceNewsletterSubscription
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.override.style';

/** @namespace Lafz/Component/NewsletterSubscription/Component/NewsletterSubscriptionComponent */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {}

export default NewsletterSubscriptionComponent;

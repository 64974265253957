/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LoginStatus from 'Component/LoginStatus';
import Menu from 'Component/Menu';
import { isBeforeDesktop } from 'Util/CheckDevice';
import { menuCloseButton, menuSignInIcon } from 'Util/Icon';

import './MobileMenu.style';

const render = (args, callback, instance) => {
    const { device, menuOpen, closeMenu } = instance.props;

    if (!device.isMobile || !menuOpen) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <div block="MobileMenu">
                <div block="MainMenuWrapper">
                    <div block="TopBar">
                        { menuSignInIcon() }
                        <button
                          key="close"
                          block="CloseMenu"
                          elem="Button"
                          onClick={ closeMenu }
                          aria-label="Close"
                        >
                            { menuCloseButton() }
                        </button>
                    </div>
                    <LoginStatus />
                    <Menu />
                </div>
            </div>
        </>
    );
};

const renderMenuButton = (args, callback, instance) => {
    const { isCheckout, openMenu } = instance.props;

    const [isVisible = false] = args;

    if (isCheckout) {
        return null;
    }

    if (isBeforeDesktop()) {
        return (
            <button
              key="menu"
              block="Header"
              elem="Button"
              mods={ { type: 'menu', isVisible } }
              aria-label="Go to menu"
              onClick={ openMenu }
            />
        );
    }

    return null;
};

export default {
    'Lafz/Component/Header/Component/HeaderComponent': {
        'member-function': {
            renderMenuButton,
            render
        }
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import App from 'Component/App';

/** @namespace Lafz/Component/AppCrawler/Component/AppCrawlerComponent */
export class AppCrawlerComponent extends PureComponent {
    render() {
        const userAgent = navigator.userAgent.toLowerCase();
        const crawlers = ['googlebot', 'bingbot', 'yandexbot', 'google-inspectiontool', 'inspectiontool']; // Add more crawler user agents if needed
        const userAgentCheck = crawlers.some((crawler) => userAgent.includes(crawler));
        // eslint-disable-next-line max-len
        const googleSpeed = userAgent === 'mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/119.0.0.0 safari/537.36'.toLowerCase() || userAgent === 'mozilla/5.0 (linux; android 11; moto g power (2022)) applewebkit/537.36 (khtml, like gecko) chrome/119.0.0.0 mobile safari/537.36'.toLowerCase();

        if (userAgentCheck || googleSpeed) {
            return '';
        }

        return <App />;
    }
}

export default AppCrawlerComponent;

import Field from 'Component/Field';
import { isMobile } from 'Util/CheckDevice';

const renderQuantityChangeField = (args, callback, instance) => {
    const {
        item: { qty, product: { type_id = null, sku } = {} },
        minSaleQuantity,
        maxSaleQuantity,
        handleChangeQuantity,
        isProductInStock,
        cartTotals
    } = instance.props;
    const [isVisibleOnMobile] = args;

    if (!isProductInStock) {
        return null;
    }

    if (!isVisibleOnMobile && isMobile()) {
        return null;
    }

    if (isVisibleOnMobile && !isMobile()) {
        return null;
    }
    // eslint-disable-next-line fp/no-let
    let isOffer = false;
    if (cartTotals) {
        const { cross_sell_product_sku } = cartTotals;
        if (cross_sell_product_sku.is_enable) {
            const { product_sku } = cross_sell_product_sku;
            if (product_sku.includes(sku)) {
                isOffer = true;
            }
        }
    }

    // eslint-disable-next-line fp/no-let
    let newMinQty = minSaleQuantity;
    // eslint-disable-next-line fp/no-let
    let newMaxQty = maxSaleQuantity;
    if (type_id && type_id === 'bundle') {
        newMinQty = 1;
        // eslint-disable-next-line no-magic-numbers
        newMaxQty = 99;
    }

    return (
        <div block="CartItem" elem="QuantityWrapper">
            <span block="CartItem" elem="QuantityText">{ __('Qty:') }</span>
            <Field
              id="item_qty"
              name="item_qty"
              type="number"
              isControlled
              min={ newMinQty }
              max={ isOffer ? 1 : newMaxQty }
              mix={ { block: 'CartItem', elem: 'Qty' } }
              value={ qty }
              onChange={ handleChangeQuantity }
            />
        </div>
    );
};

export default {
    'Component/CartItem/Component': {
        'member-function': {
            renderQuantityChangeField
        }
    }
};

/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    CATALOG_PRODUCT_LIST,
    NEW_PRODUCTS,
    RECENTLY_VIEWED,
    SLIDER
} from 'SourceComponent/WidgetFactory/WidgetFactory.config';
export const CATEGORY_PRODUCT_LIST = 'CategoryProductsList';
export const HOME_PAGE_PRODUCT_LIST_WIDGET = 'HomePageProductSliderTwo';
export const CATEGORY_PAGE_CARE_WIDGET = 'care_product_view';
export const NEW_ARRIVAL_WIDGET = 'new_arrival';
export const EXPLORE_WIDGET = 'explore';

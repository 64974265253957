import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';

const propTypes = (originalMember) => ({
    ...originalMember,
    isSkinAnalyserProduct: PropTypes.bool
});

const afterAddToCart = (args, callback, instance) => {
    const {
        product: { sku = '' },
        isSkinAnalyserProduct
    } = instance.props;

    const skus = BrowserDatabase.getItem('SKIN_ANALYZER_SKU') || [];

    if (isSkinAnalyserProduct && skus.indexOf(sku) === -1) {
        BrowserDatabase.setItem([...skus, sku], 'SKIN_ANALYZER_SKU');
    }

    return callback.apply(instance, args);
};

export default {
    'Component/AddToCart/Container': {
        'static-member': {
            propTypes
        },
        'member-function': {
            afterAddToCart
        }
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { sortByDiscount, sortByPrice, sortByStock } from '../../util';
import { getAllProducts, updateCurrentPage, updateFilteredProducts, updateSorting } from '../../store/ProductsGrid/ProductsGrid.action';
import CategorySort from './CategorySort.component';
import { DISCOUNT, PRICE_HIGH, PRICE_LOW } from './CategorySort.config';

export const mapStateToProps = (state) => ({
    products: state.ProductsGridReducer.products,
    perPage: state.ProductsGridReducer.perPage
});

export const mapDispatchToProps = (dispatch) => ({
    getAllProducts: (products) => dispatch(getAllProducts(products)),
    updateCurrentPage: (page) => dispatch(updateCurrentPage(page)),
    updateFilteredProducts: (products) => dispatch(updateFilteredProducts(products)),
    updateSorting: (direction) => dispatch(updateSorting(direction))
})

/** @namespace Component/CategorySort/Container */
export class CategorySortContainer extends PureComponent {
    static propTypes = {
        products: PropTypes.array.isRequired,
        getAllProducts: PropTypes.func.isRequired,
        updateFilteredProducts: PropTypes.func.isRequired,
        perPage: PropTypes.number.isRequired,
        updateCurrentPage: PropTypes.func.isRequired,
        updateSorting: PropTypes.func.isRequired
    };

    static defaultProps = {
        sortFields: []
    };

    containerFunctions = {
        onSortChange: this.onSortChange.bind(this)
    }

    containerProps = () => ({
        selectOptions: this._prepareOptions()
    });

    _prepareOptions() {
        const sortFields = [
            {
                index: 0,
                id: `none`,
                value: null,
                label: 'Relevance',
                default: true
            },
            {
                index: 1,
                id: 'discount',
                value: DISCOUNT,
                label: 'Discount'
            },
            {
                index: 2,
                id: 'price',
                value: PRICE_LOW,
                label: 'Price Low To High'
            },
            {
                index: 3,
                id: 'price',
                value: PRICE_HIGH,
                label: 'Price High To Low'
            }
        ];

        return sortFields.sort((a, b) => a.index - b.index);
    }

    onSortChange(direction) {
        const { updateSorting } = this.props;
        updateSorting(direction);
        console.log('onSortChange', direction);
        switch (direction) {
            case DISCOUNT:
                this.sortByDiscount();
                return;
            case PRICE_HIGH:
            case PRICE_LOW:
                this.sortByPrice(direction);
                return;
            default:
                return;
        }
    }

    sortByDiscount() {
        const { products, perPage, getAllProducts, updateFilteredProducts, updateCurrentPage } = this.props;
        const sortedProducts = sortByStock(sortByDiscount(products));
        const spliced = sortByStock(sortByDiscount(products)).splice(0, perPage);
        getAllProducts(sortedProducts);
        updateFilteredProducts(spliced);
        updateCurrentPage(1);
    }

    sortByPrice(direction) {
        const { products, perPage, getAllProducts, updateFilteredProducts, updateCurrentPage } = this.props;
        const sortedProducts = sortByStock(sortByPrice(products, direction));
        const spliced = sortByStock(sortByPrice(products, direction)).splice(0, perPage);
        getAllProducts(sortedProducts);
        updateFilteredProducts(spliced);
        updateCurrentPage(1);
    }

    render() {
        return (
            <CategorySort
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySortContainer);

import PropTypes from 'prop-types';

import Link from 'Component/Link';

const renderLoginStatus = (args, callback, instance) => {
    const { closeMenu, isSignedIn, customer: { firstname = '', lastname = '', email = '' } } = instance.props;

    if (!isSignedIn) {
        return (
        <div
          block="LoginStatus"
          elem="Row"
        >
        <Link
          to="/account/login"
          aria-label="go to login page"
          block="Button"
          mix={ { block: 'LoginStatus', elem: 'LoginButton' } }
          onClick={ closeMenu }
        >
            { __('Login') }
        </Link>
        <span block="LoginStatusOr">or</span>
        <Link
          to="/account/create"
          aria-label="go to register page"
          block="Button"
          mods={ { likeLink: true } }
          mix={ { block: 'LoginStatus', elem: 'RegisterButton' } }
          onClick={ closeMenu }
        >
            { __('Register') }
        </Link>
        </div>
        );
    }

    return (
        <div block="LoginStatus" elem="Profile">
            <Link
              to="/my-account"
              aria-label="go to account page"
              block="name"
              onClick={ closeMenu }
            >
                    { `${firstname} ${lastname}` }
            </Link>
            <h6 block="email">{ email }</h6>
        </div>
    );
};

const propTypes = (originalMember) => ({
    ...originalMember,
    closeMenu: PropTypes.func.isRequired
});

export default {
    'Lafz/Component/LoginStatus/Component/LoginStatusComponent': {
        'member-function': {
            renderLoginStatus
        },
        'static-member': {
            propTypes
        }
    }
};

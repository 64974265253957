/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import RazorpayQuery from '../../query/Razorpay.query';
import RazorpayComponent from './Razorpay.component';
import { SUCCESS_DATA } from './Razorpay.config';

/** @namespace Razorpay/Component/Razorpay/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Razorpay/Component/Razorpay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Razorpay/Component/Razorpay/Container/RazorpayContainer */
export class RazorpayContainer extends PureComponent {
     static propTypes = {
         showNotification: PropTypes.func.isRequired,
         setOrderButtonEnableStatus: PropTypes.func.isRequired,
         isLoading: PropTypes.bool
     };

     static defaultProps = {
         isLoading: false
     };

     static getData(billingAddress) {
         return new Promise((resolve, reject) => {
             const guest_cart_id = getGuestQuoteId();

             fetchMutation(
                 RazorpayQuery.getCreateRazorpayPaymentOrderMutation(
                     !isSignedIn() ? { guest_cart_id } : {}
                 )
             ).then(
                 /** @namespace Razorpay/Component/Razorpay/Container/fetchMutation/then */
                 (response) => {
                     const {
                         razorpayOrder: {
                             key_id, amount,
                             rzp_order,
                             order_id,
                             quote_currency
                         }
                     } = response;

                     const { grand_total, items, quote_currency_code } = BrowserDatabase.getItem(PAYMENT_TOTALS);
                     const paymentMethodTitle = __('Paid with Card');
                     const successData = {
                         grand_total,
                         items,
                         currency: quote_currency_code,
                         paymentMethodTitle
                     };

                     BrowserDatabase.setItem({ orderID: rzp_order, successData }, SUCCESS_DATA);

                     const options = {
                         key: key_id, // Enter the Key ID generated from the Dashboard
                         amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                         currency: quote_currency,
                         name: 'LAFZ',
                         description: 'Lafz Transaction',
                         image: '',
                         order_id: rzp_order,
                         notes: {
                             merchant_order_id: '',
                             merchant_quote_id: order_id
                         },
                         handler(res) {
                             const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = res;
                             resolve({ razorpay_payment_id, razorpay_order_id, razorpay_signature });
                         },
                         modal: {
                             ondismiss() {
                                 // eslint-disable-next-line prefer-promise-reject-errors
                                 reject('Payment Closed');
                             }
                         },
                         prefill: {
                             name: `${billingAddress.firstname} ${billingAddress.lastname}`,
                             email: billingAddress.email,
                             contact: billingAddress.telephone
                         }
                     };
                     const rzp1 = new Razorpay(options);
                     rzp1.open();
                 },
                 /** @namespace Razorpay/Component/Razorpay/Container/fetchMutation/then */
                 () => {
                     // eslint-disable-next-line no-alert
                     alert(__('Something went wrong')); reject();
                 }
             );
         });
     }

     state = {
         isLoading: false
     };

     __construct(props) {
         super.__construct(props);
     }

     render() {
         return (
             <RazorpayComponent
               { ...this.props }
               { ...this.state }
             />
         );
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(RazorpayContainer);

import { DISCOUNT, PRICE_HIGH, PRICE_LOW } from '../../component/CategorySort/CategorySort.config';
import {
    sortByDiscount,
    sortByPrice,
    sortByStock
} from '../../util';
import {
    GET_ALL_PRODUCTS,
    UPDATE_FILTERED_PRODUCTS,
    UPDATE_CURRENT_PAGE,
    TOGGLE_EXCLUDE_OSS,
    GET_ORIGINAL_PRODUCTS,
    UPDATE_SORTING
} from './ProductsGrid.action';

export const getInitialState = () => ({
    products: [],
    original: [],
    filtered: [],
    perPage: 18,
    currentPage: 1,
    excludeOss: false,
    totalItems: 0,
    sorting: null
});

export const sortItems = (items, direction, excludeOss, perPage = getInitialState().perPage) => {
    let sortedProducts, spliced;
    switch (direction) {
        case DISCOUNT:
            sortedProducts = sortByStock(sortByDiscount(items), excludeOss);
            spliced = sortByStock(sortByDiscount(items), excludeOss).splice(0, perPage);
            break; 
        case PRICE_HIGH:
        case PRICE_LOW:
            sortedProducts = sortByStock(sortByPrice(items, direction), excludeOss);
            spliced = sortByStock(sortByPrice(items, direction), excludeOss).splice(0, perPage);
            break;
        default:
            sortedProducts = sortByStock(items, excludeOss);
            spliced = sortByStock(items, excludeOss).splice(0, perPage);
           break;
    }

    return {
        sortedProducts,
        spliced
    };
}

export const ProductsGridReducer = (state = getInitialState(), action) => {
    const { payload } = action;
    switch (action.type) {
    case UPDATE_SORTING:
        return {
            ...state,
            sorting: payload
        }
    case GET_ORIGINAL_PRODUCTS:
        return {
            ...state,
            original: payload
        }
    case GET_ALL_PRODUCTS:

        return {
            ...state,
            products: payload,
            totalItems: payload.length
        };
    case UPDATE_FILTERED_PRODUCTS:

        return {
            ...state,
            filtered: payload
        };
    case UPDATE_CURRENT_PAGE:
        return {
            ...state,
            currentPage: payload
        }
    case TOGGLE_EXCLUDE_OSS:
        const exclude = !state.excludeOss;

        const items = sortItems(state.original, state.sorting, exclude);

        return {
            ...state,
            excludeOss: exclude,
            products: items.sortedProducts,
            filtered: items.spliced,
            currentPage: 1,
            totalItems: items.sortedProducts.length
        }
    default:
        return state;
    }
};

export default ProductsGridReducer;

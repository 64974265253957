/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';

import { NEW_VERSION_POPUP_ID } from './NewVersionPopup.config';

/** @namespace Lafz/Component/NewVersionPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Lafz/Component/NewVersionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(NEW_VERSION_POPUP_ID, payload)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Lafz/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends PureComponent {
    containerFunctions = {
        toggleNewVersion: this.toggleNewVersion.bind(this),
        handleDismiss: this.handleDismiss.bind(this)
    };

    componentDidMount() {}

    toggleNewVersion() {
        window.location.reload();
    }

    handleDismiss() {
        // eslint-disable-next-line react/prop-types
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);

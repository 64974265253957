import {
    HIDE_ACTIVE_OVERLAY as SOURCE_HIDE_ACTIVE_OVERLAY,
    HIDE_ACTIVE_POPUP as SOURCE_HIDE_ACTIVE_POPUP,
    hideActiveOverlay as sourceHideActiveOverlay,
    hideActivePopup as sourceHideActivePopup,
    TOGGLE_OVERLAY as SOURCE_TOGGLE_OVERLAY,
    toggleOverlayByKey as sourceToggleOverlayByKey
} from 'SourceStore/Overlay/Overlay.action';

export const SHOW_CART_OVERLAY = 'SHOW_CART_OVERLAY';

export const HIDE_CART_OVERLAY = 'HIDE_CART_OVERLAY';

export const TOGGLE_OVERLAY = SOURCE_TOGGLE_OVERLAY;

export const HIDE_ACTIVE_OVERLAY = SOURCE_HIDE_ACTIVE_OVERLAY;

export const HIDE_ACTIVE_POPUP = SOURCE_HIDE_ACTIVE_POPUP;

export const toggleOverlayByKey = sourceToggleOverlayByKey;

export const hideActiveOverlay = sourceHideActiveOverlay;

export const hideActivePopup = sourceHideActivePopup;

/** @namespace Lafz/Store/Overlay/Action/showCartOverlay */
export const showCartOverlay = (overlayKey) => ({
    type: SHOW_CART_OVERLAY,
    overlayKey
});

/** @namespace Lafz/Store/Overlay/Action/hideCartOverlay */
export const hideCartOverlay = () => ({
    type: HIDE_CART_OVERLAY
});

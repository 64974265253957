import { Field } from 'Util/Query';

/** @namespace Productalert/Query/ProductAlert/Query/ProductAlertQuery */
export class ProductAlertQuery {
    getQuery({
        email,
        product_id,
        store_code,
        associate_id = ''
    }) {
        return new Field('ProductAlertNotifyMeInStock')
            .addArgument('input', 'SubmitInput', {
                email,
                product_id,
                store_code,
                associate_id
            })
            .addFieldList([
                'message',
                'success'
            ]);
    }
}

export default new ProductAlertQuery();

export const VIEW_ITEM = 'gtm_view_item';
export const VIEW_HOME = 'gtm_view_home';
export const ADD_TO_CART = 'gtm_add_to_cart';
export const VIEW_CART = 'gtm_view_cart';
export const VIEW_CATEGORY = 'gtm_view_category';
export const EVENT_BEGIN_CHECKOUT = 'gtm_begin_checkout';
export const PURCHASE_EVENT = 'gtm_purchase';
export const CONVERSION_EVENT = 'gtm_conversion';
export const ADD_PAYMENT_INFO = 'gtm_addpaymentinfo';
export const SAVE_PAYMENT_AND_PLACE_ORDER = 'gtm_savepaymentandplaceorder';
export const SAVE_ADDRESS_INFORMATION = 'gtm_add_shipping_info';
export const FETCH_USER_PROFILE = 'gtm_fetch_profile';
export const SIGN_IN = 'gtm_login';
export const SEARCH = 'gtm_search';
export const GTM_SKIN_ANALYSER = 'gtm_skin_analyser';
export const REGISTRATION_COMPLETE = 'gtm_complete_registration';

export const GTM_DATA = 'GTM_DATA';

export const UTM_DATA = 'UTM_DATA';

export const GTM_CODE = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-T2855DR');
`;

export const GTM_ADDITIONAL = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T2855DR"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

/** @namespace Gtm/Gtm/Config/triggerEvent */
export const triggerEvent = (data = {}) => {
    if (typeof window.dataLayer !== 'object') {
        return;
    }

    window.dataLayer.push(data);
};

export const INTERNAL_TRACKING_HOMEPAGE_VIEW = 'homepage_view';
export const INTERNAL_TRACKING_PLP_VIEW = 'plp_view';
export const INTERNAL_TRACKING_PDP_VIEW = 'pdp_view';
export const INTERNAL_TRACKING_ADD_TO_CART = 'add_to_cart';
export const INTERNAL_TRACKING_CHECKOUT_INIT = 'checkout_init';
export const INTERNAL_TRACKING_CHECKOUT_SUCCESS = 'success';
export const INTERNAL_TRACKING_PAYMENT_METHOD = 'payment_method';

import { Field } from 'Util/Query';

export class Pincode {
    getQuery(options) {
        const { pincode } = options;
        return new Field('checkPincode')
        .addArgument('pincode', 'String!', pincode)
        .addFieldList(this._getPincodeFields());
    }
    _getPincodeFields() {
        return [
            'status',
            'is_latlong',
            'currency',
            'dg_courier',
            'eligible_for_insurance',
            this._getDataFields()
        ]
    }
    _getDataFields(){
        return new Field('data')
        .addFieldList([
            'is_recommendation_enabled',,
            this._recommendedByFields(),
            'child_courier_id',
            'recommended_courier_company_id',
            'shiprocket_recommended_courier_id',
            this._availableCourierCompanies(),
            'recommendation_advance_rule'
        ])
    }
    _recommendedByFields(){
        return new Field('recommended_by')
        .addFieldList([
            'id',
            'title'
        ])
    }   
    _availableCourierCompanies(){
        return new Field('available_courier_companies')
        .addFieldList([
            'base_courier_id',
            'courier_type',
            'courier_company_id',
            'courier_name',
            'is_rto_address_available',
            'rate',
            'is_custom_rate',
            'cod_multiplier',
            'cod_charges',,
            'freight_charge',,
            'rto_charges',
            'coverage_charges',
            'is_surface',,
            'rating',
            'rto_performance',
            'pickup_performance',
            'delivery_performance',
            'cod',
            'description',
            'mode',
            'blocked',
            'min_weight',
            'is_international',
            'is_hyperlocal',
            'entry_tax',
            'cutoff_time',
            'pickup_availability',
            'seconds_left_for_pickup',
            'suppress_text',
            'pickup_supress_hours',
            'suppress_date',
            'supress_hours',
            'etd_hours',
            'etd',
            'estimated_delivery_days',
            'tracking_performance',
            'weight_cases',
            'realtime_tracking',
            'delivery_boy_contact',
            'pod_available',
            'call_before_delivery',
            'rank',
            'cost',
            'edd',
            'base_weight',
            'pickup_priority',
            'qc_courier',
            'odablock'
        ])
    }     
}

export default new Pincode();

/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import './MyAccountAfterSignIn.style';

/** @namespace Component/MyAccountAfterSignIn/Component */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export class MyAccountAfterSignIn extends PureComponent {
    static propTypes = {
        handleLogout: PropTypes.func.isRequired
    };

    renderOverlayContent() {
        const { handleLogout } = this.props;
        return (
            <div block="MyAccountAfterSignIn" elem="Content">
                 <Link to="/my-account/my-orders" block="MyAccountAfterSignIn" elem="Link">
                 { __('My Orders') }
                 </Link>
                 <Link to="/my-account/my-wishlist" block="MyAccountAfterSignIn" elem="Link">
                 { __('My Wishlist') }
                 </Link>
                 <Link to="/my-account" block="MyAccountAfterSignIn" elem="Link">
                 { __('My Profile') }
                 </Link>
                 <button
                   block="MyAccountAfterSignIn"
                   elem="Link"
                   onClick={ handleLogout }
                   role="link"
                 >
                    { __('Logout') }
                 </button>
            </div>
        );
    }

    render() {
        return (
             <div block="MyAccountAfterSignIn">
                 { this.renderOverlayContent() }
             </div>
        );
    }
}

export default MyAccountAfterSignIn;

/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/label-has-associated-control */
// Disabled due bug in `renderCheckboxInput` function

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import FieldDropdown from 'Component/FieldDropdown';
import FieldInput from 'Component/FieldInput';
import FieldSelect from 'Component/FieldSelect';
import FieldTextarea from 'Component/FieldTextarea';
import { PHONE_CODES } from 'Route/Checkout/Checkout.config';
import { MixType } from 'Type/Common';

import {
    CHECKBOX_TYPE,
    DROPDOWN_TYPE,
    HIDDEN_INPUT, NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

import './Field.style';

export const mapDispatchToProps = (_dispatch) => ({});

export const mapStateToProps = (state) => ({
    countryCode: state.ConfigReducer.code
});

/**
 * Input fields component
 * @class Field
 * @namespace Component/Field/Component
 */
export class Field extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        onChangeCheckbox: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onKeyPress: PropTypes.func.isRequired,
        onKeyEnterDown: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        message: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        validation: PropTypes.arrayOf(PropTypes.string).isRequired,
        validationStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        checked: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        mix: MixType,
        min: PropTypes.number,
        max: PropTypes.number
    };

    static defaultProps = {
        min: 1,
        max: 99,
        checked: false,
        mix: {},
        label: '',
        value: null,
        message: '',
        validationStatus: null
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const inputNewLabelFields = `
        .MyAccountOverlay .Field > input,
        .LoginAccount .Field > input,
        .CreateAccount .Field > input,
        .ForgotPassword .Field > input
        `;

        // eslint-disable-next-line max-len
        document.querySelectorAll(inputNewLabelFields).forEach((input) => {
            input.addEventListener('focus', (e) => {
                e.target.offsetParent.classList.add('Field_active');
            });
            input.addEventListener('blur', (e) => {
                if (e.target.value === '' || e.target.value === null) {
                    e.target.offsetParent.classList.remove('Field_active');
                }
            });
        });
    }

    renderTextarea() {
        return (
            <FieldTextarea
              { ...this.props }
            />
        );
    }

    /**
     * Render Type Text, default value is passed from parent
     * handleToUpdate used to pass child data to parent
     */
    renderTypeText() {
        // eslint-disable-next-line react/prop-types
        const { name, countryCode } = this.props;
        if (name === 'telephone') {
            const code = PHONE_CODES[countryCode];
            return (
                <div block="Field" elem="PhoneInput">
                    <div block="Field" elem="PhoneInputCode">{ code }</div>
                    <FieldInput
                      { ...this.props }
                      type="text"
                    />
                </div>
            );
        }

        return (
            <FieldInput
              { ...this.props }
              type="text"
            />
        );
    }

    /**
     * Render Type Text, default value is passed from parent
     * handleToUpdate used to pass child data to parent
     */
    renderHiddenField() {
        return (
            <FieldInput
              { ...this.props }
              type="hidden"
            />
        );
    }

    renderTypePassword() {
        return (
            <FieldInput
              { ...this.props }
              type="password"
            />
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange
        } = this.props;

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                />
                <button
                  disabled={ +value === min }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                />
                <button
                  disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                />
            </>
        );
    }

    renderCheckbox() {
        const {
            id,
            onChangeCheckbox
        } = this.props;

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                />
                <label htmlFor={ id } />
            </>
        );
    }

    renderRadioButton() {
        const {
            id,
            label,
            onClick
        } = this.props;

        return (
            <label htmlFor={ id }>
                <FieldInput
                  { ...this.props }
                  type="radio"
                  onChange={ onClick }
                />
                <label htmlFor={ id } />
                { label }
            </label>
        );
    }

    renderSelectWithOptions() {
        return (
            <FieldSelect
              { ...this.props }
            />
        );
    }

    renderDropdownWithOptions() {
        return (
            <FieldDropdown
              { ...this.props }
            />
        );
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case DROPDOWN_TYPE:
            return this.renderDropdownWithOptions();
        case HIDDEN_INPUT:
            return this.renderHiddenField();
        default:
            return this.renderTypeText();
        }
    }

    renderLabel() {
        const { id, label, validation } = this.props;
        const isRequired = validation.includes('notEmpty');

        if (!label) {
            return null;
        }

        return (
            <label
              block="Field"
              elem="Label"
              mods={ { isRequired } }
              htmlFor={ id }
            >
                { label }
            </label>
        );
    }

    renderMessage() {
        const { message } = this.props;

        if (!message) {
            return null;
        }

        return (
            <p block="Field" elem="Message">
                { message }
            </p>
        );
    }

    render() {
        const {
            mix,
            type,
            message,
            validationStatus,
            value,
            // eslint-disable-next-line react/prop-types
            name
        } = this.props;

        return (
            <div
              className={ name }
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message,
                  isValid: validationStatus === true,
                  active: value !== ''
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

// export default Field;
export default connect(mapStateToProps, mapDispatchToProps)(Field);

import { UPDATE_DELIVERY_FEES } from './DeliveryFees.action';

/** @namespace Lafz/Store/DeliveryFees/Reducer/getInitialState */
export const getInitialState = () => ({
    data: {},
    isReady: false
});

/** @namespace Lafz/Store/DeliveryFees/Reducer/DeliveryFeesReducer */
export const DeliveryFeesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_DELIVERY_FEES:
        const { payload } = action;

        return {
            data: payload,
            isReady: true
        };

    default:
        return state;
    }
};

export default DeliveryFeesReducer;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts, getVariantsIndexes } from 'Util/Product/Product';
import { fetchQuery } from 'Util/Request';
import { objectToUri } from 'Util/Url';

import CustomCartButton from './CustomCartButton.component';

/** @namespace Lafz/Component/CustomCartButton/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Lafz/Component/CustomCartButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Lafz/Component/CustomCartButton/Container/CustomCartButtonContainer */
export class CustomCartButtonContainer extends PureComponent {
    static propTypes = {
        quantity: PropTypes.number.isRequired,
        sku: PropTypes.string.isRequired
    };

    containerFunctions = {
    };

    componentDidMount() {
        this.fetchProduct();
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            isLoading: false,
            isReady: false,
            product: null
        };
    }

    fetchProduct() {
        const { sku } = this.props;
        fetchQuery(ProductListQuery.getQuery({
            args: {
                filter: {
                    productsSkuArray: [sku]
                }
            }
        })).then(
            /** @namespace Lafz/Component/CustomCartButton/Container/fetchQuery/then */
            ({ products }) => {
                const { items } = products;
                const [product = {}] = getIndexedProducts(items);
                this.setState({
                    isReady: true,
                    product
                });
            }, /** @namespace Lafz/Component/CustomCartButton/Container/fetchQuery/then */
            (error) => {
                this.setState({
                    isReady: true
                });
                // eslint-disable-next-line no-console
                console.log('fetchProducts - error', error);
            }
        )
            .catch(
                /** @namespace Lafz/Component/CustomCartButton/Container/fetchQuery/then/catch */
                () => {
                    this.setState({
                        isReady: true,
                        product: null
                    });
                }
            );
    }

    containerProps() {
        const { isLoading, product } = this.state;

        return {
            isLoading,
            product,
            linkTo: this._getLinkTo()
        };
    }

    _getLinkTo() {
        const { product: { url = null } = { }, product } = this.state;

        if (!url) {
            return '';
        }

        const { parameters } = this._getConfigurableParameters();

        return {
            pathname: url,
            state: { product },
            search: objectToUri(parameters)
        };
    }

    _getConfigurableParameters() {
        const { product: { variants = [] }, selectedFilters = {} } = this.state;
        const filterKeys = Object.keys(selectedFilters);

        if (filterKeys.length === 0) {
            return { indexes: [], parameters: {} };
        }

        const indexes = getVariantsIndexes(variants, selectedFilters);
        const [index] = indexes;

        if (!variants[index]) {
            return { indexes: [], parameters: {} };
        }
        const { attributes } = variants[index];

        const parameters = Object.entries(attributes)
            .reduce((parameters, [key, { attribute_value }]) => {
                if (filterKeys.includes(key)) {
                    return { ...parameters, [key]: attribute_value };
                }

                return parameters;
            }, {});

        return { indexes, index, parameters };
    }

    render() {
        const { isReady } = this.state;

        if (!isReady) {
            return null;
        }

        return (
            <CustomCartButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCartButtonContainer);

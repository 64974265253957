import {
    UPDATE_CUSTOMER_DETAILS as SOURCE_UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_AUTH_TOKEN_EXPIRED as SOURCE_UPDATE_CUSTOMER_IS_AUTH_TOKEN_EXPIRED,
    UPDATE_CUSTOMER_IS_LOADING as SOURCE_UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS as SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS,
    updateCustomerDetails as sourceUpdateCustomerDetails,
    updateCustomerIsAuthTokenExpired as sourceUpdateCustomerIsAuthTokenExpired,
    updateCustomerPasswordForgotStatus as sourceUpdateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus as sourceUpdateCustomerPasswordResetStatus,
    updateCustomerSignInStatus as sourceUpdateCustomerSignInStatus,
    updateIsLoading as sourceUpdateIsLoading
} from 'SourceStore/MyAccount/MyAccount.action';

// TODO: implement UPDATE_CUSTOMER_SIGN_IN_STATUS
export const UPDATE_CUSTOMER_SIGN_IN_STATUS = SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS;

// TODO: implement UPDATE_CUSTOMER_DETAILS
export const UPDATE_CUSTOMER_DETAILS = SOURCE_UPDATE_CUSTOMER_DETAILS;

// TODO: implement UPDATE_CUSTOMER_PASSWORD_RESET_STATUS
export const UPDATE_CUSTOMER_PASSWORD_RESET_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS;

// TODO: implement UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS;

// TODO: implement UPDATE_CUSTOMER_IS_LOADING
export const UPDATE_CUSTOMER_IS_LOADING = SOURCE_UPDATE_CUSTOMER_IS_LOADING;

// TODO: implement UPDATE_CUSTOMER_IS_AUTH_TOKEN_EXPIRED
export const UPDATE_CUSTOMER_IS_AUTH_TOKEN_EXPIRED = SOURCE_UPDATE_CUSTOMER_IS_AUTH_TOKEN_EXPIRED;

// TODO: implement updateCustomerSignInStatus
export const updateCustomerSignInStatus = sourceUpdateCustomerSignInStatus;

// TODO: implement updateCustomerIsAuthTokenExpired
export const updateCustomerIsAuthTokenExpired = sourceUpdateCustomerIsAuthTokenExpired;

// TODO: implement updateCustomerDetails
export const updateCustomerDetails = sourceUpdateCustomerDetails;

// TODO: implement updateCustomerPasswordResetStatus
export const updateCustomerPasswordResetStatus = sourceUpdateCustomerPasswordResetStatus;

// TODO: implement updateCustomerPasswordForgotStatus
export const updateCustomerPasswordForgotStatus = sourceUpdateCustomerPasswordForgotStatus;

// TODO: implement updateIsLoading
export const updateIsLoading = sourceUpdateIsLoading;

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import {
    CategoryPage as SourceCategoryPage,
    CmsPage as SourceCmsPage,
    ProductPage as SourceProductPage,
    UrlRewrites as SrouceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';

export const ProductPage = SourceProductPage;
export const CategoryPage = SourceCategoryPage;
export const CmsPage = SourceCmsPage;

/** @namespace Lafz/Route/UrlRewrites/Component/UrlRewritesComponent */
export class UrlRewritesComponent extends SrouceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;

        switch (type) {
        case TYPE_PRODUCT:
            return <ProductPage { ...props } />;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } />;
        case TYPE_CATEGORY:
            return <CategoryPage { ...props } />;
        case TYPE_NOTFOUND:
            window.location.href = '/';
            return null;
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewritesComponent;

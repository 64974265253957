import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';

import { triggerEvent, UTM_DATA, VIEW_CART } from '../../Gtm.config';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    countryCode: PropTypes.string.isRequired
});

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const componentDidMount = (args, callback, instance) => {
    const {
        gtmTriggered
    } = instance.state;

    if (!gtmTriggered) {
        const {
            totals: {
                quote_currency_code: currency = 'INR',
                grand_total: value,
                items = []
            } = {},
            countryCode = ''
        } = instance.props;

        if (value > 0 && currency !== '') {
            const utmData = BrowserDatabase.getItem(UTM_DATA);

            const clevertapData = {
                'Country Code': countryCode,
                'UTM Source': utmData?.utm_source,
                'UTM Medium': utmData?.utm_medium,
                'UTM Campaign': utmData?.utm_campaign
            };

            const itemsData = [];
            if (items) {
                items.forEach((d, index) => {
                    const {
                        qty: quantity,
                        product: {
                            name,
                            url,
                            sku,
                            price_range: {
                                minimum_price: {
                                    final_price: { value: price = 0 } = {}
                                } = {}
                            } = {}
                        }
                    } = d;

                    const count = index + 1;

                    if (price > 0) {
                        itemsData.push(
                            {
                                item_id: sku,
                                item_name: name,
                                currency,
                                price,
                                quantity
                            }
                        );
                        clevertapData[`Product Name - ${count}`] = name;
                        clevertapData[`Product SKU - ${count}`] = sku;
                        clevertapData[`Product Price - ${count}`] = price;
                        clevertapData[`Product Quantity - ${count}`] = quantity;
                        clevertapData[`Product URL - ${count}`] = url;
                    }
                });
            }

            triggerEvent({ ecommerce: null });

            triggerEvent({
                event: VIEW_CART,
                ecommerce: {
                    currency,
                    value,
                    items: itemsData
                },
                utm_data: utmData,
                clevertapData
            });
        }

        instance.setState({ gtmTriggered: true });
    }

    return callback.apply(args, instance);
};

export default {
    'Route/CartPage/Container': {
        'static-member': {
            propTypes
        },
        'member-property': {
            state
        },
        'member-function': {
            componentDidMount
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

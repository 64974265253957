/* eslint-disable no-useless-concat */
/* eslint-disable no-sparse-arrays */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/** @namespace Lafz/Util/Cookies/Helper/readCookie */
export const readCookie = (name) => {
    const nameEQ = `${name }=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }

    return null;
};

/** @namespace Lafz/Util/Cookies/Helper/eraseCookie */
export const eraseCookie = (name) => {
    document.cookie = `${name }=${ name };expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=; path=/`;
};

/** @namespace Lafz/Util/Cookies/Helper/checkCookie */
export const checkCookie = (name) => readCookie(name) != null;

/** @namespace Lafz/Util/Cookies/Helper/ssGetURLParameter */
export const ssGetURLParameter = (name) => decodeURIComponent((new RegExp(`[?|&]${ name }=` + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ''])[1].replace(/\+/g, '%20')) || null;

export function ssCalculateExpireDate(days) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    d.toUTCString();
    return d;
}

export function setCookie(cValue) {
    document.cookie = `${cValue }=${ cValue };expires= ${ ssCalculateExpireDate(30) };domain=; path=/`;
}

/* eslint-disable no-undef, no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Loader from 'Component/Loader';

import './Razorpay.style';

/** @namespace Razorpay/Component/Razorpay/Component/RazorpayComponent */
export class RazorpayComponent extends PureComponent {
     static propTypes = {
         isLoading: PropTypes.bool.isRequired,
         // eslint-disable-next-line react/boolean-prop-naming
         setOrderButtonEnableStatus: PropTypes.bool.isRequired
     };

     renderScript() {
         const { setOrderButtonEnableStatus } = this.props;

         setOrderButtonEnableStatus(true);
         return (
                <Html
                  content={ `<script
                        async
                        src='https://checkout.razorpay.com/v1/checkout.js'
                    ></script>` }
                />
         );
     }

     render() {
         const { isLoading } = this.props;

         return (
             <div block="Razorpay">
                 <Loader isLoading={ isLoading } />
                 { this.renderScript() }
             </div>
         );
     }
}

export default RazorpayComponent;

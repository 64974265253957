import { UrlParser as urlParser } from 'url-params-parser';

import BrowserDatabase from 'Util/BrowserDatabase';

import { GTM_ADDITIONAL, GTM_CODE, UTM_DATA } from '../../Gtm.config';

const render = (args, callback, instance) => {
    const check = document.querySelector('#indglobal-gtm');
    if (check) {
        return callback.apply(instance, args);
    }

    const query = urlParser(window.location.href).queryParams;

    if (query.utm_source) {
        BrowserDatabase.setItem(query, UTM_DATA);
    } else {
        BrowserDatabase.setItem({
            utm_source: 'Direct/Organic',
            utm_medium: 'Direct/Organic',
            utm_campaign: 'Direct/Organic'
        }, UTM_DATA);
    }

    const gtmscript = document.createElement('script');
    gtmscript.setAttribute('async', true);
    gtmscript.setAttribute('defer', true);
    gtmscript.setAttribute('id', 'indglobal-gtm');
    gtmscript.innerHTML = GTM_CODE;
    document.body.appendChild(gtmscript);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = GTM_ADDITIONAL;
    document.body.prepend(noscript);

    return callback.apply(instance, args);
};

export default {
    'Lafz/Component/App/Component/AppComponent': {
        'member-function': {
            render
        }
    }
};

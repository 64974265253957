import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

export const DeliveryFeesDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/DeliveryFees/DeliveryFees.dispatcher'
);

/** @namespace Lafz/Component/LoadDeliveryFeeConfig/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Lafz/Component/LoadDeliveryFeeConfig/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    loadFees: () => DeliveryFeesDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch)
    )
});

/** @namespace Lafz/Component/LoadDeliveryFeeConfig/Container/LoadDeliveryFeeConfigContainer */
export class LoadDeliveryFeeConfigContainer extends PureComponent {
    static propTypes = {
        loadFees: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.loadDeliveryFees();
    }

    loadDeliveryFees() {
        const { loadFees } = this.props;
        loadFees();
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadDeliveryFeeConfigContainer);

/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { TinyPagination } from 'react-pagination-custom';

import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';

import './CustomGrid.style';

/** @namespace PlpCustomGrid/Component/CustomGrid/Component/CustomGridComponent */
export class CustomGridComponent extends Component {
    static propTypes = {
        products: PropTypes.arrayOf(PropTypes.object).isRequired,
        currentPage: PropTypes.number.isRequired,
        perPage: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        buttonPageClick: PropTypes.func.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        firstPageLoading: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct() {
        this.renderBtnNumber = this.renderBtnNumber.bind(this);
    }

    renderBtnNumber(id) {
        const { currentPage, buttonPageClick } = this.props;

        return (
          <button
            onClick={ () => buttonPageClick(id) }
            key={ id }
            className={ `page ${currentPage === id ? 'selected-page' : ''}` }
          >
            { id }
          </button>
        );
    }

    renderProducts() {
        const { products } = this.props;
        return (
            <div block="CustomGrid" elem="Products">
                { products.map((product) => <ProductCard key={ product.sku } product={ product } />) }
            </div>
        );
    }

    renderPagination() {
        const {
            currentPage,
            perPage,
            totalItems,
            products
        } = this.props;

        if (!products.length) {
            return null;
        }

        return (
            <TinyPagination
              total={ totalItems }
              selectedPageId={ currentPage }
              renderBtnNumber={ this.renderBtnNumber }
              itemPerPage={ perPage }
              preKey="Previous"
              nextKey="Next"
              wrapClass="CustomGrid-Pagination"
              btnsClass="CustomGrid-PaginationButtons"
              counterClass="CustomGrid-PaginationCounter"
              spreadClass="CustomGrid-PaginationSpread"
            />
        );
    }

    renderNoProducts() {
        return (
            <div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <h2>{ __('We are sorry!') }</h2>
                    <h3>{ __('There were no products found matching your request.') }</h3>
                    <p>{ __('Please, try removing selected filters and try again!') }</p>
                </div>
            </div>
        );
    }

    render() {
        const { products, isLoading, firstPageLoading } = this.props;

        if (isLoading && firstPageLoading) {
            return <Loader />;
        }

        if (!products.length && !isLoading && !firstPageLoading) {
            return this.renderNoProducts();
        }

        return (
            <div block="CustomGrid">
                { this.renderProducts() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default CustomGridComponent;

import { Field } from 'Util/Query';

/** @namespace CouponAutoApply/Query/AutoApplyCoupon/Query/AutoApplyCouponQuery */
export class AutoApplyCouponQuery {
    getQuery(options) {
        return new Field('checkCouponInCart')
            .addArgument('cart_id', 'String!', options.cart_id)
            .addArgument('phonenumber', 'String!', options.phonenumber)
            .addFieldList([
                'success',
                'message',
                'customer_type'
            ]);
    }
}

export default new AutoApplyCouponQuery();

/* eslint-disable max-len */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    CART_OVERLAY
} from 'Component/Header/Header.config';
import {
    EXTRA10,
    FIRST10,
    FIRST10_MIN_AMOUNT,
    FREEPRODUCT,
    MIN_DISCOUNT_AMOUNT,
    XTRA10
} from 'Route/CartPage/CartPage.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { showCartOverlay } from 'Store/Overlay/Overlay.action';
import { ProductType } from 'Type/ProductList';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getDefaultCustomizableOptions } from 'Util/helpers';
// import history from 'Util/History';
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';

import AddToCart from './AddToCart.component';
import {
    BUY_PRODUCT,
    FREE_PRODUCT
} from './AddToCart.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    wishlistItems: state.WishlistReducer.productsInWishlist,
    countryCode: state.ConfigReducer.code,
    device: state.ConfigReducer.device,
    cartTotals: state.CartReducer.cartTotals,
    autoAddProducts: state.AutoAddProductsReducer.data
});

/** @namespace Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    removeFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showCartOverlay: (overlayKey) => dispatch(showCartOverlay(overlayKey)),
    applyCouponToCart: (couponCode) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applyCouponToCart(dispatch, couponCode)
    ),
    removeCouponFromCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeCouponFromCart(dispatch)
    )
});

/* @namespace Component/AddToCart/Container */
export class AddToCartContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        product: ProductType.isRequired,
        quantity: PropTypes.number,
        configurableVariantIndex: PropTypes.number,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        showNotification: PropTypes.func.isRequired,
        setQuantityToDefault: PropTypes.func,
        addProduct: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,
        wishlistItems: PropTypes.objectOf(ProductType).isRequired,
        onProductValidationError: PropTypes.func,
        productOptionsData: PropTypes.object.isRequired,
        disableHandler: PropTypes.bool,
        countryCode: PropTypes.string.isRequired,
        device: PropTypes.object.isRequired,
        cartTotals: PropTypes.object.isRequired,
        showCartOverlay: PropTypes.func.isRequired,
        applyCouponToCart: PropTypes.func.isRequired,
        removeCouponFromCart: PropTypes.func.isRequired,
        isRewardProduct: PropTypes.bool,
        isOutOfStock: PropTypes.bool,
        autoAddProducts: PropTypes.oneOfType([PropTypes.object]).isRequired,
        onlyIcon: PropTypes.bool
    };

    static defaultProps = {
        quantity: 1,
        configurableVariantIndex: 0,
        setQuantityToDefault: () => {},
        onProductValidationError: () => {},
        isLoading: false,
        disableHandler: false,
        isRewardProduct: false,
        isOutOfStock: false,
        onlyIcon: false
    };

    state = {
        isLoading: false,
        buttonText: null,
        bundleOutOfStockStatus: false
    };

    containerFunctions = {
        buttonClick: this.buttonClick.bind(this),
        isConfigurableAddToCart: this.isConfigurableAddToCart.bind(this),
        getAttribute: this.getAttribute.bind(this)
    };

    validationMap = {
        [CONFIGURABLE]: this.validateConfigurableProduct.bind(this),
        [GROUPED]: this.validateGroupedProduct.bind(this),
        [BUNDLE]: this.validateBundleProduct.bind(this)
    };

    addToCartHandlerMap = {
        [CONFIGURABLE]: this.addConfigurableProductToCart.bind(this),
        [GROUPED]: this.addGroupedProductToCart.bind(this)
    };

    componentDidMount() {
        const { isOutOfStock, product: { type_id = null } = {} } = this.props;
        if (type_id && type_id === BUNDLE) {
            this.setState({ bundleOutOfStockStatus: isOutOfStock });
        }
    }

    componentDidUpdate(_prevProps) {
        // const {
        //     product: { type_id = null } = {},
        //     productOptionsData: { productOptions = {} } = {}
        // } = this.props;

        // const { bundleOutOfStockStatus } = this.state;

        // const { productOptionsData: { productOptions: prevProductOptions } } = prevProps;

        // if (type_id && type_id === 'bundle') {
        //     if (productOptions && productOptions !== prevProductOptions) {
        //         // eslint-disable-next-line max-len
        //         const isOutOfStock = Object.keys(productOptions).some((key) => productOptions[key].stock_status === 'OUT_OF_STOCK');
        //         if (bundleOutOfStockStatus !== isOutOfStock) {
        //             // eslint-disable-next-line react/no-did-update-set-state
        //             this.setState({ bundleOutOfStockStatus: isOutOfStock });
        //         }
        //     }
        // }
    }

    validateConfigurableProduct() {
        const {
            configurableVariantIndex,
            showNotification,
            product: {
                variants = []
            }
        } = this.props;

        if (configurableVariantIndex < 0 || !variants[configurableVariantIndex]) {
            showNotification('info', __('Please select product options!'));
            return false;
        }

        const { stock_status: configurableStock } = variants[configurableVariantIndex];

        if (configurableStock !== 'IN_STOCK') {
            showNotification('info', __('Sorry! The selected product option is out of stock!'));
            return false;
        }

        return true;
    }

    getAttribute(key) {
        const {
            product: {
                attributes = {}
            } = {}
        } = this.props;

        if (Array.isArray(attributes)) {
            const selected = attributes.find((item) => item.attribute_code === key);
            return selected;
        }

        const selected = attributes[key];
        if (selected) {
            return selected;
        }

        return null;
    }

    validateGroupedProduct() {
        const {
            groupedProductQuantity,
            showNotification,
            product: {
                items
            }
        } = this.props;

        const isAllItemsAvailable = items.some(({ product: { id } }) => groupedProductQuantity[id]);

        if (!isAllItemsAvailable) {
            showNotification('info', __('Please specify the quantity of product(s)!'));
            return false;
        }

        return true;
    }

    validateBundleProduct() {
        const {
            productOptionsData,
            showNotification
        } = this.props;

        const validateBundleOptions = this.validateCustomizableOptions(productOptionsData, true);

        if (!validateBundleOptions) {
            showNotification('info', __('Please select required option!'));
            return false;
        }

        return true;
    }

    validateSimpleProduct() {
        const {
            productOptionsData,
            showNotification
        } = this.props;

        const validateCustomizableOptions = this.validateCustomizableOptions(productOptionsData);

        if (!validateCustomizableOptions) {
            showNotification('info', __('Please select required option!'));
            return false;
        }

        return true;
    }

    validateCustomizableOptions(productOptionsData, isBundle = false) {
        const {
            requiredOptions = {}
        } = productOptionsData || {};

        if (requiredOptions.length) {
            const {
                productOptions,
                productOptionsMulti,
                requiredOptions
            } = productOptionsData;

            return this.validateProductOptions(
                [...productOptions || [], ...productOptionsMulti || []],
                requiredOptions,
                isBundle
            );
        }

        return true;
    }

    validateProductOptions(items, requiredOptions, isBundle = false) {
        // Make sure EVERY required option is FOUND in selected items
        return requiredOptions.every((requiredOption) => (
            items.find((item) => {
                const { id, option_id } = item;
                const matchWith = isBundle ? id : option_id;
                return requiredOption === matchWith;
            })
        ));
    }

    validateAddToCart() {
        const {
            product: { type_id }
        } = this.props;

        const validationRule = this.validationMap[type_id];

        if (validationRule) {
            return validationRule();
        }

        return this.validateSimpleProduct();
    }

    addGroupedProductToCart() {
        const {
            product,
            product: { items },
            groupedProductQuantity,
            addProduct
        } = this.props;

        Promise.all(items.map((item) => {
            const { product: groupedProductItem } = item;

            const newProduct = {
                ...groupedProductItem,
                parent: product
            };

            const quantity = groupedProductQuantity[groupedProductItem.id];

            if (!quantity) {
                return Promise.resolve();
            }

            return addProduct({
                product: newProduct,
                quantity
            });
        })).then(
            /** @namespace Component/AddToCart/Container/addGroupedProductToCartPromiseAllThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addGroupedProductToCartPromiseAllCatch */
            () => this.resetLoading()
        );
    }

    addConfigurableProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            configurableVariantIndex,
            productOptionsData,
            productOptionsData: { productOptions },
            product: { type_id }
        } = this.props;

        if (type_id === 'bundle') {
            Object.keys(productOptions).forEach((key) => {
                if (typeof productOptions[key].stock_status !== 'undefined'
                && productOptions[key].stock_status) {
                    // eslint-disable-next-line fp/no-delete
                    delete productOptions[key].stock_status;
                }
            });
        }

        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            quantity,
            productOptionsData
        }).then(
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    addSimpleProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            productOptionsData,
            productOptionsData: { productOptions },
            product: { type_id }
        } = this.props;

        if (type_id === 'bundle') {
            Object.keys(productOptions).forEach((key) => {
                if (typeof productOptions[key].stock_status !== 'undefined'
                && productOptions[key].stock_status) {
                    // eslint-disable-next-line fp/no-delete
                    delete productOptions[key].stock_status;
                }
            });
        }

        addProduct({
            product,
            quantity,
            productOptionsData
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    addProductToCart() {
        const {
            product: { type_id },
            product,
            cartTotals,
            showNotification
        } = this.props;

        // check if item is in offer products list
        if (cartTotals) {
            const {
                cross_sell_product_sku: {
                    is_enable = false,
                    product_sku = []
                } = {}
            } = cartTotals;

            if (is_enable) {
                // eslint-disable-next-line fp/no-let
                let isOffer = false;
                if (product_sku.includes(product.sku)) {
                    isOffer = true;
                }
                // check if item is in cart
                if (isOffer) {
                    const { items } = cartTotals;
                    const { sku } = product;
                    const isInCart = items.some((item) => item.sku === sku || product_sku.includes(item.sku));
                    if (isInCart) {
                        showNotification('info', __('Sorry! Only %s! product is allowed from special offer', 1));
                        this.setState({ isLoading: false });
                        return;
                    }
                }
            }
        }

        const addToCartHandler = this.addToCartHandlerMap[type_id];

        if (addToCartHandler) {
            addToCartHandler();
            this.setState({ isLoading: false });
            return;
        }

        this.addSimpleProductToCart();
    }

    buttonClick() {
        const {
            product: { type_id } = {},
            onProductValidationError,
            disableHandler
        } = this.props;

        if (disableHandler) {
            return;
        }

        if (!this.validateAddToCart()) {
            onProductValidationError(type_id);
            return;
        }

        this.setState({ isLoading: true }, () => this.addProductToCart());
    }

    resetLoading() {
        this.setState({ isLoading: false });
    }

    removeProductFromWishlist() {
        const {
            wishlistItems,
            removeFromWishlist,
            configurableVariantIndex,
            product: { type_id, variants = {} } = {}
        } = this.props;

        if (type_id !== 'configurable') {
            return;
        }

        const { sku } = variants[configurableVariantIndex];

        const wishlistItemKey = Object.keys(wishlistItems)
            .find((key) => {
                const { wishlist: { sku: wSku } } = wishlistItems[key];
                return wSku === sku;
            });

        if (!isSignedIn() || wishlistItemKey === undefined) {
            return;
        }

        const { wishlist: { id: item_id } } = wishlistItems[wishlistItemKey];
        removeFromWishlist({ item_id, sku, noMessage: true });
    }

    async afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault,
            device: { isMobile = false } = { },
            showCartOverlay
        } = this.props;

        showNotification('success', __('Product added to cart!'));
        setQuantityToDefault();

        this.removeProductFromWishlist();
        await this.buyOneGetOneFree();
        await this.checkFirst10CouponAutoApply();
        await this.checkCouponAutoApply();
        // await this.autoAddFreeProduct();
        await this.checkAutoAddProduct();
        this.checkRewardProduct();
        this.setState({ isLoading: false });
        if (!isMobile) {
            // history.push('/cart');
            showCartOverlay(CART_OVERLAY);
            window.scrollTo(0, 0);
        }
    }

    checkRewardProduct() {
        const {
            product: { name = '' },
            isRewardProduct
        } = this.props;

        const names = BrowserDatabase.getItem('CART_REWARD_SKU') || [];

        if (isRewardProduct && names.indexOf(name) === -1) {
            BrowserDatabase.setItem([...names, name], 'CART_REWARD_SKU');
        }
    }

    async checkAutoAddProduct() {
        const {
            autoAddProducts,
            countryCode,
            cartTotals,
            addProduct
        } = this.props;
        const product = autoAddProducts[countryCode];
        if (!product) {
            return;
        }

        const isProductExistInCart = cartTotals.items.find((item) => item.sku.includes(product.sku));

        if (isProductExistInCart) {
            return;
        }

        const productOptionsData = getDefaultCustomizableOptions(product);

        await addProduct({
            product,
            quantity: 1,
            productOptionsData
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    // async autoAddFreeProduct() {
    //     const { cartTotals, addProduct, countryCode } = this.props;

    //     if (countryCode !== 'bd') {
    //         return;
    //     }

    //     const freeProduct = cartTotals.items.find((item) => ZMMLB.includes(item.sku));

    //     if (freeProduct) {
    //         return;
    //     }

    //     const randomIndex = Math.floor(Math.random() * ZMMLB.length);
    //     const randomSku = ZMMLB[randomIndex];

    //     await addProduct({
    //         product: { sku: randomSku },
    //         quantity: 1,
    //         productOptionsData: {}
    //     }).then(
    //         /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
    //         () => this.afterAddToCart(),
    //         /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
    //         () => this.resetLoading()
    //     );
    // }

    async buyOneGetOneFree() {
        const { cartTotals, addProduct, countryCode } = this.props;

        if (countryCode !== 'bd') {
            return;
        }

        const checkSku = cartTotals.items.find((item) => item.sku === BUY_PRODUCT);

        if (!checkSku) {
            return;
        }

        const freeProduct = cartTotals.items.find((item) => item.sku === FREE_PRODUCT);

        if (freeProduct) {
            return;
        }

        await addProduct({
            product: { sku: FREE_PRODUCT },
            quantity: 1,
            productOptionsData: {}
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    async checkCouponAutoApply() {
        const {
            cartTotals,
            addProduct
        } = this.props;
        const minAmount = MIN_DISCOUNT_AMOUNT[cartTotals.quote_currency_code];

        const isInCart = cartTotals.items.find((item) => item.sku === FREEPRODUCT);

        if (!minAmount || cartTotals.grand_total <= minAmount || isInCart) {
            return;
        }

        await addProduct({
            product: { sku: FREEPRODUCT },
            quantity: 1,
            productOptionsData: {}
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    async checkFirst10CouponAutoApply() {
        const {
            cartTotals,
            applyCouponToCart,
            removeCouponFromCart
        } = this.props;
        const minAmount = FIRST10_MIN_AMOUNT[cartTotals.quote_currency_code];

        if (!minAmount) {
            return;
        }

        if (cartTotals.coupon_code === null && cartTotals.grand_total >= minAmount) {
            if (cartTotals.quote_currency_code.toLowerCase() === 'bhd') {
                await applyCouponToCart(EXTRA10);
            } else if (cartTotals.quote_currency_code.toLowerCase() === 'kwd') {
                await applyCouponToCart(XTRA10);
            } else {
                await applyCouponToCart(FIRST10);
            }
        } else if ((cartTotals.coupon_code === FIRST10 || cartTotals.coupon_code === EXTRA10 || cartTotals.coupon_code === XTRA10) && cartTotals.grand_total < minAmount) {
            await removeCouponFromCart();
        }
    }

    isConfigurableAddToCart() {
        const {
            product: { variants = [] },
            product,
            configurableVariantIndex
        } = this.props;
        const productOrVariant = variants[configurableVariantIndex] || product;
        const { stock_status } = productOrVariant;
        return stock_status === 'IN_STOCK';
    }

    render() {
        return (
            <AddToCart
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';
import {
    checkCookie,
    eraseCookie,
    setCookie,
    ssGetURLParameter
} from 'Util/cookies.helper';
import { injectScriptInFooter } from 'Util/Script';
import getStore from 'Util/Store';

import './App.override.style';

/** @namespace Lafz/Component/App/Component/AppComponent */
export class AppComponent extends SourceApp {
    state ={
        gaData: {},
        showGA: true
    };

    renderGoogleAds() {
        this.renderInScripts();
        return null;
    }

    renderInScripts() {
        injectScriptInFooter({
            defer: true,
            async: true,
            content: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-198792902-1');
        `
        });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    injectUniversalTag() {
        const store = getStore();
        const { getState } = store;
        const reduxState = getState();
        const countryCode = reduxState.ConfigReducer.code;

        if (!['ae', 'bh', 'kw', 'sa', 'qa'].includes(countryCode)) {
            return null;
        }
        const href = escape(window.parent.location.href);
        injectScriptInFooter({
            'data-author': 'indglobal',
            type: 'text/javascript',
            async: true,
            src: `//track.omguk.com/e/qs/?action=Content&MID=2338078&PID=53716&ref=${ href}`
        });

        return null;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    injectCookiesCode() {
        const store = getStore();
        const { getState } = store;
        const reduxState = getState();
        const countryCode = reduxState.ConfigReducer.code;

        if (!['ae', 'bh', 'kw', 'sa', 'qa'].includes(countryCode)) {
            return null;
        }
        // eslint-disable-next-line fp/no-let
        let ssSessionId = '';
        if (ssGetURLParameter('utm_medium') != null) {
            ssSessionId = ssGetURLParameter('utm_medium');
        }

        // eslint-disable-next-line fp/no-let
        let gclid = '';
        if (ssGetURLParameter('gclid') != null) {
            gclid = ssGetURLParameter('gclid');
        }

        if (ssSessionId.toLowerCase() === 'optimisemedia') {
            setCookie(ssSessionId);
        } else if (ssSessionId.toLowerCase() !== 'optimisemedia' && (ssSessionId.toLowerCase() !== '' || gclid !== '')) {
            if (checkCookie('optimisemedia')) {
                eraseCookie('optimisemedia');
            }
        }

        return null;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    removeIframefromlocal() {
        if (window.location.href.includes('localhost')) {
            document.body.classList.add('isLocal');
        }
    }

    render() {
        this.removeIframefromlocal();

        return (
            <>
                { this.renderContextProviders() }
                { this.renderGoogleAds() }
                { this.injectCookiesCode() }
                { this.injectUniversalTag() }
            </>
        );
    }
}

export default AppComponent;

/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { customerType } from 'Type/Account';

import './LoginStatus.style';

/** @namespace Lafz/Component/LoginStatus/Component/LoginStatusComponent */
export class LoginStatusComponent extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        customer: customerType.isRequired
    };

    renderLoginStatus() {
        const { isSignedIn, customer: { firstname = '', lastname = '', email = '' } } = this.props;

        if (!isSignedIn) {
            return (
            <div
              block="LoginStatus"
              elem="Row"
            >
            <Link
              to="/account/login"
              aria-label="go to login page"
              block="Button"
              mix={ { block: 'LoginStatus', elem: 'LoginButton' } }
            >
                { __('Login') }
            </Link>
            <span block="LoginStatusOr">or</span>
            <Link
              to="/account/create"
              aria-label="go to register page"
              block="Button"
              mods={ { likeLink: true } }
              mix={ { block: 'LoginStatus', elem: 'RegisterButton' } }
            >
                { __('Register') }
            </Link>
            </div>
            );
        }

        return (
            <div block="LoginStatus" elem="Profile">
                <Link
                  to="/my-account"
                  aria-label="go to account page"
                  block="name"
                >
                      { `${firstname} ${lastname}` }
                </Link>
                <h6 block="email">{ email }</h6>
            </div>
        );
    }

    render() {
        return (
             <div
               block="LoginStatus"
             >
                 { this.renderLoginStatus() }
             </div>
        );
    }
}

export default LoginStatusComponent;

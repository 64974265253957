import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { menuCloseButton } from 'Util/Icon';
import './ExcludeOss.style';

/** @namespace PlpCustomGrid/Component/ExcludeOss/Component/ExcludeOssComponent */
export class ExcludeOssComponent extends PureComponent {
    static propTypes = {
        checked: PropTypes.bool.isRequired,
        onchange: PropTypes.func.isRequired
    };

    render = (instance) => {
        const { checked, onchange, OnclickExit } = this.props;
        // const { closeMenu } = instance.props;
        const { } = this.props;
        return (
            <div block="ExcludeOss">
                <div block="excludeOss_Top_bar">
                    <div>
                        <span block="Filter_Heading"> Filter </span>
                    </div>
                    <div block="ExcludeOss" elem="close_button">
                        <button
                            key="close"
                            block="CloseFilter"
                            elem="Button"
                            onClick={OnclickExit}
                            aria-label="Close"
                        >
                            {menuCloseButton()}
                        </button>
                    </div>
                </div>
                {/* <label block="ExcludeOss" elem="label">
                    <input
                        type="checkbox"
                        block="ExcludeOss"
                        elem="labelInput"
                        checked={checked}
                        onChange={onchange}
                    />
                    <span block="ExcludeOss" elem="labelText">In Stock</span>
                </label> */}
            </div>
        );
    }
}

export default ExcludeOssComponent;

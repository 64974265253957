/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/sort
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/** @namespace Lafz/Component/ScrollTop/Index/mapStateToProps */
export const mapStateToProps = (state) => ({
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    device: state.ConfigReducer.device
});

/** @namespace Lafz/Component/ScrollTop/Index/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Lafz/Component/ScrollTop/Index/ScrollIntoView */
export class ScrollIntoView extends PureComponent {
    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate = (prevProps) => {
        // const { urlRewrite, device } = this.props;
        // if (device.isMobile && urlRewrite.type === 'CATEGORY') {
        //     return;
        // }
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    };

    render = () => this.props.children;
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScrollIntoView));

/* eslint-disable no-undef, no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './Sslcommerze.style';

/** @namespace Sslcommerze/Component/Sslcommerze/Component/SslcommerzeComponent */
export class SslcommerzeComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        SslcommerzeStatus: PropTypes.string
    };

    static defaultProps = {
        SslcommerzeStatus: ''
    };

    renderStatus() {
        const { SslcommerzeStatus } = this.props;

        if (SslcommerzeStatus) {
            return (
                <span block="SslcommerzeStatus">{ SslcommerzeStatus }</span>
            );
        }

        return null;
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="Sslcommerze">
                <Loader isLoading={ isLoading } />
                { this.renderStatus() }
            </div>
        );
    }
}

export default SslcommerzeComponent;

import { Field } from 'Util/Query';

const _getCartTotalsFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    new Field('cross_sell_product_sku').addFieldList([
        'product_sku',
        'is_enable',
        'minimum_total'
    ])
];

export default {
    'Query/Cart': {
        'member-function': {
            _getCartTotalsFields
        }
    }
};

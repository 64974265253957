export const EVENT_CONVERSION = 'conversion';

export const CONVERSION_SEND_TO = 'AW-318914292/g3iuCL-Woe4CEPT9iJgB';

export const WAIT_TIME = 180000;

export const CONV_CURRENCY = 'INR';

export const EVENT_ADDTOCART_GTAG = 'add_to_cart';

export const EVENT_CATEGORY_PAGE = 'view_item_list';

export const LISTNAME_CATEGORY_PAGE = 'Category Page Results';

export const EVENT_PDP = 'view_item';

export const EVENT_SEARCH = 'view_item_list';

export const LISTNAME_SEARCH = 'Search Page Results';

export const EVENT_BEGIN_CHECKOUT = 'begin_checkout';

export const EVENT_PURCHASE = 'purchase';

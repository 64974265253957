/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { RATES } from './CurrencyRates.config';

/**
 *
 * @type {string}
 */
export const CUR_CURRENCY = 'current_currency';

/**
 *
 * @type {number}
 */
export const ONE_HOUR = 3600;

/**
 *
 * @param {Any} currency
 * @returns {void}
 * @namespace Util/Currency/setCurrency
 */
export const setCurrency = (currency) => {
    BrowserDatabase.setItem(currency, CUR_CURRENCY, ONE_HOUR);
};

/**
 *
 * @returns {String} currency
 * @namespace Util/Currency/getCurrency
 */
export const getCurrency = () => {
    const currency = BrowserDatabase.getItem(CUR_CURRENCY);

    return (typeof currency === 'string') ? currency : '';
};

/**
 * @returns {Float|Number} currency value
 * @namespace Util/Currency/getConvertedCurrency
 */
export const getConvertedCurrency = (from = '', to = '', value) => {
    const FROM = from.toUpperCase();
    const TO = to.toUpperCase();

    if (FROM === TO) {
        return value;
    }

    const conv_obj_key = `${FROM}_${TO}`;
    const { [conv_obj_key]: conv_val = 0 } = RATES || {};

    if (conv_val > 0) {
        // eslint-disable-next-line no-magic-numbers
        return parseFloat(value * conv_val).toFixed(2);
    }

    return 0;
};

import { OFFERPRODUCT } from './OfferProduct.action';

/** @namespace OfferProduct/Store/OfferProduct/Reducer/getInitialState */
export const getInitialState = () => ({
    product: null
});

/** @namespace OfferProduct/Store/OfferProduct/Reducer/OfferProductReducer */
export const OfferProductReducer = (state = getInitialState(), action) => {
    const { type, payload } = action;
    switch (type) {
    case OFFERPRODUCT:
        return {
            ...state,
            product: payload
        };

    default:
        return state;
    }
};

export default OfferProductReducer;

/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Breadcrumb from 'Component/Breadcrumb';
import ContentWrapper from 'Component/ContentWrapper';
import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';
import { trimTrailingSlash } from 'Util/helpers';
import { appendWithStoreCode } from 'Util/Url';

import './Breadcrumbs.override.style';

/** @namespace Lafz/Component/Breadcrumbs/Component/BreadcrumbsComponent */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    static propTypes = {
        base_url: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        showBreadcrumbSchema: PropTypes.bool.isRequired
    };

    renderBreadcrumb({ url, name }, i) {
        const { breadcrumbs } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;

        return (
            <Breadcrumb
              name={ name }
              url={ url }
              index={ i }
              key={ i }
              isDisabled={ isDisabled }
            />
        );
    }

    renderBreadcrumbList(breadcrumbs) {
        return breadcrumbs.map((_, i) => this.renderBreadcrumb(
            breadcrumbs[breadcrumbs.length - 1 - i], i
        ));
    }

    renderBreadcrumbSchema(breadcrumbs) {
        const { base_url, code, showBreadcrumbSchema } = this.props;
        if (breadcrumbs.length === 0 || showBreadcrumbSchema === false) {
            return null;
        }
        let position = 1;
        const trimmed = `${trimTrailingSlash(base_url)}`;
        const output = {
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [{
                '@type': 'ListItem',
                position,
                name: 'Home',
                item: `${trimmed}/${code}`
            }]
        };

        for (let index = 0; index < breadcrumbs.length; index++) {
            const element = breadcrumbs[breadcrumbs.length - 1 - index];
            let url = typeof element.url === 'string' ? element.url : element.url?.pathname;

            if (typeof url !== 'undefined') {
                let item = {
                    '@type': 'ListItem',
                    position: ++position,
                    name: element.name,
                    item: `${trimmed}${url}`
                };

                output.itemListElement.push(item);
            }
        }

        return (
            <Helmet>
                <script type="application/ld+json">
                    { JSON.stringify(output) }
                </script>
            </Helmet>
        );
    }

    render() {
        const { breadcrumbs, areBreadcrumbsVisible, showBreadcrumbSchema } = this.props;

        if (
            !areBreadcrumbsVisible
            || location.pathname === appendWithStoreCode('/')
            || location.pathname.match(appendWithStoreCode('/account'))
            || location.pathname === '/'
            || showBreadcrumbSchema === false
        ) {
            return null;
        }

        return (
            <ContentWrapper mix={ { block: 'Breadcrumbs' } } label={ __('Breadcrumbs (current location)...') }>
                <nav aria-label="Breadcrumbs navigation">
                    { this.renderBreadcrumbSchema(breadcrumbs) }
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { (
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional
                            breadcrumbs.length
                                ? this.renderBreadcrumbList(breadcrumbs)
                                : this.renderBreadcrumb({}, 0)
                        ) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default BreadcrumbsComponent;

/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import BrowserDatabase from 'Util/BrowserDatabase';

export const MIN_PASSWORD_LENGTH = 8;

// ^(?:[1-9]\d*|0)$
export const STORE_LIST = {
    pk: {
        regex: /^[0-9]{10}$/,
        message: __('Please enter a valid mobile number.')
    },
    sa: {
        regex: /^[0-9]{9}$/,
        message: __('Please enter a valid mobile number.')
    },
    qa: {
        regex: /^[0-9]{8}$/,
        message: __('Please enter a valid mobile number.')
    },
    om: {
        regex: /^[0-9]{8}$/,
        message: __('Please enter a valid mobile number.')
    },
    kw: {
        regex: /^[0-9]{8}$/,
        message: __('Please enter a valid mobile number.')
    },
    bh: {
        regex: /^[0-9]{8}$/,
        message: __('Please enter a valid mobile number.')
    },
    in: {
        regex: /^[0-9]{10}$/,
        message: __('Please enter a valid mobile number.')
    },
    default: {
        regex: /^[0-9]{10}$/,
        message: __('Please enter a valid mobile number.')
    },
    bd: {
        regex: /^[0-9]{10}$/,
        message: __('Please enter a valid mobile number.')
    },
    ae: {
        regex: /^[0-9]{9}$/,
        message: __('Please enter a valid mobile.')
    }
};

/** @namespace Lafz/Component/Form/Config/phoneValidationMessage */
export const phoneValidationMessage = () => {
    // const { storeConfig: { code = 'default' } = {} } = BrowserDatabase.getItem('config') || {};
    const { message } = STORE_LIST[window.location.pathname.slice(1, 3)];
    return message;
};

/** @namespace Lafz/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Lafz/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Lafz/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace Lafz/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => {
    // const { storeConfig: { code = 'default' } = {} } = BrowserDatabase.getItem('config') || {};
    const { regex } = STORE_LIST[window.location.pathname.slice(1, 3)];
    return value.match(regex);
};

/** @namespace Lafz/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Lafz/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };
    return value === passwordValue;
};

/** @namespace Lafz/Component/Form/Config/validateMatch */
export const validateMatch = ({ id, value }) => {
    const element = document.getElementById(id);
    const { dataset: { matchvalue = null } = {} } = element;
    return value !== matchvalue;
};

/** @namespace Lafz/Component/Form/Config/validatePincode */
export const validatePincode = ({ value }) => {
    const { storeConfig: { code: storeCode } = {} } = BrowserDatabase.getItem('config') || {};
    if (value.length > 0 && storeCode === 'in') {
        return value.match(/^[1-9][0-9]{5}$/);
    }

    return true;
};

/** @namespace Component/Form/Config */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least 8 characters long')
    },
    telephone: {
        validate: validateTelephone,
        message: phoneValidationMessage()
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    requiredMatch: {
        validate: validateMatch,
        message: __('This field is required')
    },
    pincode: {
        validate: validatePincode,
        message: __('Pin code is invalid!')
    }
});

export default formConfig();

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import OfferProduct from './OfferProduct.component';

export const OfferProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "offerproductdispatchers" */
    '../../store/OfferProduct/OfferProduct.dispatcher'
);

/** @namespace OfferProduct/Component/OfferProduct/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    product: state.OfferProductReducer.product,
    cartTotals: state.CartReducer.cartTotals
});

/** @namespace OfferProduct/Component/OfferProduct/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchProduct: (options) => {
        OfferProductDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
        );
    }
});

/** @namespace OfferProduct/Component/OfferProduct/Container/OfferProductContainer */
// eslint-disable-next-line react/no-redundant-should-component-update
export class OfferProductContainer extends Component {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        totals: PropTypes.object.isRequired,
        fetchProduct: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        product: PropTypes.object.isRequired,
        cartTotals: PropTypes.isRequired
    };

    state = {
        moduleEnabled: true,
        minimumPrice: 0
    };

    componentDidMount() {
        this.fetchProduct();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            return true;
        }

        return false;
        // const { cartTotals: nextPropsCartTotals = {} } = nextProps;
        // const { cross_sell_product_sku: { product_sku: next_product_sku = [] } = {} } = nextPropsCartTotals;

        // const { cartTotals = {} } = this.props;
        // const { cross_sell_product_sku: { product_sku = [] } = {} } = cartTotals;

        // if (next_product_sku !== product_sku) {
        //     return true;
        // }

        // return false;
    }

    fetchProduct() {
        setTimeout(async () => {
            try {
                const { fetchProduct, cartTotals } = this.props;

                const { cross_sell_product_sku: { product_sku, is_enable, minimum_total } } = cartTotals;
                if (is_enable) {
                    await fetchProduct({
                        args: {
                            filter: {
                                productsSkuArray: product_sku
                            }
                        }
                    });
                    this.setState({
                        // eslint-disable-next-line radix
                        minimumPrice: parseInt(minimum_total),
                        moduleEnabled: true
                    });
                } else {
                    this.setState({
                        moduleEnabled: false
                    });
                }
            } catch (error) {
                console.log('offerproductfetch', error);
                this.setState({
                    moduleEnabled: false
                });
            }
        // eslint-disable-next-line no-magic-numbers
        }, 3000);
    }

    containerProps() {
        const { product } = this.props;

        return {
            product
        };
    }

    render() {
        const { moduleEnabled, minimumPrice } = this.state;
        if (!moduleEnabled) {
            return null;
        }

        const { totals, product } = this.props;
        if (!product) {
            return null;
        }

        const { grand_total } = totals;
        if (grand_total < minimumPrice) {
            return null;
        }

        return (
            <OfferProduct
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferProductContainer);

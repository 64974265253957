import { Field } from 'Util/Query';

export const changeOrderStatusMutation = (args) => {
    const [order_id] = args;
    return new Field('changeOrderStatus')
        .addArgument('order_id', 'String', order_id)
        .addFieldList([
            'success',
            'message'
        ]);
};

export default {
    'Lafz/Query/Checkout/Query/CheckoutQuery': {
        'member-function': {
            changeOrderStatusMutation
        }
    }
};

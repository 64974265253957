export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ORIGINAL_PRODUCTS = 'GET_ORIGINAL_PRODUCTS';
export const UPDATE_FILTERED_PRODUCTS = 'UPDATE_FILTERED_PRODUCTS';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const TOGGLE_EXCLUDE_OSS = 'TOGGLE_EXCLUDE_OSS';
export const UPDATE_SORTING = 'UPDATE_SORTING';

export const getAllProducts = (payload) => ({
    type: GET_ALL_PRODUCTS,
    payload
});

export const getOriginalProducts = (payload) => ({
    type: GET_ORIGINAL_PRODUCTS,
    payload
});


export const updateFilteredProducts = (payload) => ({
    type: UPDATE_FILTERED_PRODUCTS,
    payload
})


export const updateCurrentPage = payload => ({
    type: UPDATE_CURRENT_PAGE,
    payload
})

export const toggleExcludeOss = () => ({
    type: TOGGLE_EXCLUDE_OSS
});

export const updateSorting = (direction) => ({
    type: UPDATE_SORTING,
    payload: direction
})
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import CustomGrid from '../component/CustomGrid';
import CustomGridLoading from '../component/CustomGridLoading';

const render = (args, callback, instance) => {
    const {
        firstIsLoading,
        totalPages,
        isLoading,
        mix,
        pages,
        isWidget
    } = instance.props;

    if (isWidget) {
        return callback(...args);
    }

    if (!isLoading && totalPages === 0) {
        return instance.renderNoProducts();
    }

    if (
        document.querySelector('.CategoryPage-Wrapper')
        && document.querySelector('.CategoryPage-Wrapper').classList.contains('NoProducts')
    ) {
        document.querySelector('.CategoryPage-Wrapper').classList.remove('NoProducts');
    }

    return (
        <div
          block="ProductList"
          mods={ { isLoading } }
          mix={ mix }
        >
            { instance.renderTitle() }
            { firstIsLoading && isLoading ? (
                <CustomGridLoading />
            ) : (
                <CustomGrid
                  isLoading={ isLoading }
                  pages={ pages }
                />
            ) }
        </div>
    );
};

export default {
    'Component/ProductList/Component': {
        'member-function': {
            render
        }
    }
};

/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import axios from 'axios';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import {
    // PAYMENT_TOTALS,
    SUCCESS_DATA
} from 'Route/Checkout/Checkout.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';
// import { getConvertedCurrency } from 'Util/Currency/Currency';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

// import PostpayQuery from '../../query/Postpay.query';
import PostpayComponent from './Postpay.component';

export const PostpayDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Postpay/Postpay.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Postpay/Component/Postpay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isRedirect: state.PostpayReducer.isRedirect,
    isLoading: state.PostpayReducer.isLoading,
    PostpayData: state.PostpayReducer.PostpayData
});

/** @namespace Postpay/Component/Postpay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
    )
});

/** @namespace Postpay/Component/Postpay/Container/PostpayContainer */
export class PostpayContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        isRedirect: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        PostpayData: PropTypes.object.isRequired,
        cart: PropTypes.isRequired,
        shippingFields: PropTypes.isRequired,
        email: PropTypes.string.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired
    };

    // eslint-disable-next-line react/sort-comp
    __construct() {
        this.state = {
            isLoading: false,
            PostpayStatus: ''
        };
    }

    static defaultProps = {
        isLoading: false
    };

    static getRef() {
        const store = getStore();
        const { dispatch, getState } = store;
        const reduxState = getState();
        const cart = reduxState.CartReducer.cartTotals;
        const { shippingFields, email } = reduxState?.CheckoutReducer;
        const customer = BrowserDatabase.getItem('customer');
        const address = BrowserDatabase.getItem('address');
        const { billing_address, shipping_address } = address;
        const { base_url } = reduxState.ConfigReducer;
        return new Promise((resolve, reject) => {
            (async () => {
                const guest_cart_id = getGuestQuoteId();

                PostpayDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateIsLoading(dispatch, true)
                );

                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
                const { placeOrder: { order: { order_id } } } = orderData;

                await fetchMutation(CheckoutQuery.changeOrderStatusMutation(order_id));

                deleteGuestQuoteId();

                if (isSignedIn()) {
                    CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                    );
                } else {
                    CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
                    );
                }

                const items = cart.items.reduce((all, current) => {
                    let url = new URL(current.product.url, base_url).href;
                    // let unit_price = getConvertedCurrency(cart.quote_currency_code, 'AED', current.row_total_incl_tax);
                    let unit_price = current.row_total_incl_tax;
                    all.push({
                        reference: `${current.sku}`,
                        name: `${current.product.name}`,
                        url,
                        image_url: `${current.product.thumbnail.url}`,
                        unit_price,
                        qty: current.qty
                    });

                    return all;
                }, []);
                const total_amount = cart.grand_total;
                const shipping_tax = cart.shipping_incl_tax;
                const data = JSON.stringify({
                    quoteId: guest_cart_id,
                    postPayData: {
                        total_amount,
                        tax_amount: 0,
                        currency: cart.quote_currency_code,
                        shipping: {
                            amount: shipping_tax,
                            name: 'Shipping Address',
                            address: {
                                first_name: isSignedIn() ? shipping_address.firstname : shippingFields.firstname,
                                last_name: isSignedIn() ? shipping_address.lastname : shippingFields.lastname,
                                phone: isSignedIn() ? shipping_address.telephone : shippingFields.telephone,
                                line1: shippingFields.street0,
                                line2: shippingFields.street1,
                                street: isSignedIn() && Array.isArray(shipping_address.street) ? shipping_address.street.join('') : shipping_address.street,
                                city: isSignedIn() ? shipping_address.city : shippingFields.city,
                                country: isSignedIn() ? shipping_address.country_id : shippingFields.country_id,
                                postal_code: isSignedIn() ? shipping_address.postcode : shippingFields.postcode
                            }
                        },
                        billing_address: {
                            first_name: isSignedIn() ? billing_address.firstname : shippingFields.firstname,
                            last_name: isSignedIn() ? billing_address.lastname : shippingFields.lastname,
                            phone: isSignedIn() ? billing_address.telephone : shippingFields.telephone,
                            line1: shippingFields.street0,
                            line2: shippingFields.street1,
                            street: isSignedIn() && Array.isArray(billing_address.street) ? billing_address.street.join('') : billing_address.street,
                            city: isSignedIn() ? billing_address.city : shippingFields.city,
                            country: isSignedIn() ? billing_address.country_id : shippingFields.country_id,
                            postal_code: isSignedIn() ? billing_address.postcode : shippingFields.postcode
                        },
                        customer: {
                            email: isSignedIn() ? customer.email : email,
                            first_name: isSignedIn() ? customer.firstname : shippingFields.firstname,
                            last_name: isSignedIn() ? customer.lastname : shippingFields.lastname
                        },
                        items,
                        num_instalments: 1
                    }
                });

                const auth_token = BrowserDatabase.getItem('auth_token');
                const store_config = BrowserDatabase.getItem('config');
                const { storeConfig: { code } } = store_config;

                const config = {
                    method: 'post',
                    url: `/rest/${code}/V1/postpay/order/initiate`,
                    headers: {
                        'Content-Type': 'application/json',
                        ...(isSignedIn() && { Authorization: `Bearer ${auth_token}` })
                    },
                    data
                };

                axios(config)
                    .then(
                        /** @namespace Postpay/Component/Postpay/Container/axios/then */
                        ({ data }) => {
                            const { redirect_url } = data;
                            BrowserDatabase.setItem({ quoteId: guest_cart_id, ...data }, SUCCESS_DATA);

                            PostpayDispatcher.then(
                                ({ default: dispatcher }) => dispatcher.updatePostpayData(dispatch, { url: redirect_url })
                            );

                            PostpayDispatcher.then(
                                ({ default: dispatcher }) => dispatcher.updateRedirect(dispatch, true)
                            );
                        }
                    )
                    .catch(
                        /** @namespace Postpay/Component/Postpay/Container/axios/then/catch */
                        (error) => {
                            throw error;
                        }
                    );

                reject();
            })();
        });
    }

    componentDidUpdate(prevProps) {
        const {
            isRedirect, isLoading
        } = this.props;

        if (prevProps.isRedirect !== isRedirect) {
            if (isRedirect) {
                this.redirect();
            }
        }

        if (prevProps.isLoading !== isLoading) {
            if (isLoading) {
                this.setProgress(true, __('Pleasewait...'), false);
            }
        }
    }

    redirect() {
        const { PostpayData: { url } } = this.props;

        if (url !== '') {
            try {
                BrowserDatabase.setItem(getGuestQuoteId(), 'postpay_guest_id');
                this.setProgress(true, __('Redirecting you to payment page...'), false);
                window.location.href = url;
            } catch (e) {
                showNotification('error', __('Something went wrong'));
            }
        }
    }

    setProgress(loading, status, btnStatus) {
        this.setState({ isLoading: loading, PostpayStatus: status });
        const { setOrderButtonEnableStatus } = this.props;
        setOrderButtonEnableStatus(btnStatus);
    }

    render() {
        return (
            <PostpayComponent
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostpayContainer);

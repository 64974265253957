import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Lafz/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    base_url: state.ConfigReducer.base_url,
    country: state.ConfigReducer.code
});

/** @namespace Lafz/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Lafz/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...this.propTypes,
        country: PropTypes.string
    };

    containerProps = () => {
        const { country } = this.props;
        return {
            metadata: this._getMetadata(),
            country
        };
    };

    _generateMetaFromMetadata(metadata, param = 'name') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }

    _getTitle() {
        const { title, default_title } = this.props;

        return title || default_title;
    }

    _getDescription() {
        const { description, default_description } = this.props;

        return description || default_description;
    }

    _getKeywords() {
        const { keywords, default_keywords } = this.props;

        return keywords || default_keywords;
    }

    _getRobots() {
        const { robots } = this.props;

        return robots;
    }

    _getStatusCode() {
        const { status_code } = this.props;

        return status_code;
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode()
        };

        const metaData = this._generateMetaFromMetadata(meta);

        return metaData;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);

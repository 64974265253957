import {
    DISPLAY_MODE_BOTH as SOURCE_DISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK as SOURCE_DISPLAY_MODE_CMS_BLOCK,
    DISPLAY_MODE_PRODUCTS as SOURCE_DISPLAY_MODE_PRODUCTS,
    LOADING_TIME as SOURCE_LOADING_TIME
} from 'SourceRoute/CategoryPage/CategoryPage.config';

// TODO: implement LOADING_TIME
export const LOADING_TIME = SOURCE_LOADING_TIME;

// TODO: implement DISPLAY_MODE_PRODUCTS
export const DISPLAY_MODE_PRODUCTS = SOURCE_DISPLAY_MODE_PRODUCTS;

// TODO: implement DISPLAY_MODE_CMS_BLOCK
export const DISPLAY_MODE_CMS_BLOCK = SOURCE_DISPLAY_MODE_CMS_BLOCK;

// TODO: implement DISPLAY_MODE_BOTH
export const DISPLAY_MODE_BOTH = SOURCE_DISPLAY_MODE_BOTH;

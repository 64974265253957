/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const TOGGLE_FOOTER = 'TOGGLE_FOOTER';

/** @namespace Lafz/Store/Config/Action/updateConfig */
export const updateConfig = (config) => ({
    type: UPDATE_CONFIG,
    config
});

export const UPDATE_CONFIG_DEVICE = 'UPDATE_CONFIG_DEVICE';

export const UPDATE_IS_PDP = 'UPDATE_IS_PDP';

export const UPDATE_IS_PLP = 'UPDATE_IS_PLP';

export const UPDATE_IS_HOME = 'UPDATE_IS_HOME';

/** @namespace Lafz/Store/Config/Action/updateIsPlp */
export const updateIsPlp = (payload) => ({
    type: UPDATE_IS_PLP,
    payload
});

/** @namespace Lafz/Store/Config/Action/updateConfigDevice */
export const updateConfigDevice = (device) => ({
    type: UPDATE_CONFIG_DEVICE,
    device
});

/** @namespace Lafz/Store/Config/Action/toggleFooter */
export const toggleFooter = (payload) => ({
    type: TOGGLE_FOOTER,
    payload
});

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import { RAZORPAY } from '../component/Razorpay/Razorpay.config';
import { RazorpayContainer } from '../component/Razorpay/Razorpay.container';

/** @namespace razorpay/Plugin/CheckoutPaymentsContainer/getRazorpayData */
export function getRazorpayData() {
    const { billingAddress } = this.props;
    return {
        asyncData: RazorpayContainer.getData(billingAddress)
    };
}

/** @namespace razorpay/Plugin/CheckoutPaymentsContainer/dataMap */
export const dataMap = (originalMember, instance) => ({
    ...originalMember,
    [RAZORPAY]: getRazorpayData.bind(instance)
});

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap
        }
    }
};

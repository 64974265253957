/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import Image from 'Component/Image';
import Link from 'Component/Link';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import PlpFooter from 'Component/PlpFooter';
import media from 'Util/Media';

import { COLUMN_MAP, NEWSLETTER_COLUMN, RENDER_NEWSLETTER } from './Footer.config';

import './Footer.style';

/**
 * Page footer
 * @class Footer
 * @namespace Lafz/Component/Footer/Component/FooterComponent  */
export class FooterComponent extends PureComponent {
    static propTypes = {
        isPLP: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        showFooter: PropTypes.bool.isRequired
    };

    renderMap = {
        [RENDER_NEWSLETTER]: {
            render: this.renderNewsletterSubscriptionBlock
        }
    };

    componentDidMount() {
        this.parseSearchTermUrls();

        document.addEventListener('click', this.listenFooterLinksClick);
    }

    listenFooterLinksClick(e) {
        const parentElClass = '.Footer';
        if (e.target.nodeName.toLowerCase() === 'a' && document.querySelector(parentElClass)) {
            const v = document.querySelector(parentElClass).contains(e.target);
            if (v) {
                window.scrollTo({ top: 0 });
            }
        }
    }

    async parseSearchTermUrls() {
        while (!document.querySelector('.search-terms')
        || typeof document.querySelector('.search-terms') === 'undefined') {
            // eslint-disable-next-line no-magic-numbers
            await new Promise((r) => setTimeout(r, 500));
        }

        const allLinkEl = document.querySelectorAll('.search-terms a');
        // eslint-disable-next-line fp/no-let
        for (let i = 0; i < allLinkEl.length; i++) {
            const url = allLinkEl[i].getAttribute('href');
            const queryString = url.split('?')[1];
            const params = new URLSearchParams(queryString);
            if (params.has('q')) {
                const newUrl = `search/${params.get('q')}`;
                allLinkEl[i].setAttribute('href', newUrl);
            }
        }
    }

    renderColumnItemContent(src, title) {
        if (!src) {
            return title;
        }

        return (
            <Image
              mix={ { block: 'Footer', elem: 'ColumnItemImage' } }
              src={ media(src, '', false) }
            />
        );
    }

    renderColumnItemLink = ({ href = '/', title, src }, i) => {
        const mods = src ? { type: 'image' } : {};

        return (
            <Link
              block="Footer"
              elem="ColumnItem"
              to={ href }
              mods={ mods }
              key={ i }
            >
                { this.renderColumnItemContent(src, title) }
            </Link>
        );
    };

    renderColumnItem = (item, i) => {
        const { render } = item;

        if (render) {
            return this.renderMap[render].render(item, i);
        }

        return this.renderColumnItemLink(item, i);
    };

    renderColumn = (column, i) => {
        const {
            title,
            items,
            isItemsHorizontal,
            mods = {}
        } = column;
        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        // skip newsletter column
        if (items[0].render !== undefined && items[0].render === 'render_newsletter') {
            return null;
        }

        return (
            <div block="Footer" elem="Column" mods={ mods } key={ i }>
                <h3 block="Footer" elem="ColumnTitle">
                    { title }
                </h3>
                <div
                  block="Footer"
                  elem="ColumnContent"
                  mods={ contentMods }
                >
                    { items.map(this.renderColumnItem) }
                </div>
            </div>
        );
    };

    renderColumns() {
        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'Footer', elem: 'Columns' } }
              label=""
            >
                { COLUMN_MAP.map(this.renderColumn) }
            </ContentWrapper>
        );
    }

    renderNewsletterSubscriptionBlock(item, i) {
        return <NewsletterSubscription key={ i } />;
    }

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                    { this.renderColumn({
                        ...NEWSLETTER_COLUMN,
                        mods: { isNewsletter: true }
                    }) }
                </div>
            </div>
        );
    }

    renderSearchTerms() {
        return (
        <div
          block="Footer"
          elem="CmsBlockWrapper"
          mix={ { block: 'Footer', elem: 'Content' } }
        >
            <div
              block="Footer"
              elem="PopularSearch"
              mix={ { block: 'ContentWrapper' } }
            >
                <div block="CmsBlockWrapper" elem="PopularSearch">Popular Search</div>
                <div block="CmsBlockWrapper" elem="SearchKeywords"><CmsBlock identifier="search_terms" /></div>
            </div>
        </div>
        );
    }

    renderContent() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div block="Footer" elem="Content">
                { this.renderColumns() }
            </div>
        );
    }

    renderFooterBottom() {
        const { footer_content: { footer_bottom } = {} } = window.contentConfiguration;

        if (!footer_bottom) {
            return null;
        }

        return (
        <div block="Footer" elem="Bottom">
        <div
          block="Footer"
          elem="CmsBlockWrapper"
          mix={ { block: 'Footer', elem: 'Content' } }
        >
            <div
              block="Footer"
              elem="Columns"
              mix={ { block: 'ContentWrapper' } }
            >
                <CmsBlock identifier={ footer_bottom } />
            </div>
        </div>
        </div>
        );
    }

    renderCopyrightContent() {
        return null;
    }

    moveToTop() {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }

    renderScrollToTopButton() {
        return (
            <button
              block="Button"
              mix={ { block: 'Footer', elem: 'ScrolltoTop' } }
              onClick={ this.moveToTop }
            >
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 7L7 0.999999L1 7"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                </svg>
            </button>
        );
    }

    renderNewsletter() {
        return (
            <div block="Footer" elem="Newsletter">
                <div block="Footer" elem="NewsletterHeading">Get special deals in your inbox</div>
                <div block="Footer" elem="NewsletterForm">
                    <NewsletterSubscription />
                </div>
            </div>
        );
    }

    renderPlpFooter() {
        const { isPLP } = this.props;

        if (!isPLP) {
            return null;
        }

        return <PlpFooter />;
    }

    render() {
        const { showFooter, isPLP } = this.props;

        // hide footer from checkout and cart page
        if (!showFooter) {
            return null;
        }

        const { footer_content: { is_show_copyright } } = window.contentConfiguration;

        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderNewsletter() }
                { this.renderContent() }
                { this.renderSearchTerms() }
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { isPLP ? this.renderPlpFooter() : null }
                { this.renderFooterBottom() }
                { is_show_copyright && this.renderCopyrightContent() }
                { /* { this.renderScrollToTopButton() } */ }
            </footer>
        );
    }
}

export default FooterComponent;

/* eslint-disable react/prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    getAllProducts, getOriginalProducts, updateCurrentPage, updateFilteredProducts
} from '../../store/ProductsGrid/ProductsGrid.action';
import { sortByStock } from '../../util';
import CustomGrid from './CustomGrid.component';

/** @namespace PlpCustomGrid/Component/CustomGrid/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    firstPage: state.TempProductListReducer.pages,
    firstPageLoading: state.TempProductListReducer.isLoading,
    perPage: state.ProductsGridReducer.perPage,
    totalItems: state.ProductsGridReducer.totalItems,
    excludeOss: state.ProductsGridReducer.excludeOss,
    currentPage: state.ProductsGridReducer.currentPage,
    filteredProducts: state.ProductsGridReducer.filtered,
    products: state.ProductsGridReducer.products
});

/** @namespace PlpCustomGrid/Component/CustomGrid/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getAllProducts: (products) => dispatch(getAllProducts(products)),
    getOriginalProducts: (products) => dispatch(getOriginalProducts(products)),
    updateCurrentPage: (page) => dispatch(updateCurrentPage(page)),
    updateFilteredProducts: (products) => dispatch(updateFilteredProducts(products))
});

/** @namespace PlpCustomGrid/Component/CustomGrid/Container/CustomGridContainer */
export class CustomGridContainer extends PureComponent {
    static propTypes = {
        firstPage: PropTypes.arrayOf(PropTypes.object).isRequired,
        firstPageLoading: PropTypes.bool.isRequired,
        pages: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        filteredProducts: PropTypes.array.isRequired,
        products: PropTypes.array.isRequired,
        updateFilteredProducts: PropTypes.func.isRequired,
        getAllProducts: PropTypes.func.isRequired,
        perPage: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        updateCurrentPage: PropTypes.func.isRequired,
        excludeOss: PropTypes.bool.isRequired,
        totalItems: PropTypes.number.isRequired
    };

    state = {
        tempProductsLoaded: false,
        loaded: false
    };

    containerFunctions = {
        buttonPageClick: this.buttonPageClick.bind(this)
    };

    componentDidMount() {
        this.addProducts();
        // this.updateProducts();
    }

    componentDidUpdate() {
        const { loaded, tempProductsLoaded } = this.state;
        const { isLoading, firstPageLoading } = this.props;
        if (!tempProductsLoaded) {
            this.addProducts();
        }
        if (firstPageLoading && tempProductsLoaded) {
            this.setState({
                tempProductsLoaded: false
            });
        }
        if (!loaded) {
            this.updateProducts();
        }
        if (isLoading && loaded) {
            this.setState({
                loaded: false
            });
        }
    }

    addProducts() {
        const {
            isLoading,
            getAllProducts,
            updateFilteredProducts,
            perPage,
            getOriginalProducts,
            excludeOss,
            firstPage,
            firstPageLoading
        } = this.props;

        if (!firstPageLoading && isLoading) {
            const products = Object.values(firstPage).reduce((acc, firstPage) => acc.concat(firstPage), []);
            const sortedOriginalProducts = sortByStock(products);
            const sortedProducts = sortByStock(products, excludeOss);
            const spliced = sortByStock(products, excludeOss).splice(0, perPage);
            getOriginalProducts(sortedOriginalProducts);
            getAllProducts(sortedProducts);
            updateFilteredProducts(spliced);
            this.setState({
                tempProductsLoaded: true
            });
        }
    }

    updateProducts() {
        const {
            pages,
            isLoading,
            getAllProducts,
            updateFilteredProducts,
            perPage,
            getOriginalProducts,
            excludeOss
        } = this.props;

        if (!isLoading) {
            const products = Object.values(pages).reduce((acc, page) => acc.concat(page), []);
            const sortedOriginalProducts = sortByStock(products);
            const sortedProducts = sortByStock(products, excludeOss);
            const spliced = sortByStock(products, excludeOss).splice(0, perPage);
            getOriginalProducts(sortedOriginalProducts);
            getAllProducts(sortedProducts);
            updateFilteredProducts(spliced);
            this.setState({
                loaded: true
            });
        }
    }

    buttonPageClick(id) {
        const { currentPage } = this.props;
        switch (id) {
        case 'Previous':
            this.changePage(currentPage - 1);
            break;
        case 'Next':
            this.changePage(currentPage + 1);
            break;
        default:
            this.changePage(id);
            break;
        }
    }

    changePage(id) {
        const {
            products, updateFilteredProducts, perPage, updateCurrentPage
        } = this.props;
        const startIndex = (id - 1) * perPage;
        // id = 2, perPage = 10 (3-1) * 10 = 20
        const endIndex = startIndex + perPage;
        const splicedProducts = products.filter((_, index) => index >= startIndex && index < endIndex);
        updateFilteredProducts(splicedProducts);
        updateCurrentPage(id);
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }

    containerProps() {
        const {
            isLoading,
            filteredProducts,
            perPage,
            currentPage,
            totalItems
        } = this.props;

        return {
            products: filteredProducts,
            currentPage,
            perPage,
            totalItems,
            isLoading
        };
    }

    render() {
        return (
            <CustomGrid
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomGridContainer);

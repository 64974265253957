/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';

import { closeMenu } from '../store/MobileMenu/MobileMenu.action';

const mapDispatchToProps = (args, callback, _instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        closeMenu: () => dispatch(closeMenu())
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    closeMenu: PropTypes.func.isRequired
});

export class LoginStatusContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        closeMenu: this.closeMenu.bind(instance)
    });

    closeMenu() {
        const {
            closeMenu
        } = this.props;

        closeMenu();
    }
}

const { containerFunctions } = new LoginStatusContainer();

export default {
    'Lafz/Component/LoginStatus/Container/LoginStatusContainer': {
        'static-member': {
            propTypes
        },
        'member-property': {
            containerFunctions
        }
    },
    'Lafz/Component/LoginStatus/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

/* eslint-disable no-undef, no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './Postpay.style';

/** @namespace Postpay/Component/Postpay/Component/PostpayComponent */
export class PostpayComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        PostpayStatus: PropTypes.string
    };

    static defaultProps = {
        PostpayStatus: ''
    };

    renderStatus() {
        const { PostpayStatus } = this.props;

        if (PostpayStatus) {
            return (
                <span block="PostpayStatus">{ PostpayStatus }</span>
            );
        }

        return null;
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="Postpay">
                <Loader isLoading={ isLoading } />
                { this.renderStatus() }
            </div>
        );
    }
}

export default PostpayComponent;

/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Field from 'Component/Field';

import './FieldSelect.style';

/** @namespace Component/FieldSelect/Component */
export class FieldSelect extends PureComponent {
     static propTypes = {
         handleSelectListOptionClick: PropTypes.func.isRequired,
         handleSelectExpand: PropTypes.func.isRequired,
         onChange: PropTypes.func.isRequired,
         handleSelectListKeyPress: PropTypes.func.isRequired,
         handleSelectExpandedExpand: PropTypes.func.isRequired,
         isSelectExpanded: PropTypes.bool.isRequired,
         selectOptions: PropTypes.arrayOf(PropTypes.shape({
             id: PropTypes.oneOfType([
                 PropTypes.string,
                 PropTypes.number
             ]),
             value: PropTypes.oneOfType([
                 PropTypes.string,
                 PropTypes.number
             ]),
             disabled: PropTypes.bool,
             label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
         })).isRequired,
         id: PropTypes.string.isRequired,
         name: PropTypes.string.isRequired,
         formRef: PropTypes.oneOfType([
             PropTypes.func,
             PropTypes.shape({ current: PropTypes.instanceOf(Element) })
         ]),
         placeholder: PropTypes.string,
         value: PropTypes.oneOfType([
             PropTypes.string,
             PropTypes.number,
             PropTypes.bool
         ]),
         autocomplete: PropTypes.oneOfType([
             PropTypes.string,
             PropTypes.bool
         ]),
         isDisabled: PropTypes.bool,
         skipValue: PropTypes.bool,
         matchValue: PropTypes.oneOfType([
             PropTypes.string,
             PropTypes.number
         ])
     };

     static defaultProps = {
         formRef: () => {},
         placeholder: '',
         value: null,
         isDisabled: false,
         autocomplete: 'off',
         skipValue: false,
         matchValue: ''
     };

     state = {
         searchInput: ''
     };

     renderNativeSelect() {
         const {
             name,
             id,
             onChange,
             selectOptions,
             formRef,
             value,
             isDisabled,
             isSelectExpanded: isExpanded,
             autocomplete,
             skipValue,
             matchValue
         } = this.props;

         return (
             <select
               block="FieldSelect"
               elem="Select"
               autoComplete={ autocomplete }
               mods={ { isExpanded } }
               ref={ formRef }
               name={ name }
               id={ id }
               disabled={ isDisabled }
               tabIndex="0"
               value={ value || '' }
               onChange={ onChange }
               data-skip-value={ skipValue }
               data-matchvalue={ matchValue }
             >
                 { this.renderPlaceholder() }
                 { selectOptions.map(this.renderNativeOption) }
             </select>
         );
     }

     renderNativeOption = (option) => {
         const {
             id,
             value,
             disabled,
             label
         } = option;

         return (
             <option
               key={ id }
               id={ id }
               value={ value }
               disabled={ disabled }
             >
                 { label }
             </option>
         );
     };

     renderPlaceholder() {
         const { placeholder } = this.props;

         if (!placeholder) {
             return null;
         }

         return (
             <option value="" label={ placeholder } />
         );
     }

     renderOption = (option) => {
         const {
             id,
             label,
             disabled
         } = option;

         const {
             isSelectExpanded: isExpanded,
             handleSelectListOptionClick
         } = this.props;

         return (
             <li
               block="FieldSelect"
               elem="Option"
               mods={ {
                   isExpanded,
                   isDisabled: disabled
               } }
               key={ id }
               /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
               id={ `o${id}` }
               role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
               onClick={ () => {
                   handleSelectListOptionClick(option);
                   return this.setState({ searchInput: label });
               } }
             // eslint-disable-next-line react/jsx-no-bind
               onKeyPress={ () => handleSelectListOptionClick(option) }
               tabIndex={ isExpanded ? '0' : '-1' }
             >
                 { label }
             </li>
         );
     };

     handleChange = (e) => {
         this.setState({ searchInput: e });
     };

     renderOptions() {
         const {
             selectOptions = {},
             isSelectExpanded: isExpanded,
             value = ''
         } = this.props;
         const { searchInput } = this.state;
         try {
             const selectedValue = selectOptions.filter(({ id }) => id?.toString() === value)[0] || '';

             return (
                 <>
                   <Field
                     id="select_search"
                     name="select_search"
                     type="text"
                     placeholder={ selectedValue?.id ? selectedValue?.label : selectOptions[0]?.label }
                     value={ (selectedValue?.id && !searchInput) ? selectedValue?.label : searchInput }
                   // eslint-disable-next-line react/jsx-no-bind
                     onChange={ this.handleChange }
                   />
                   <ul
                     block="FieldSelect"
                     elem="Options"
                     role="menu"
                     mods={ { isExpanded } }
                   >
                           { selectOptions.filter((val) => val.label.toLowerCase().match(searchInput.toLowerCase())).map(this.renderOption) }
                   </ul>
                 </>
             );
         } catch {
             return (
                 <>
                   <Field
                     id="select_search"
                     name="select_search"
                     type="text"
                     placeholder={ selectOptions[0]?.label }
                     value={ searchInput }
                   // eslint-disable-next-line react/jsx-no-bind
                     onChange={ this.handleChange }
                   />
                   <ul
                     block="FieldSelect"
                     elem="Options"
                     role="menu"
                     mods={ { isExpanded } }
                   >
                           { selectOptions.filter((val) => val.label.toLowerCase().match(searchInput.toLowerCase())).map(this.renderOption) }
                   </ul>
                 </>
             );
         }
     }

     render() {
         const {
             isSelectExpanded: isExpanded,
             handleSelectExpand,
             handleSelectListKeyPress,
             handleSelectExpandedExpand
         } = this.props;

         return (
             <ClickOutside onClick={ handleSelectExpandedExpand }>
                 <div
                   block="FieldSelect"
                   onClick={ handleSelectExpand }
                   onKeyPress={ handleSelectListKeyPress }
                   role="button"
                   tabIndex="0"
                   aria-label="Select drop-down"
                   aria-expanded={ isExpanded }
                 >
                     { this.renderNativeSelect() }
                     { this.renderOptions() }
                 </div>
             </ClickOutside>
         );
     }
}

export default FieldSelect;

import PropTypes from 'prop-types';

import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import { closeMenu } from '../store/MobileMenu/MobileMenu.action';

const mapDispatchToProps = (args, callback, _instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
        closeNavigation: () => dispatch(closeMenu())
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    changeHeaderState: PropTypes.func.isRequired,
    closeNavigation: PropTypes.func.isRequired
});

const onItemClick = (args, callback, instance) => {
    const {
        closeMenu,
        closeNavigation,
        changeHeaderState
    } = instance.props;

    window.scrollTo({ top: 0 });
    closeMenu();
    closeNavigation();
    changeHeaderState({
        name: 'default'
    });
};

export default {
    'Lafz/Component/MenuItem/Container/MenuItemContainer': {
        'static-member': {
            propTypes
        },
        'member-function': {
            onItemClick
        }
    },
    'Lafz/Component/MenuItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
